import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { all } from 'rsvp';

import { parseDate } from '@internationalized/date';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import { apiBaseURL, multiTransfersNamespace } from 'qonto/constants/hosts';
import { BUCKET_KEY } from 'qonto/constants/in-context-integrations';
import { getCurrentParisDateString } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class TransfersMultiController extends Controller {
  @service segment;
  @service store;
  @service networkManager;
  @service uploaderManager;
  @service router;
  @service sentry;
  @service abilities;
  @service subscriptionManager;
  @service organizationManager;

  extension;

  replaceModel(mt) {
    // keep the file with the previous model
    mt.set('file', this.get('model.file'));
    this.model.unloadRecord();
    this.set('model', mt);
  }

  scrollIntoView = scrollIntoView;

  ebicsSortFunction(a, b) {
    return (
      (b.integration.minimum_integration_level === 'connect_integration_ap') -
      (a.integration.minimum_integration_level === 'connect_integration_ap')
    );
  }

  get canAccessBulkTransferEbicsEntry() {
    return (
      variation('feature--boolean-ebics-entry-on-bulk-transfer') &&
      this.abilities.can('navigate connect') &&
      this.organizationManager.organization.isFrench &&
      this.abilities.can('update subscription') &&
      this.subscriptionManager.hasFeature('team_management')
    );
  }

  uploadTask = dropTask(async () => {
    let url = `${apiBaseURL}/${multiTransfersNamespace}/multi_transfers`;

    this.segment.track('app_multi_transfers_file_extension', {
      file_extension: this.model.file.extension,
    });

    let multiTransferResponse;

    try {
      let response = await this.uploaderManager.uploadTask.perform(
        this.get('model.file'),
        url,
        'multi_transfer'
      );

      multiTransferResponse = (await response.json()).multi_transfer;
    } catch (error) {
      if (error.status === 422) {
        let payload = await error.json();
        this.model.errors.add('document', payload?.errors?.document[0]);
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.networkManager.handleNetworkError(error);
      }

      this.segment.track('app_multi_transfers_file_parsing', {
        success: false,
      });

      return;
    }

    this.segment.track('app_multi_transfers_file_parsing', {
      success: true,
    });

    for (let transfer of multiTransferResponse.transfers) {
      // the backend is not sending IDs for the individual transfers in a
      // multi-transfer, but since we are transforming them to Ember Data records
      // they will need unique IDs. the `bulk_id` looks like an ID but is only
      // unique within the uploaded file and not globally unique. we can however
      // combine it with the ID of the parent multi-transfer to get a globally
      // unique ID, which we do here for all the transfers in the response.
      transfer.id = `${multiTransferResponse.id}#${transfer.bulk_id}`;

      if (this.abilities.can('assign category') && !transfer.activity_tag_code) {
        transfer.activity_tag = ACTIVITY_TAGS.OTHER_EXPENSE;
      }
    }

    this.store.pushPayload({
      multiTransfer: [multiTransferResponse],
    });
    let multiTransfer = this.store.peekRecord('multi-transfer', multiTransferResponse.id);

    this.replaceModel(multiTransfer);

    await this.validateTransfers();

    this.router.transitionTo('transfers.multi.review', multiTransfer);
    this.scrollIntoView('#transfer-multi-information', { offsetTop: 48 });
  });

  parseTransfers() {
    let errorTransfers = [];
    let validTransfers = [];
    this.model.transfers.forEach(transfer => {
      if (transfer.validations.error) {
        errorTransfers.push(transfer);
      } else {
        validTransfers.push(transfer);
      }
    });
    return {
      errorTransfers: this.sortTransfers(errorTransfers),
      validTransfers: this.sortTransfers(validTransfers),
    };
  }

  sortTransfers(transfers, attr = 'name') {
    return transfers.sort((a, b) => {
      return a[attr] > b[attr] ? 1 : -1;
    });
  }

  async validateTransfers() {
    let promise = this.model.transfers.map(transfer => transfer.validate());
    await all(promise);
    let { errorTransfers, validTransfers } = this.parseTransfers();
    this.set('model.transfers', [...errorTransfers, ...validTransfers]);
  }

  @action
  handleDeleteFile() {
    this.set('model.file', null);
    this.set('model.errors.document', null);
  }

  @action
  handleToggleCheck(transfer) {
    transfer.toggleProperty('selected');
  }

  @action
  handleActivityTagSelection(activityTagCode) {
    this.set('model.activityTag', activityTagCode);
    this.get('model.transfers').forEach(transfer => {
      transfer.set('activityTag', activityTagCode);
    });
    this.validateTransfers();
  }

  @action
  handleToggleScheduled() {
    this.toggleProperty('model.scheduled');
    let date = this.get('model.scheduled')
      ? parseDate(this.model.minDate)
      : parseDate(getCurrentParisDateString());
    this.handleScheduleSelection(date);
  }

  @action
  onEbicsTriggerClick() {
    // this is added to keep the sidebar open when going back to the page
    window.sessionStorage.setItem(BUCKET_KEY, 'fake-bucket');
    this.segment.track('ebics_automation_bulk-transfers_entrypoint_clicked');
  }

  @action
  handleScheduleSelection(date) {
    let dateString = date ? date.toString() : null;
    this.set('model.scheduledDate', dateString);
    this.get('model.transfers').forEach(transfer => {
      transfer.set('scheduledDate', dateString);
    });
  }
}
