import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { ES_FREELANCER_LEGAL_FORM, IRPF_ES_DEFAULT_RATE } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class QuoteEditRoute extends Route {
  @service abilities;
  @service intl;
  @service toastFlashMessages;
  @service menu;
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;
  @service receivableInvoicesBreadcrumb;
  @service accountReceivableDocumentsUploadManager;

  beforeModel() {
    if (!this.abilities.can('update receivableInvoice')) {
      return this.router.replaceWith('receivable-invoices');
    }

    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('quote').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ id }, transition) {
    try {
      let { organization } = this.organizationManager;

      let settings;

      try {
        [settings] = await Promise.all([
          this.store.findRecord('receivable-invoices-settings', organization.id, {}),
        ]);
      } catch (error) {
        if (
          ErrorInfo.for(error).shouldSendToSentry &&
          !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
        ) {
          this.sentry.captureException(error);
        }

        if (error instanceof NotFoundError) {
          return;
        }
        this._redirectOnError();
      }

      let editedQuote = await this.store.findRecord('quote', id);

      editedQuote.contactEmail = settings.contactEmail;
      editedQuote.welfareFund =
        editedQuote.welfareFund ?? this.store.createRecord('receivable-invoice/welfare-fund');

      editedQuote.organization.vatNumber = settings.vatNumber;

      if (
        organization.legalCountry === 'DE' ||
        variation('feature--boolean-ar-advanced-customization')
      ) {
        editedQuote.header = editedQuote.header ?? settings.quoteHeader;
        editedQuote.footer = editedQuote.footer ?? settings.quoteFooter;

        let hasCustomMessages = Boolean(editedQuote.header) || Boolean(editedQuote.footer);

        editedQuote.saveCustomMessages = hasCustomMessages;
      }

      if (variation('feature--boolean-ar-advanced-customization')) {
        editedQuote.termsAndConditions = null;
      }

      if (organization.legalCountry === 'DE') {
        editedQuote.organization.taxNumber = settings.taxNumber;
        editedQuote.organization.companyLeadership = settings.companyLeadership;
        editedQuote.organization.districtCourt = settings.districtCourt;
        editedQuote.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
      }

      await this.fetchClientsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => this.ignoreNotFoundAndHandleError(error));

      await editedQuote.customer.catch(error => {
        let client = this.store.peekRecord('client-hub', editedQuote.belongsTo('customer').id());
        if (client) {
          editedQuote.customer = client;
        } else {
          editedQuote.customer = null;
        }

        this.handleError(error);
      });

      let customerBillingCountry = editedQuote?.customer.get('billingAddress.countryCode');

      if (
        organization.isSpanish &&
        organization.legalForm === ES_FREELANCER_LEGAL_FORM &&
        customerBillingCountry === 'ES'
      ) {
        editedQuote.withholdingTax.rate = editedQuote.withholdingTax?.rate ?? IRPF_ES_DEFAULT_RATE;
        editedQuote.withholdingTax.reason = '';
        editedQuote.withholdingTax.type = '';
      } else if (organization.isItalian) {
        editedQuote.withholdingTax =
          editedQuote.withholdingTax ??
          this.store.createRecord('receivable-invoice/withholding-tax');
      }

      this.fetchOrganizationAvatarTask.perform(organization).catch(ignoreCancelation);

      editedQuote.currency =
        editedQuote?.customer.get('currency') || editedQuote.currency || CURRENCIES.default;

      let quote = {};

      // when coming from the settings/flows modal, there might be already some changed applied to the opened edited quote
      // instead of initializing fetching the edited one, we will peek in the store the one with the latest changes
      if (
        (transition?.from?.name === 'invoicing-settings' || transition?.from?.name === 'flows') &&
        this.peekRecordedQuotes.length > 0
      ) {
        // only one newly created quote can be expected inside the array
        quote = this.peekRecordedQuotes[0];

        // the email needs to be the latest one
        quote.contactEmail = settings.contactEmail;
      } else {
        quote = editedQuote;
      }
      return { quote, settings };
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }
  }

  get peekRecordedQuotes() {
    // peek in the store the already created but not saved quote without an id
    return this.store.peekAll('quote').filter(quote => quote.isNew && quote.id === null);
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    this.receivableInvoicesBreadcrumb.loadBreadcrumb(this.#getBreadcrumb());
    this.receivableInvoicesBreadcrumb.registerCallback(controller.validateTask.perform);
  }

  resetController(_controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      this.receivableInvoicesBreadcrumb.reset();
      this.accountReceivableDocumentsUploadManager.resetFiles();
    }
  }

  #getBreadcrumb() {
    let items = [
      {
        route: 'quotes.edit.index',
        title: this.intl.t(
          'receivable-invoices.quote-creation.navigation.breadcrumb.quote-details'
        ),
      },
      {
        route: 'quotes.edit.products',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
      },
    ];

    return items;
  }

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('quotes.edit') &&
      !transition.targetName.includes('invoicing-settings') &&
      !transition.targetName.includes('flows')
    ) {
      let { quote } = this.context;
      quote.rollbackAttributes();
      if (!isEmpty(quote.sections)) {
        quote.sections.forEach(section => {
          section.rollbackAttributes();
          section.items.forEach(item => item.rollbackAttributes());
        });
      } else {
        quote.items.forEach(item => item.rollbackAttributes());
      }
    }
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.transitionTo('quotes.index');
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }
}
