import Controller from '@ember/controller';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';

import { variation } from 'ember-launch-darkly';

export default class CapitalStatusController extends Controller {
  @service capitalIncreaseManager;

  get depositCapitalStatus() {
    return this.organization.depositCapitalStatus;
  }

  get onboardingPartner() {
    return capitalize(this.organization.onboardingPartner ?? '');
  }

  get organization() {
    return this.capitalIncreaseManager.organization;
  }

  get isOnboardingPartnerAccountant() {
    return this.organization.onboardingPartnerType === 'accountant';
  }

  get shouldShowLegalPlaceButton() {
    let { legalCountry, onboardingPartner, companyCreationFromScratch } = this.model.organization;
    let hasLegalPlaceAsPartner = onboardingPartner === 'legal-place';
    let isFeatureFlagEnabled = variation('feature--boolean-display-progress');

    return (
      isFeatureFlagEnabled &&
      hasLegalPlaceAsPartner &&
      companyCreationFromScratch &&
      legalCountry === 'FR'
    );
  }
}
