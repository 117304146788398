import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { IS_RIBA_LOGIN_PROMOTION } from 'qonto/constants/riba';
import { CFT } from 'qonto/constants/sentry-tags';
import { DEFAULT_SEARCH_INCLUDES, DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class OverviewRoute extends Route {
  @service abilities;
  @service homePage;
  @service sentry;
  @service store;
  @service segment;
  @service organizationManager;
  @service periodicUpdate;
  @service externalBankRecommendations;
  @service upsellManager;
  @service zendeskWidget;
  @service supplierInvoicesInsights;
  @service subscriptionManager;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
    openZendeskWidget: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.abilities.can('update riba')) {
      if (variation('feature--boolean-riba-promotional-elements')) {
        safeLocalStorage.setItem(IS_RIBA_LOGIN_PROMOTION, true);
      } else {
        safeLocalStorage.removeItem(IS_RIBA_LOGIN_PROMOTION);
      }
    }

    if (this.subscriptionManager.isQontoInvoicing) {
      this.store.adapterFor('receivable-invoice').namespace = receivableInvoiceV5Namespace;
    }

    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);
    if (this.abilities.cannot('view overview')) {
      return this.homePage.visitDefaultPage();
    }
  }

  async model({ bankAccounts, openZendeskWidget }) {
    let clientInvoicesOnboardingState = null;

    if (this.subscriptionManager.isQontoInvoicing) {
      this.fetchInvoicesStatsTask.perform().catch(ignoreCancelation);
      clientInvoicesOnboardingState = await this.fetchAccountReceivableOnboardingState(
        this.organizationManager.organization.id
      ).catch(() => null);
    }

    this.fetchTransactionsTask.perform({ bankAccounts }).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    let updateProcess = await this.periodicUpdate.fetchUpdateProcess();

    this.upsellManager.getUpsellRecommendationTask
      .perform({
        featureName: 'sepa_out',
        shouldDisable: this.upsellManager.isDisabledForSepaTransferUpsell(),
      })
      .catch(ignoreCancelation);

    let recommendationsBundle;
    if (this.abilities.can('view external-bank-recommendation')) {
      try {
        recommendationsBundle = await this.externalBankRecommendations.fetchRecommendationsBundle();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    if (openZendeskWidget === 'true') {
      this.zendeskWidget.openAfterMounting();
    }

    return {
      updateProcess,
      bankAccounts,
      recommendationsBundle,
      transactionsTask: this.fetchTransactionsTask,
      clientInvoicesOnboardingState,
      invoicesStatsTask: this.fetchInvoicesStatsTask,
    };
  }

  fetchInvoicesStatsTask = restartableTask(async () => {
    try {
      await this.supplierInvoicesInsights.fetchInsights();
      let receivableInvoicesStats = await this.store
        .modelFor('receivable-invoice')
        .getStats(this.store);
      return { receivableInvoicesStats };
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  fetchAccountReceivableOnboardingState = async orgId => {
    return await this.store.findRecord('accounts-receivable-onboarding', orgId);
  };

  fetchTransactionsTask = restartableTask(async ({ bankAccounts } = {}) => {
    let [property, direction] = DEFAULT_SORT_BY.split(':');

    let bankAccountFilter;
    if (bankAccounts) {
      bankAccountFilter = { bank_account_ids: bankAccounts.split(',') };
    }

    let query = {
      includes: DEFAULT_SEARCH_INCLUDES,
      sort: { property, direction },
      pagination: { page: 1, per_page: 4 },
      organization_id: this.organizationManager.organization.id,
      ...bankAccountFilter,
    };

    let { transactions } = await this.store.adapterFor('transaction').search(query);

    return { transactions };
  });

  setupController(controller, model) {
    super.setupController(controller, model);

    let trackingProperties = {};

    if (controller.showQontoInvoicingActivationHomepage) {
      trackingProperties = {
        type: 'qonto-invoicing_empty-state',
      };
    }
    this.segment.track('overview_page_loaded', trackingProperties);
  }
}
