export default {
  "container": "qOv",
  "caption": "qOo caption",
  "compact-header": "qOn",
  "visible": "qOi",
  "page-container": "qOA",
  "main": "qOY",
  "tabs": "qOh",
  "tabs-nav": "qOf",
  "tabs-panels": "qOK",
  "tabs-panel": "qOG body-2",
  "sidebar": "qOr",
  "cms-content": "qOb"
};
