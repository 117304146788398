export default {
  "header": "qDc",
  "btn-preset": "qDq btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qDZ",
  "statements-table": "qDR",
  "no-result": "qDQ",
  "lottie-illustration": "qDe",
  "wrapper": "qDB",
  "access-denied": "qDE",
  "access-denied-illustration": "qDd",
  "application-card": "qDI",
  "presets-wrapper": "qDt",
  "error-wrapper": "qDM",
  "error-container": "qDP",
  "error-illustration": "qDl mb-32",
  "invoicing-info": "qDX"
};
