import Controller from '@ember/controller';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { MEMBERSHIP_BASE_EXCLUDES, MEMBERSHIP_UBO_EXCLUDES } from 'qonto/constants/membership';
import scrollIntoView from 'qonto/utils/scroll-into-view';

const NEW_MEMBERSHIP_MODEL = 'new_membership';

export default class MembershipAddController extends Controller {
  @service toastFlashMessages;
  @service intl;
  @service router;

  @tracked showValidations = false;
  @tracked showEmptyPoiError = false;
  @tracked showEmptyKbisError = false;
  @tracked selectedMembershipModel = NEW_MEMBERSHIP_MODEL;

  @action
  setShowEmptyPoiError(showEmptyPoiError) {
    this.showEmptyPoiError = showEmptyPoiError;
  }

  @action
  setShowEmptyKbisError(showEmptyKbisError) {
    this.showEmptyKbisError = showEmptyKbisError;
  }

  get memberships() {
    return [
      'new_membership',
      ...this.model.memberships.filter(
        ({ corporateOfficer, legalRepresentative, ubo }) =>
          !corporateOfficer && !legalRepresentative && !ubo
      ),
    ];
  }

  get selectedMembership() {
    if (this.selectedMembershipModel === NEW_MEMBERSHIP_MODEL) {
      return {
        text: this.intl.t('organizations.profile.add-representative.option-add-from-scratch'),
        icon: 'icon_user_plus_outlined',
      };
    }

    if (this.selectedMembershipModel) {
      return { text: this.selectedMembershipModel.fullName, icon: null };
    }

    return null;
  }

  set selectedMembership(model) {
    this.selectedMembershipModel = model;
  }

  get shouldDisplayUboConnectionToOrganization() {
    return this.model.organization.isItalianShareCapitalCompany;
  }

  get isUboConnectionToOrganizationFieldOptional() {
    return this.shouldDisplayUboConnectionToOrganization && !this.model.membershipChangeRequest.ubo;
  }

  @action
  onClose() {
    this.model.membershipChangeRequest.rollbackAttributes();
    this.router.transitionTo('settings.company-profile.index');
  }

  @action
  handleSelectMembership(model) {
    this.selectedMembership = model;

    let { membershipChangeRequest } = this.model;

    if (model === NEW_MEMBERSHIP_MODEL) {
      membershipChangeRequest.addressCity = '';
      membershipChangeRequest.addressCountry = null;
      membershipChangeRequest.addressFirstLine = '';
      membershipChangeRequest.addressSecondLine = '';
      membershipChangeRequest.addressThirdLine = '';
      membershipChangeRequest.addressZipCode = '';
      membershipChangeRequest.birthdate = '';
      membershipChangeRequest.birthCity = '';
      membershipChangeRequest.birthCountry = null;
      membershipChangeRequest.corporateOfficer = null;
      membershipChangeRequest.firstName = '';
      membershipChangeRequest.lastName = '';
      membershipChangeRequest.gender = '';
      membershipChangeRequest.legalRepresentative = null;
      membershipChangeRequest.nationality = null;
      membershipChangeRequest.nationalities = null;
      membershipChangeRequest.taxCountry = null;
      membershipChangeRequest.taxIdentificationNumber = '';
      membershipChangeRequest.ubo = null;
    } else {
      membershipChangeRequest.addressCity = model.address.city;
      membershipChangeRequest.addressCountry = model.address.country;
      membershipChangeRequest.addressFirstLine = model.address.firstLine;
      membershipChangeRequest.addressSecondLine = model.address.secondLine;
      membershipChangeRequest.addressThirdLine = model.address.thirdLine;
      membershipChangeRequest.addressZipCode = model.address.zipcode;
      membershipChangeRequest.birthdate = model.birthdate;
      membershipChangeRequest.birthCity = model.birthCity;
      membershipChangeRequest.birthCountry = model.birthCountry;
      membershipChangeRequest.corporateOfficer = model.corporateOfficer;
      membershipChangeRequest.firstName = model.firstName;
      membershipChangeRequest.lastName = model.lastName;
      membershipChangeRequest.gender = model.gender;
      membershipChangeRequest.legalRepresentative = model.legalRepresentative || false;
      membershipChangeRequest.nationality = model.nationality;
      membershipChangeRequest.nationalities = model.nationalities;
      membershipChangeRequest.taxCountry = model.taxResidenceCountry;
      membershipChangeRequest.taxIdentificationNumber = model.taxResidenceTaxIdentificationNumber;
      membershipChangeRequest.ubo = model.ubo;
    }
  }

  scrollToFirstErrorInput() {
    schedule('afterRender', () => scrollIntoView('[data-has-error]', { block: 'center' }));
  }

  submitTask = dropTask(async () => {
    this.showValidations = true;

    let { membershipChangeRequest } = this.model;

    let shouldExcludeUboFields =
      !this.shouldDisplayUboConnectionToOrganization ||
      this.isUboConnectionToOrganizationFieldOptional;

    let excludes = shouldExcludeUboFields
      ? [...MEMBERSHIP_BASE_EXCLUDES, ...MEMBERSHIP_UBO_EXCLUDES]
      : MEMBERSHIP_BASE_EXCLUDES;

    let { validations } = await this.model.membershipChangeRequest.validate({ excludes });

    if (validations.isInvalid) {
      this.scrollToFirstErrorInput();
    }

    if (
      validations.isInvalid ||
      !this.model.pois.length ||
      (membershipChangeRequest.legalRepresentative && !this.model.kbis.length)
    ) {
      if (!this.model.pois.length) {
        this.showEmptyPoiError = true;
      }
      if (membershipChangeRequest.legalRepresentative && !this.model.kbis.length) {
        this.showEmptyKbisError = true;
      }

      return;
    }

    membershipChangeRequest.set('didValidate', true);

    try {
      // create UpdateProcess, if it does not exist yet on the server
      if (this.model.updateProcess.get('isNew') || membershipChangeRequest.legalRepresentative) {
        if (this.model.kbis.length) {
          this.model.updateProcess.kycKybUpdateKbisFile = this.model.kbis.at(-1);
        }
        if (this.model.bylaws.length) {
          this.model.updateProcess.kycKybUpdateBylawFile = this.model.bylaws.at(-1);
        }

        await this.model.updateProcess.save();
      }

      if (this.model.pois.length) {
        membershipChangeRequest.kycKybUpdatePoiFile = this.model.pois.at(-1);
      }

      await membershipChangeRequest.save();

      this.showValidations = false;
      membershipChangeRequest.set('didValidate', false);

      // since just the transitionTo() does not reevaluate the model() of the route
      // we do it manually before redirecting
      this.router.refresh('settings.company-profile');
      this.router.transitionTo('settings.company-profile.index');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('organizations.profile.company-profile.success-toast.shareholder-added')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}
