export default {
  "mt-16": "qnY",
  "title": "qnh",
  "avatar": "qnf",
  "edit-organization-form": "qnK",
  "dropdown-content": "qnG",
  "city-block": "qnr",
  "zip-code-input": "qnb",
  "cancel-button": "qny",
  "error-message": "qnU",
  "nature-of-operations": "qnj",
  "error": "qnW",
  "tax-country": "qnu",
  "tooltip": "qiS",
  "legal-code": "qic",
  "activity-type": "qiq"
};
