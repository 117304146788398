import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ReceivableInvoicesAccountantAccessUpgradeRoute extends Route {
  @service menu;
  @service router;
  @service upsellManager;

  beforeModel() {
    if (!this.upsellManager.shouldShowFreemiumUpgrade) {
      this.router.replaceWith('receivable-invoices');
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
