import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RibaRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('read riba') || this.abilities.cannot('update riba')) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
