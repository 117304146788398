import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class CapitalIndexController extends Controller {
  checkbox = Checkbox;

  @service featuresManager;
  @service router;

  @reads('featuresManager.flags.qontoNotary') isQontoNotaryEnabled;

  @tracked hasAgreedTermsConditions = false;
  @tracked hasTermsConditionsError = false;

  @action
  toggleHasAgreedTermsConditions() {
    this.hasAgreedTermsConditions = !this.hasAgreedTermsConditions;
    if (this.hasAgreedTermsConditions) {
      this.hasTermsConditionsError = false;
    }
  }

  @action
  validateHasAgreedTermsConditions() {
    this.hasTermsConditionsError = false;

    if (this.hasAgreedTermsConditions) {
      this.router.transitionTo('capital.shareholders.index');
    } else {
      this.hasTermsConditionsError = true;
    }
  }
}
