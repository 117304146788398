export default {
  "page": "qvI",
  "container": "qvt",
  "form-container": "qvM",
  "preview-container": "qvP",
  "pdf-preview-container": "qvl",
  "content": "qvX",
  "title": "qvC",
  "tabs-container": "qvk",
  "close-button": "qvJ btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvs",
  "header": "qvF",
  "without-tabs": "qvg"
};
