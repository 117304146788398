import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SETTINGS_DEFAULT_VALUES } from 'qonto/constants/receivable-invoice';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesEditProductsRoute extends BaseReceivableInvoicesRoute {
  @service router;
  @service store;
  @service organizationManager;

  beforeModel() {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      this.router.transitionTo('receivable-invoices.edit.index');
    }
  }

  async model() {
    let parentModel = this.modelFor('receivable-invoices.edit');
    let { invoice } = parentModel;

    let organization = this.organizationManager.organization;

    if (organization.legalCountry === 'DE') {
      await this.fetchIsFirstInvoice
        .perform()
        .catch(ignoreCancelation)
        .finally(() => {
          let isFirst = this.fetchIsFirstInvoice.lastSuccessful?.value;

          if (isFirst) {
            invoice.header = invoice.header || SETTINGS_DEFAULT_VALUES.invoiceHeader;
            invoice.footer = invoice.footer || SETTINGS_DEFAULT_VALUES.invoiceFooter;
            invoice.saveCustomMessages = true;
          }
        });
    }

    await this.fetchAttachment.perform(invoice).catch(ignoreCancelation);

    return {
      ...parentModel,
      invoice,
      attachment: this.fetchAttachment.lastSuccessful?.value,
      isBanklessOrganization: this.isBanklessOrganization,
    };
  }

  fetchIsFirstInvoice = restartableTask(async () => {
    let invoiceStats = await this.store.modelFor('receivable-invoice').getStats(this.store);

    let isFirstInvoice = invoiceStats.created.total <= 0;

    return isFirstInvoice;
  });

  fetchAttachment = dropTask(async invoice => {
    let attachmentFileId = invoice.belongsTo('receivableInvoicingUpload').id();

    if (attachmentFileId) {
      let attachmentFile = await this.store.findRecord(
        'receivable-invoices-upload',
        attachmentFileId
      );
      return attachmentFile;
    }

    return null;
  });
}
