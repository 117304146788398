export const cardUpsell = {
  steps: {
    'card-selection': {
      componentName: 'cards/card-selection',
      nextStepId: 'upsell',
    },
    upsell: {
      componentName: 'cards/upsell',
      nextStepId: 'customization',
    },
    customization: {
      componentName: 'cards/customization-upsell',
      nextStepId: 'delivery-address',
    },
    'delivery-address': {
      componentName: 'cards/delivery-address',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review-upsell',
      nextStepId: 'success',
    },
    success: {
      componentName: 'cards/success-new-physical',
    },
  },
  options: {
    enablePersistence: false,
  },
};
