import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class SupplierInvoicesFreemiumUpgradeController extends Controller {
  @service intl;
  @service subscriptionManager;
  @service upsellManager;
  @service router;

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      ['fx_transfers', 'sepa_transfers', 'request_transfer'].every(feature =>
        this.upsellManager.hasAvailableFreeTrial(feature)
      ) || this.hasInitialTrial
    );
  }

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-account-payable.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goToSupplierInvoicesList() {
    this.router.transitionTo('supplier-invoices.list');
  }

  @action
  upsell() {
    this.upsellManager.upsell({ feature: 'fx_transfers' });
  }
}
