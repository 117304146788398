import { service } from '@ember/service';

import { ES_FREELANCER_LEGAL_FORM, IRPF_ES_DEFAULT_RATE } from 'qonto/constants/receivable-invoice';
import NewInvoiceSubscriptionRoute from 'qonto/routes/invoice-subscriptions/new/route';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class EditInvoiceSubscriptionRoute extends NewInvoiceSubscriptionRoute {
  @service store;

  async model({ id }, transition) {
    // Fetch organization avatar
    await this.organization.getAvatar();

    await this.fetchClientsTask.perform().catch(ignoreCancelation);

    let subscription;

    // when coming from the settings modal, there might be already one recorded subscription in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (
      (transition?.from?.name === 'invoicing-settings' ||
        transition?.from?.params?.name === 'sdd-activation') &&
      this.peekRecordedSubscriptions.length > 0
    ) {
      // only one newly created subscription can be expected inside the array
      subscription = this.peekRecordedSubscriptions[0];

      if (this.isGermanOrganization) {
        // the fields need to be the latest one
        subscription.header = this.isGermanOrganization ? this.settings.invoiceHeader : undefined;
        subscription.footer = this.isGermanOrganization ? this.settings.invoiceFooter : undefined;
      }
    } else {
      subscription = await this.store.findRecord('invoice-subscription', id);
    }

    let client = this.store.peekRecord('client-hub', subscription.belongsTo('customer').id());

    let customerBillingCountry;

    if (client) {
      subscription.customer = client;
      customerBillingCountry = client.get('billingAddress.countryCode');

      // adding withholding tax for spanish freelancers
      if (
        this.organization.isSpanish &&
        this.organization.legalForm === ES_FREELANCER_LEGAL_FORM &&
        customerBillingCountry === 'ES' &&
        !subscription.withholdingTax
      ) {
        subscription.withholdingTax = this.store.createRecord(
          'receivable-invoice/withholding-tax',
          {
            rate: IRPF_ES_DEFAULT_RATE,
            reason: '',
            type: '',
          }
        );
      }
    }

    // the email needs to be the latest one
    subscription.contactEmail = this.settings.contactEmail;

    let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
      .perform()
      .catch(ignoreCancelation);

    let isFirstFrenchEinvoice = false;
    if (this.isFrenchOrganization) {
      await this.fetchFirstEInvoice();

      let allEinvoices = this.fetchFirstEInvoiceTask?.lastSuccessful?.value || false;

      isFirstFrenchEinvoice = allEinvoices && allEinvoices.length === 0;
    }

    subscription.isEinvoice =
      (canCreateFrEinvoice && subscription.customer.get('einvoicing')) ||
      Boolean(subscription.customer.get('eInvoicingAddress')) ||
      false;

    let sddActivation = this.fetchSddActivation();

    await this.loadMandatesTask.perform(subscription.customer.id);

    return {
      directDebitMandate: await subscription.directDebitCollectionMandate,
      subscriptionCustomerId: subscription.customer.id,
      sddActivation,
      subscription,
      settings: this.settings,
      canCreateFrEinvoice,
      isFirstFrenchEinvoice,
      loadMandatesTask: this.loadMandatesTask,
    };
  }
}
