import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

export default class TasksPastMultiTransferDetailController extends Controller {
  @service organizationManager;
  @service router;
  @service intl;

  @reads('organizationManager.membership') membership;

  @tracked highlight = '';

  get localState() {
    return {
      empty: !this.get('model.transfers.length'),
    };
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }
}
