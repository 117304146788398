/* eslint-disable @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { LAST_DISMISSED_DATE_LOCAL_STORAGE_KEY } from 'qonto/constants/e-invoicing-fr';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ORGANIZATIONS_REDIRECTS_CONFIG } from 'qonto/services/redirect-handler/redirects-config';

export default class OrganizationsRoute extends Route {
  @service sentry;
  @service abilities;
  @service router;
  @service featuresManager;
  @service organizationManager;
  @service subscriptionManager;
  @service upsellManager;
  @service topBanners;
  @service bannerFlashMessages;
  @service store;
  @service periodicUpdate;
  @service kycVerification;
  @service modals;
  @service intl;
  @service homePage;
  @service toastFlashMessages;
  @service redirectHandler;
  @service cashFlowManager;

  model(params) {
    let slug = params.organization_slug;
    let organization = this.organizationManager.getOrganizationBySlug(slug);

    if (organization) {
      return organization;
    }

    this.router.replaceWith('/404');
    return null;
  }

  async afterModel(organization, transition) {
    super.afterModel(...arguments, transition);

    // If the model is not loaded at this point could mean that the user was redirected to 404.
    if (!organization) {
      this.sentry.captureMessage('afterModel: organization is not loaded');
      return;
    }

    await this.organizationManager.setCurrentOrganizationAndMembership(organization);

    await Promise.all([
      this.subscriptionManager.refresh(organization),
      organization.loadTeams(),
      this.loadFlexKyb(organization),
      this.upsellManager.loadFreemiumUpgradeInfo(organization),
      this.loadFrenchSettings(organization),
      this.cashFlowManager.getFirstTimeExperienceState(),
    ]);

    let promises = [
      ...(this.abilities.can('view connections external-account')
        ? [organization.loadBankConnections()]
        : []),
    ];

    let { kycAccepted, kycSubmitted } = this.organizationManager.membership;
    if (!kycAccepted || !kycSubmitted) {
      promises.push(
        this.kycVerification.generateUrl().catch(() => {
          // we don't want to block the user
        })
      );
    }

    await Promise.all(promises);

    // Hide dual nav animations on load, signin, org change
    document.body.classList.add('prevent-nav-animation');

    this.handleDisplayEInvoicingConsentModal();

    /**
     * This acts as middleware for route redirects.
     *
     * This is where we handle the redirects for the `organizations` routes.
     * As most of the redirects will happen to a route that is part of the `organizations.show` route,
     * we need to ensure that the organization is fully loaded before redirecting, hence this is placed here.
     */
    await this.redirectHandler.handleConditionalRedirects(
      {
        transition,
        organization,
        periodicUpdate: this.periodicUpdate,
        membership: this.organizationManager.membership,
      },
      ORGANIZATIONS_REDIRECTS_CONFIG
    );
  }

  serialize(model) {
    // in some cases `model` can be undefined. in these cases we fall back to
    // the current route model to get the current organization slug.
    return { organization_slug: (model || this.currentModel).get('slug') };
  }

  async loadFlexKyb(organization) {
    if (organization.isDeFreelancer || organization.isDeCompanyCreation) {
      await organization.loadFlexKyb();
    }
  }

  async loadFrenchSettings(organization) {
    if (organization.isFrench) {
      await organization.loadFrenchSettings();
    }
  }

  handleDisplayEInvoicingConsentModal() {
    let { organization } = this.organizationManager;
    let { features, id } = organization;
    let frenchSettings = this.store.peekRecord('einvoicing-fr-settings', id);

    if (
      !frenchSettings ||
      !this.abilities.can('write einvoicingSetting') ||
      !features.includes('einvoicing_opt_in') ||
      !variation('feature--boolean-einvoicing-login-opt-in')
    ) {
      return;
    }

    let { einvoicingReceivingStatus = '', segmentation = '' } = frenchSettings;

    let dateModalLastDisplayed = safeLocalStorage.getItem(LAST_DISMISSED_DATE_LOCAL_STORAGE_KEY);
    let today = new Date();
    let reshowDays = variation('feature--e-invoicing-opt-in-reshow-days') || 7;

    let dateToReshowModal = new Date(dateModalLastDisplayed);
    dateToReshowModal.setDate(dateToReshowModal.getDate() + reshowDays);

    let reshowModal = today >= dateToReshowModal;

    let { kybStatus } = this.organizationManager.organization;

    let showModal =
      einvoicingReceivingStatus === 'disabled' && kybStatus === 'accepted' && reshowModal;

    if (showModal) {
      this.modals.open('e-invoicing-opt-in-pop-up', {
        segmentation,
      });
    }
  }

  activate() {
    let { underRegistration } = this.organizationManager.organization;
    if (underRegistration) {
      this.bannerFlashMessages.clearTopBannerMessages();
    }

    this.router.on('routeDidChange', this, this._triggerBanners);
  }

  deactivate() {
    this.bannerFlashMessages.clearTopBannerMessages();
    this.router.off('routeDidChange', this, this._triggerBanners);
  }

  _triggerBanners() {
    this.topBanners.triggerBanners();
  }
}
