/* eslint-disable @qonto/no-import-roles-constants */

import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/accounting-hub';
import { ROLES } from 'qonto/constants/membership';
import { CFT } from 'qonto/constants/sentry-tags';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class AccountingHubRoute extends Route {
  @service router;
  @service store;
  @service abilities;
  @service userManager;
  @service organizationManager;
  @service zendeskWidget;

  queryParams = {
    openZendeskWidget: {
      refreshModel: true,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);
    if (this.abilities.cannot('access accounting-hub')) {
      this.router.replaceWith('application');
    }
  }

  model({ openZendeskWidget }) {
    if (openZendeskWidget === 'true') {
      this.zendeskWidget.openAfterMounting();
    }
    return { organizations: this.organizationManager.organizations };
  }

  redirect({ organizations }, transition) {
    let { organization_slug } = transition.to.params;

    if (organization_slug) {
      this.router.replaceWith('accounting-hub.details', organization_slug);
      return;
    }

    let organizationToDisplay = this._findOrganizationToDisplay(organizations);

    if (organizationToDisplay) {
      this.router.replaceWith('accounting-hub.details', organizationToDisplay.slug);
    } else {
      let defaultOrganization = this.organizationManager.getDefaultOrganization();
      this.router.replaceWith('overview', defaultOrganization.slug);
    }
  }

  _findOrganizationToDisplay(organizations) {
    let eligibleRoles = [ROLES.ADMIN, ROLES.OWNER, ROLES.REPORTING];
    let localStorageSlug = safeLocalStorage.getItem(LOCAL_STORAGE_ORGANIZATION_KEY);

    if (localStorageSlug) {
      return organizations.find(
        ({ slug, membershipRole }) =>
          slug === localStorageSlug && eligibleRoles.includes(membershipRole)
      );
    }

    return organizations.find(({ membershipRole }) => eligibleRoles.includes(membershipRole));
  }
}
