import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { IN_PROGRESS_FINANCING_STATUSES } from 'qonto/constants/financing';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FinancingPayLaterInProgressRoute extends Route {
  @service financing;
  @service sentry;
  @service organizationManager;
  @service store;

  model() {
    this.fetchFinancingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchFinancingsTask: this.fetchFinancingsTask,
    };
  }

  fetchFinancingsTask = dropTask(async () => {
    return await this.financing.fetchFinancings([...IN_PROGRESS_FINANCING_STATUSES]);
  });

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.highlight = null;
    }
  }
}
