import Route from '@ember/routing/route';
import { service } from '@ember/service';

import window from 'ember-window-mock';

import { SLACK_OAUTH_STATE_KEY } from 'qonto/constants/hub';

export default class HubApplicationDetailsSettingsRoute extends Route {
  @service intl;
  @service toastFlashMessages;
  @service router;
  @service store;
  @service sentry;

  queryParams = {
    code: { refreshModel: true },
  };

  settingsRouteName = 'settings.connect-hub.applications.hub-application.details.settings';
  descriptionRouteName = 'settings.connect-hub.applications.hub-application.details.description';
  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';

  async model(params) {
    let parentModel = this.modelFor(this.detailsRouteName);
    let { integration, organization, hubApplication } = parentModel;
    if (integration.integration_type !== 'internal_integration') {
      return this.replaceWith(this.descriptionRouteName);
    }

    let hubConnections = await this.store.query('hub-connection', {
      organization_id: organization.id,
      includes: ['rules'],
      filters: {
        external_service: hubApplication.slug,
      },
    });
    let hubConnection = hubConnections[0];

    if (hubConnection && params.code) {
      let authenticationUrl = this.router.urlFor(
        this.settingsRouteName,
        organization.slug,
        hubApplication.slug
      );

      hubConnection.params = Object.assign(hubConnection.params, {
        code: params.code,
        redirect_uri: `${window.location.origin}${authenticationUrl}`,
      });

      try {
        let originalState = window.sessionStorage.getItem(SLACK_OAUTH_STATE_KEY);
        let isStateVerified = originalState === params.state;
        if (!isStateVerified) {
          throw new Error('Invalid Slack state.');
        }
        await hubConnection.save();
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        this.sentry.captureException(error);
      }

      return await this.transitionTo(authenticationUrl);
    }

    let rules = await hubConnection?.rules;

    return Object.assign({}, parentModel, { hubConnection, rules });
  }
}
