export default {
  "container": "qhI",
  "header-cell": "qht caption-bold",
  "col-8": "qhM",
  "table": "qhP",
  "cell": "qhl body-2",
  "cell-content": "qhX",
  "row": "qhC",
  "empty": "qhk",
  "quick-actions": "qhJ",
  "card-infos": "qhs",
  "card-digits": "qhF",
  "empty-state": "qhg"
};
