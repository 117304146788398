/* eslint-disable  ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReimbursementsPendingRoute extends Route {
  @service abilities;
  @service notifierManager;
  @service router;
  @service sentry;
  @service store;
  @service organizationManager;
  @service notifierCounterManager;
  @service subscriptionManager;

  activate() {
    let controller = this.controllerFor(this.routeName);
    this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CREATED, controller, 'reloadData');
    this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CANCELED, controller, 'employeeCanceled');
  }

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  beforeModel() {
    if (
      this.abilities.cannot('review expense report request') &&
      this.abilities.cannot('review mileage request') &&
      this.abilities.cannot('update subscription')
    ) {
      return this.router.replaceWith('reimbursements.requests');
    }
  }

  async model(params) {
    let controller = this.controllerFor(this.routeName);
    let hasTeamManagement = this.subscriptionManager.hasFeature('team_management');

    controller.memberships = hasTeamManagement
      ? (
          await this.store.query('membership', {
            organization_id: this.organizationManager.organization.id,
          })
        ).sortBy('fullName')
      : null;

    if (this.abilities.cannot('use expense reports request')) {
      return;
    }

    this.notifierCounterManager.updateCounter();
    controller.fetchDataTask
      .perform(params)
      .then(() => {
        if (params.highlight) {
          let request = controller.requests.find(({ id }) => id === params.highlight);
          if (request) {
            controller.toggleCanShowInsufficientWarning();
            controller.setAccount(request, this.organizationManager.organization.mainAccount);
            return request.belongsTo('approvalWorkflowState').reload();
          } else {
            controller.clearHighlightParam();
          }
        }
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }

  deactivate() {
    let controller = this.controllerFor(this.routeName);
    controller.destroyCurrentTransfer();

    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CREATED, controller, 'reloadData');
    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CANCELED, controller, 'employeeCanceled');
  }
}
