import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class ReceivableInvoicesPaymentLinksUpgradeController extends Controller {
  @service intl;
  @service subscriptionManager;
  @service upsellManager;
  @service router;

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('payment_links') || this.hasInitialTrial;
  }

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-account-receivable.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goToReceivableInvoices() {
    this.router.transitionTo('receivable-invoices');
  }

  @action
  upsell() {
    this.upsellManager.upsell({ feature: 'payment_links' });
  }
}
