export default {
  "page": "qod",
  "container": "qoI",
  "form-container": "qot",
  "preview-container": "qoM",
  "pdf-preview-container": "qoP",
  "content": "qol",
  "title": "qoX",
  "tabs-container": "qoC",
  "close-button": "qok btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qoJ",
  "header": "qos",
  "without-tabs": "qoF"
};
