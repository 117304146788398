import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/direct-debit-collections';
import { TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';
import { ActivationCheckpoint } from 'qonto/react/components/direct-debit-collections/activation-checkpoint';

export default class DirectDebitCollectionsController extends Controller {
  ActivationCheckpoint = ActivationCheckpoint;

  @service segment;
  @service intl;
  @service flowLinkManager;
  @service emptyStates;
  @service abilities;
  @service directDebitCollectionsManager;
  @service organizationManager;

  @tracked isSideDrawerRendered = Boolean(sessionStorage.getItem('bucketSlug'));
  @tracked isSideDrawerVisible = Boolean(sessionStorage.getItem('bucketSlug'));

  sideDrawerId = 'direct-debit-collections';
  TRACKING_ORIGINS = TRACKING_ORIGINS;

  @action
  onClickSetupCollection(origin) {
    this.directDebitCollectionsManager.handleRedirectionOnSetupCollection({
      redirectRoute: 'direct-debit-collections.index',
    });
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
  }

  @action
  onClickActivation(origin) {
    this.flowLinkManager.transitionTo({
      name: 'sdd-activation',
      stepId: 'intro',
    });

    this.emptyStates.trackCta(this.emptyStateOptions, origin);
  }

  @action
  onEbicsClick() {
    this.displaySideDrawer();

    this.emptyStates.trackCta(this.emptyStateOptions, TRACKING_ORIGINS.SECONDARY);
  }

  @action
  hideSideDrawer() {
    this.isSideDrawerVisible = false;
    sessionStorage.removeItem('bucketSlug');
    this.segment.track('ICI_sidedrawer_closed', { page: this.sideDrawerId });
  }

  @action
  displaySideDrawer() {
    this.isSideDrawerRendered = true;
    setTimeout(() => {
      this.isSideDrawerVisible = true;
      this.segment.track('ICI_trigger_clicked', { page: this.sideDrawerId });
    }, 0);
  }

  get canViewEbicsCta() {
    return this.abilities.can('access ebics directDebitCollection');
  }

  get emptyStateOptions() {
    let { isEligible, isActivated } = this.model;

    if (isActivated) {
      return this.emptyStates.getEmptyStateOptions({
        isOrgEligibleForFeature: isActivated,
        isEmptyGlobally: true,
        isEmptyLocally: true,
        hasActiveFilterOrSearch: false,
        config: getEmptyStateConfig(this.intl, {
          ctaCallback: this.onClickSetupCollection,
          secondaryCtaCallback: this.onEbicsClick,
          isActivated,
          canViewEbicsCta: this.canViewEbicsCta,
          isGermanLegalCountry: this.organizationManager.organization.isGerman,
        }),
      });
    }

    //options for both not_eligile and eligible empty states
    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: isEligible,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, {
        ctaCallback: isEligible ? this.onClickActivation : null,
      }),
    });
  }
}
