import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  REDIRECT_TO_STEP_ON_ERROR,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ReceivableInvoicesNewController extends Controller {
  @service intl;
  @service router;
  @service modals;
  @service segment;
  @service toastFlashMessages;
  @service sentry;
  @service store;
  @service abilities;

  queryParams = ['quoteId', 'customerId', 'origin', 'isDeposit', 'depositId'];

  @tracked quoteId = null;
  @tracked customerId = null;
  @tracked origin = null;
  @tracked isDeposit = false;
  @tracked depositId = null;

  @service organizationManager;

  @reads('organizationManager.organization') organization;

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  _saveAsDraft = async () => {
    if (this.model.settings?.numberingMode === 'automatic') {
      this.model.invoice.number = null;
    }
    let draft = await this.model.invoice.saveAsDraft();
    this.segment.track('invoice_draft_saved');
    // Remove locally stored items because backend returns copies of those items with new IDs
    this.model.invoice.clearItemsWithNoId();
    return draft;
  };

  onCloseTask = dropTask(async () => {
    await this.modals.open(
      'receivable-invoices/cancel-invoice-form-modal',
      {
        title: this.intl.t('receivable-invoices.invoice-creation.exit-modal.title'),
        description: this.intl.t('receivable-invoices.invoice-creation.exit-modal.description'),
        closeWithoutSaving: this.intl.t(
          'receivable-invoices.invoice-creation.exit-modal.cta.discard'
        ),
        saveAsDraft: this.intl.t('receivable-invoices.invoice-creation.exit-modal.cta.save-draft'),
        closeWithoutSavingTask: this.modalConfirmTask,
        saveAsDraftTask: this.saveAsDraftModalTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  modalConfirmTask = dropTask(async close => {
    this.segment.track('invoice_creation_canceled');
    this.router.transitionTo(this.origin ? this.origin : 'receivable-invoices.index');
    await close();
  });

  saveAsDraftModalTask = dropTask(async close => {
    this.segment.track('invoice-draft_exit_save-as-draft');
    try {
      await this._saveAsDraft();
      this.router.transitionTo('receivable-invoices.index');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoices-list.draft-saved.toast-success')
      );
    } catch (error) {
      this.router.transitionTo('receivable-invoices.new');
      this.handleError(error);
    } finally {
      await close();
    }
  });

  saveTask = dropTask(async closeModal => {
    let oldNumber = this.model.invoice.number;
    try {
      if (this.model.settings?.numberingMode === 'automatic') {
        this.model.invoice.number = null;
      }

      await this.model.invoice.save();

      this.segment.track('invoice_creation_confirmed');
      // Remove locally stored items because backend return copies of those items with new ids
      this.model.invoice.clearItemsWithNoId();

      await this.savePaymentLinkTask.perform(this.model.invoice).catch(this.handlePaymentLinkError);

      let origin = this.router.currentRouteName;

      this.router.transitionTo('receivable-invoices.share', this.model.invoice.id, {
        queryParams: { origin },
      });

      this.toastFlashMessages.toastSuccess(this.intl.t('receivable-invoices.issue-modal.toaster'));
      if (oldNumber !== this.model.invoice.number) {
        this.toastFlashMessages.toastInfo(
          this.intl.t('receivable-invoices.issue-modal.updated-number-toaster')
        );
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
    }
  });

  saveAsDraftTask = dropTask(async () => {
    try {
      await this._saveAsDraft();
      this.router.transitionTo('receivable-invoices.index');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoices-list.draft-saved.toast-success')
      );
    } catch (error) {
      this.handleError(error);
    }
  });

  saveAsDraftBeforeOnboardingTask = dropTask(async () => {
    try {
      return await this._saveAsDraft();
    } catch (error) {
      this.handleError(error);
    }
  });

  savePaymentLinkTask = dropTask(async invoice => {
    if (this.abilities.cannot('write paymentLink')) return;
    await this.store
      .createRecord('payment-link', {
        paymentInitiator: {
          resource_id: invoice.id,
          resource_type: 'Invoice',
          resource_number: invoice.number,
        },
        debitorName: invoice.customerSnapshot.name,
        amount: {
          value: invoice.totalAmount,
          currency: invoice.currency,
        },
        potentialPaymentMethods: invoice.paymentLink?.methods,
      })
      .save();
  });

  validateTask = dropTask(async () => {
    try {
      await this.model.invoice.validate();
      return true;
    } catch (error) {
      this.handleError(error);
      return false;
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } else {
      let { redirectToIndexStep, redirectToProductStep } = this._parseErrors(error);

      if (redirectToIndexStep) {
        this.router.transitionTo('receivable-invoices.new.index');
      } else if (redirectToProductStep) {
        this.router.transitionTo('receivable-invoices.new.products');
      }

      later(this, this.scrollIntoError, macroCondition(isTesting()) ? 0 : 200);
    }
  }

  _parseErrors(error) {
    let redirectToIndexStep = false;
    let redirectToProductStep = false;

    error.errors.forEach(err => {
      redirectToIndexStep ||= REDIRECT_TO_STEP_ON_ERROR.indexStep.some(attribute =>
        err.source?.pointer?.startsWith(`/data/attributes/${attribute}`)
      );
      redirectToProductStep ||= REDIRECT_TO_STEP_ON_ERROR.productsStep.some(attribute =>
        err.source?.pointer?.startsWith(`/data/attributes/${attribute}`)
      );
    });

    return {
      redirectToIndexStep,
      redirectToProductStep,
    };
  }

  handlePaymentLinkError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
  }

  scrollIntoError() {
    scrollIntoView('[data-has-error]');
  }
}
