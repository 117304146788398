import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { E_SIGNATURE_FLOW_REDIRECT } from 'qonto/react/receivable-invoices/constants';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class QuoteEsignatureFlow {
  onCompleteRoute = '';
  quoteId = '';
  signature = { url: '', status: '' };

  constructor({ onCompleteRoute, quoteId, signature }) {
    this.onCompleteRoute = onCompleteRoute;
    this.quoteId = quoteId;
    this.signature = signature;
  }
}

export default class QuoteEsignatureFlowSetup extends FlowSetup {
  @service abilities;
  @service intl;
  @service homePage;
  @service router;
  @service toastFlashMessages;

  dataContext = null;

  constructor() {
    super(...arguments);

    let { quoteId, signatureUrl } = this.router.currentRoute.queryParams;

    this.dataContext = new QuoteEsignatureFlow({
      onCompleteRoute: '',
      quoteId,
      signature: { url: signatureUrl, status: '' },
    });
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    let cannnotAccessFlow =
      this.abilities.cannot('create receivable-invoice') ||
      this.abilities.cannot('read receivable-invoice');

    if (cannnotAccessFlow) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  @action onComplete(dataContext) {
    let { onCompleteRoute, quoteId } = dataContext;

    if (onCompleteRoute === E_SIGNATURE_FLOW_REDIRECT.QUOTES_SHARE) {
      this.router.transitionTo(onCompleteRoute, quoteId);
    } else if (onCompleteRoute === E_SIGNATURE_FLOW_REDIRECT.QUOTES_SHOW) {
      this.router.transitionTo(onCompleteRoute, quoteId);
    } else {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.quote-sending.send-quote.toast-success')
      );
      this.router.transitionTo(E_SIGNATURE_FLOW_REDIRECT.QUOTES_INDEX);
    }
  }

  onAbort() {
    let { quoteId } = this.dataContext;

    this.router.transitionTo(E_SIGNATURE_FLOW_REDIRECT.QUOTES_SHARE, quoteId);
  }
}
