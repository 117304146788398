import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/quotes';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { QUOTE_STATUSES } from 'qonto/constants/quotes';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { E_SIGNATURE_INSTRUCTIONAL_TOOLTIP_STORAGE_KEY } from 'qonto/react/receivable-invoices/constants';

const TAB_NAMES = {
  COMPLETED: 'completed',
  PENDING: 'pending',
};

export default class QuotesIndexController extends Controller {
  InstructionalTooltip = InstructionalTooltip;

  @service intl;
  @service router;
  @service segment;
  @service abilities;
  @service organizationManager;
  @service modals;
  @service emptyStates;
  @service zendeskLocalization;

  queryParams = ['status', 'page', 'perPage', 'sortBy'];
  @tracked status = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = this.defaultSortBy;
  @tracked isTooltipDismissed = false;

  get sideDrawerId() {
    return this.abilities.can('request financing') ? 'quotes-with-financing' : 'quotes';
  }

  get emptyStateRevampOptions() {
    if (this.model.quotesTask.isRunning) {
      return;
    }

    let isEmptyGlobally = this.quoteStats?.created.total === 0;
    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: Boolean(this.filterValue),
      config: getEmptyStateConfig(this.intl, { ctaCallback: this.createQuote }),
      customInputs: {
        tab: this.tabName,
      },
    });
  }

  get tabName() {
    let statuses = this.status?.split(',') || [];
    if (statuses.includes(QUOTE_STATUSES.APPROVED) || statuses.includes(QUOTE_STATUSES.CANCELED)) {
      return TAB_NAMES.COMPLETED;
    } else {
      return TAB_NAMES.PENDING;
    }
  }

  get isInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get isEmptyLocally() {
    let { quoteStats, status, tabName } = this;
    if (tabName === TAB_NAMES.COMPLETED) {
      let statusCode = QUOTE_STATUSES[status.toUpperCase()];
      if (statusCode) {
        return quoteStats?.created[statusCode] === 0;
      } else {
        return (
          quoteStats?.created[QUOTE_STATUSES.CANCELED] === 0 &&
          quoteStats?.created[QUOTE_STATUSES.APPROVED] === 0
        );
      }
    } else {
      return quoteStats?.created.pendingApproval === 0;
    }
  }

  get canFullyAccess() {
    return this.abilities.can('fully access receivableInvoice');
  }

  get filterOptions() {
    return [
      {
        code: QUOTE_STATUSES.APPROVED,
        value: this.intl.t('receivable-invoices.quotes-status.approved'),
      },
      {
        code: QUOTE_STATUSES.CANCELED,
        value: this.intl.t('receivable-invoices.quotes-status.canceled'),
      },
    ];
  }

  get filterValue() {
    if (this.status?.split(',').length > 1) {
      return;
    }

    return this.filterOptions.filter(el => el.code === this.status)[0];
  }

  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'quotes',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleClickTacking() {
    this.trackCtaEvent(TRACKING_ORIGINS.HEADER);
  }

  @action
  createQuote(origin) {
    this.trackCtaEvent(origin);
    this.router.transitionTo('quotes.new');
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      this.status = this.filterOptions.map(el => el.code).join(',');
      return;
    }
    this.status = selected.code;
  }

  get currentParams() {
    return {
      status: this.status,
      sortBy: this.sortBy,
      page: this.page,
      perPage: this.perPage,
    };
  }

  get hasReachedLimitOfQuotes() {
    return this.model.quotesTask.lastSuccessful?.value.hasReachedLimitOfQuotes;
  }

  get isCreateQuoteCTADisplayed() {
    return !this.hasReachedLimitOfQuotes;
  }

  get defaultSortBy() {
    if (this.status === QUOTE_STATUSES.PENDING) {
      return '-expiry_date,-number';
    }
    return '-issue_date,-number';
  }

  get isFilterActive() {
    return this.status !== null;
  }

  get quotes() {
    return this.model.quotesTask.lastSuccessful?.value.quotes || [];
  }

  get settings() {
    return this.model.settingsTask.lastSuccessful?.value;
  }

  get showStatusFilter() {
    return (
      this.tabName === TAB_NAMES.COMPLETED &&
      (this.model.quotesTask.lastSuccessful?.value.meta.total_count > 0 ||
        [QUOTE_STATUSES.APPROVED, QUOTE_STATUSES.CANCELED].includes(this.status))
    );
  }

  get quoteStats() {
    return this.model.quotesTask.lastSuccessful?.value.quoteStats;
  }

  get columnsToShow() {
    return {
      status: this.tabName === TAB_NAMES.COMPLETED,
      customer: true,
      issueDate: true,
      expiryDate: true,
      amountDue: true,
    };
  }

  get localState() {
    let isShowRoute = this.router.currentRouteName.includes('quotes.show');

    let localState = {
      isEmpty: false,
      isError: false,
      isLoading: false,
    };
    if ((this.model.quotesTask.isRunning || this.model.settingsTask.isRunning) && !isShowRoute) {
      localState.isLoading = true;
    } else if (this.model.quotesTask.last.isError || this.model.settingsTask.last.isError) {
      localState.isError = true;
    } else if (this.quotes.length === 0) {
      if ([QUOTE_STATUSES.APPROVED, QUOTE_STATUSES.CANCELED].includes(this.status)) {
        return;
      }
      localState.isEmpty = true;
    }

    return localState;
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.perPage = perPage;
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  get shouldDisplayTooltip() {
    if (!variation('feature--boolean-ar-quotes-esignature')) {
      return false;
    }

    let storageValue = safeLocalStorage.getItem(E_SIGNATURE_INSTRUCTIONAL_TOOLTIP_STORAGE_KEY);

    return storageValue !== 'true';
  }

  get eSignatureFaqLinkUrl() {
    return this.zendeskLocalization.getLocalizedArticle('quotes-esignature');
  }

  @action
  dismissTooltip() {
    safeLocalStorage.setItem(E_SIGNATURE_INSTRUCTIONAL_TOOLTIP_STORAGE_KEY, true);

    this.isTooltipDismissed = true;
  }
}
