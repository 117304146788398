export default {
  "container": "qaV",
  "page-wrapper": "qaL",
  "header": "qaT",
  "back-button-wrapper": "qaz",
  "header-inner": "qaa",
  "header-main-wrapper": "qaH",
  "header-main": "qaO",
  "header-right": "qam",
  "content-wrapper": "qap",
  "content": "qax",
  "content-body": "qaw",
  "sidebar": "qaN"
};
