import Route from '@ember/routing/route';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class TransactionsRoute extends Route {
  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);
  }
}
