import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class EinvoiceActivationRoute extends Route {
  @service menu;
  @service organizationManager;
  @service store;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'e-invoicing');
  }

  model() {
    return this.store.findRecord('e-invoice-activation', this.organizationManager.organization.id);
  }

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    this.menu.show();
  }
}
