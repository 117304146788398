import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
import { IDENTITY_TYPES, LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/oauth';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class OauthIndexRoute extends Route {
  @service networkManager;
  @service organizationManager;
  @service sessionManager;
  @service sentry;
  @service store;
  @service userManager;

  async beforeModel(transition) {
    let { login_challenge: loginChallenge } = transition.to.queryParams;

    if (!loginChallenge) {
      return this.replaceWith('/404');
    }

    this.loginRequest = await this.networkManager.request(
      `${apiBaseURL}/${oauthNamespace}/oauth/login_requests?login_challenge=${loginChallenge}`
    );

    if (this.loginRequest.skip_login_prompt) {
      transition.abort();

      let redirectTo = await this.performSilentLogin(loginChallenge);
      window.location.replace(redirectTo);

      return;
    }

    this.sessionManager.requireAuthentication(transition, 'signin');
  }

  async model(params, transition) {
    await this.sessionManager.setupData();

    if (this.loginRequest.identity_type === IDENTITY_TYPES.USER) {
      transition.abort();

      let redirectTo = await this.performSilentLogin(params.login_challenge);
      window.location.replace(redirectTo);

      return;
    }

    if (this.loginRequest.organization_id) {
      transition.abort();

      let redirectTo = await this.acceptLoginRequestWithOrganization(
        params.login_challenge,
        this.loginRequest.organization_id
      );

      safeLocalStorage.setItem(LOCAL_STORAGE_ORGANIZATION_KEY, this.loginRequest.organization_id);
      window.location.replace(redirectTo);

      return;
    }

    let memberships = await this.getMembershipsTask.perform(this.organizationManager.organizations);

    return {
      login: this.loginRequest,
      organizations: memberships.map(membership => {
        let organization = membership.get('organization');

        organization.disabled = !membership.permissions?.oauth?.rules.some(
          rule => rule.action === 'login'
        );

        return organization;
      }),
    };
  }

  async acceptLoginRequestWithOrganization(loginChallenge, organizationId) {
    try {
      let { redirect_to: redirectTo } = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/accept`,
        {
          method: 'POST',
          data: JSON.stringify({
            login_challenge: loginChallenge,
            organization_id: organizationId,
          }),
        }
      );

      return redirectTo;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  async performSilentLogin(loginChallenge) {
    try {
      if (this.sessionManager.isAuthenticated) {
        let { redirect_to: redirectTo } = await this.networkManager.request(
          `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/accept`,
          {
            method: 'POST',
            data: JSON.stringify({ login_challenge: loginChallenge }),
          }
        );

        return redirectTo;
      } else {
        let { redirect_to: redirectTo } = await this.networkManager.request(
          `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/reject`,
          {
            method: 'POST',
            data: JSON.stringify({
              login_challenge: loginChallenge,
              error: {
                code: 'user_not_logged_in',
                description: 'user was not logged in and login prompt was skipped',
              },
            }),
          }
        );

        return redirectTo;
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (this.organizationManager.organizations.length === 1) {
      controller.organization = this.organizationManager.organizations[0];
    }
  }

  getMembershipsTask = dropTask(async organizations => {
    return await Promise.all(
      organizations.map(async organization => {
        if (organization.memberships.length) {
          return organization.memberships[0];
        } else {
          return await this.store.adapterFor('membership').fetchMembershipMe({
            organizationId: organization.id,
          });
        }
      })
    );
  });
}
