import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class QuotesEditProductsRoute extends Route {
  @service router;
  @service store;

  beforeModel() {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      this.router.transitionTo('quotes.duplicate.index');
    }
  }
}
