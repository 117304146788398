export default {
  "page": "qvO",
  "container": "qvm",
  "form-container": "qvp",
  "preview-container": "qvx",
  "pdf-preview-container": "qvw",
  "content": "qvN",
  "title": "qvv",
  "tabs-container": "qvo",
  "close-button": "qvn btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvi",
  "header": "qvA",
  "without-tabs": "qvY"
};
