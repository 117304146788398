export default {
  "container": "qAM",
  "content": "qAP",
  "setup-box": "qAl",
  "setup-wizard": "qAX",
  "setup-success": "qAC",
  "setup-success-lottie": "qAk",
  "setup-close-btn": "qAJ",
  "tray-connection": "qAs",
  "close-button": "qAF"
};
