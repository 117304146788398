export default {
  "member-details": "qHE",
  "header": "qHd",
  "header-img": "qHI",
  "invited-persona": "qHt",
  "revoked-persona": "qHM",
  "invited-icon": "qHP",
  "revoked-icon": "qHl",
  "dropdown": "qHX",
  "header-body": "qHC",
  "name": "qHk title-3",
  "email": "qHJ caption-bold",
  "actions": "qHs body-2",
  "actions-cards": "qHF",
  "actions-transactions": "qHg",
  "body": "qHD",
  "expense-permissions-section": "qHV",
  "body-title": "qHL title-4",
  "body-attr": "qHT",
  "body-label": "qHz",
  "attr-buttons": "qHa",
  "body-role": "qHH small",
  "details-actions": "qHO",
  "actions-item": "qHm",
  "disclaimer": "qHp",
  "permissions": "qHx",
  "permission": "qHw",
  "permission-label": "qHN",
  "permission-check": "qHv",
  "permission-missing": "qHo",
  "editable-section": "qHn"
};
