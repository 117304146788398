import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { didCancel, dropTask, restartableTask, timeout } from 'ember-concurrency';

import { REQUEST_TYPES } from 'qonto/constants/requests';
import { SUBSCRIPTION_STATUS } from 'qonto/constants/subscriptions';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReimbursementsRequestsRoute extends Route {
  @service sentry;
  @service abilities;
  @service featuresManager;
  @service router;
  @service organizationManager;
  @service store;
  @service homePage;
  @service subscriptionManager;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  beforeModel() {
    if (this.abilities.cannot('use reimbursements request')) {
      this.homePage.visitDefaultPage();
    }

    if (
      this.abilities.can('review expense report request') ||
      this.abilities.can('review mileage request')
    ) {
      return this.router.replaceWith('reimbursements.pending');
    }
  }

  async model(params) {
    let subscription = this.subscriptionManager.currentSubscription;

    await this.fetchDataTask.perform(params).catch(error => {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        subscription.status !== SUBSCRIPTION_STATUS.INACTIVE
      ) {
        this.sentry.captureException(error);
      }
    });

    return {
      requests: this.fetchDataTask.last?.value,
      fetchDataTask: this.fetchDataTask,
      allocatedBudget: this.fetchAllocatedBudgetTask.last?.value,
      fetchAllocatedBudgetTask: this.fetchAllocatedBudgetTask,
    };
  }

  fetchDataTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by } = filterParams(params);

    let requestModel = {
      includes: ['memberships'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
      request_type: ['expense_report', 'mileage'],
    };

    let requests = await this.store.query('request', requestModel);

    return requests;
  });

  fetchAllocatedBudgetTask = dropTask(async request => {
    if (
      !request ||
      this.abilities.cannot('read budget') ||
      [REQUEST_TYPES.MILEAGE].includes(request.requestType) ||
      !request.pending
    ) {
      return;
    }

    let requestPeriodAmounts = this.abilities.can('review expense report request');
    let initiatorId = request.initiator.get('id');
    let scheduledDate = new Date();

    requestPeriodAmounts &= this.organizationManager.membership.id !== initiatorId;

    try {
      let results = await this.store.adapterFor('budget').search({
        initiatorId,
        scheduledDate,
        includes: requestPeriodAmounts ? ['period_amounts'] : [],
      });
      return results[0];
    } catch (error) {
      if (didCancel(error)) return;

      if (ErrorInfo.for(error).shouldSendToSentry) {
        return this.sentry.captureException(error);
      }
    }
  });
}
