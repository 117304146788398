export default {
  "mandates": "qpH",
  "mandates-empty": "qpO",
  "header": "qpm",
  "header-empty": "qpp",
  "header-content": "qpx",
  "search": "qpw",
  "search-bar": "qpN",
  "body": "qpv",
  "isEmpty": "qpo",
  "left-section": "qpn",
  "mandates-list": "qpi",
  "mandate-suspended": "qpA",
  "list-title": "qpY caption-bold",
  "list-empty": "qph",
  "empty-illustration": "qpf",
  "empty-title": "qpK title-3",
  "empty-description": "qpG body-2",
  "body-details": "qpr",
  "l-app-content__page-header": "qpb",
  "pagination-footer": "qpy"
};
