import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import {
  DEFAULT_SOURCE_CURRENCY_CODE,
  DEFAULT_TARGET_CURRENCY_CODE,
} from 'qonto/constants/international-out/quote';
import { TRANSFER_FLOW_EXIT_ROUTE, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import transformKeys from 'qonto/utils/transform-keys';

class InternationalOutFlowDataContext {
  quote = null;
  fees = null;
  beneficiary = null;
  targetAccount = null;
  bankAccount = null;
  transferDetails = null;
  schedule = null;
  attachments = [];
  additionalRequirements = null;
  invoice = null;
  confirmationResult = null;
  budgetName = null;
  settings = null;

  constructor({ bankAccount, settings }) {
    this.bankAccount = bankAccount;
    this.settings = settings;
  }
}

export default class InternationalOutFlowSetup extends FlowSetup {
  @service abilities;
  @service flow;
  @service homePage;
  @service internationalOutManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;

  constructor() {
    super(...arguments);
    let { defaultAccount, transferSettings } = this.organizationManager.organization;
    let { origin, targetCurrency, exitRoute } = this.router.currentRoute.queryParams;
    this.dataContext = new InternationalOutFlowDataContext({
      bankAccount: defaultAccount,
      settings: {
        attachment: {
          maximumAmountWithout:
            (transformKeys(transferSettings)?.maxAmountWithoutAttachmentCents ?? 0) / 100,
        },
        preferred: {
          sourceCurrency: DEFAULT_SOURCE_CURRENCY_CODE,
          targetCurrency: targetCurrency ?? DEFAULT_TARGET_CURRENCY_CODE,
        },
        ...(origin && { origin }),
        ...(exitRoute && { exitRoute }),
      },
    });
    this.internationalOutManager.addIdempotencyKey();
  }

  get #refererPage() {
    if (this.dataContext.settings.exitRoute === TRANSFER_FLOW_EXIT_ROUTE.TASKS) {
      return 'tasks.pending';
    }

    if (this.dataContext.settings.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      return 'supplier-invoices';
    }

    let refererPage = this.flow.refererPage;

    if (!refererPage || refererPage === '/') {
      return 'transfers.landing';
    }

    return refererPage;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    let canAccess =
      this.abilities.can('create transfer') && (await this.internationalOutManager.isEligible());

    if (!canAccess) {
      this.homePage.replaceWithDefaultPage();
    }

    let { supplierInvoiceId } = this.router.currentRoute.queryParams;

    if (supplierInvoiceId) {
      let supplierInvoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
      if (supplierInvoice) {
        await this.setSupplierInvoiceDataTask.perform(supplierInvoice);
      }
    }
  }

  @action
  onComplete() {
    this.router.transitionTo(this.#refererPage);
  }

  onAbortTask = dropTask(async (_, { id: currentStepId }) => {
    if (currentStepId !== 'quote') {
      let result = await this.openAbortModalTask.perform();

      if (result !== 'confirm') {
        return false;
      }
    }

    this.router.transitionTo(this.#refererPage);

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.exit-flow.title'),
      description: this.intl.t('transfers.exit-flow.subtitle'),
      cancel: this.intl.t('transfers.exit-flow.cancel'),
      confirm: this.intl.t('transfers.exit-flow.confirm'),
    });
  });

  setSupplierInvoiceDataTask = dropTask(async invoice => {
    let attachment = await this.store.findRecord(
      'attachment',
      invoice.belongsTo('attachment').id()
    );

    if (attachment) {
      let {
        id,
        displayAttachment,
        totalAmount: { value, currency },
        invoiceNumber,
        supplierName: legacySupplierName,
        supplierSnapshot: { name: supplierName, iban },
        iban: legacyIban,
        isAttachmentNonFinancial,
        isGermanInvoice,
      } = invoice;

      Object.assign(this.dataContext, {
        attachments: [attachment],
        invoice: {
          id,
          attachment,
          displayAttachment: displayAttachment ?? null,
          amount: Number(value),
          currency,
          number: invoiceNumber ?? null,
          supplierName: supplierName ?? legacySupplierName ?? null,
          supplierAccountIdentifier: iban ?? legacyIban ?? null,
          isAttachmentNonFinancial,
          isGermanInvoice,
        },
      });
    }
  });
}
