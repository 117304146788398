import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import {
  FACILE_INSURANCE_PRODUCT_LEGAL_TYPES,
  PRODUCT_ROUTE,
  PROVIDERS_MAP,
} from 'qonto/constants/insurance-hub';

export default class InsuranceHubProductController extends Controller {
  @service router;
  @service segment;
  @service organizationManager;
  @service intl;

  @tracked showCompactHeader = false;

  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', transition => {
      if (transition.from && transition.from.name === PRODUCT_ROUTE) {
        this.showCompactHeader = false;
      }
    });
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get isItalian() {
    return this.organizationManager.organization.isItalian;
  }

  get legalNotice() {
    if (this.isItalian && variation('feature--boolean-insurance-hub-it')) {
      switch (this.model.uid) {
        case FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.MULTI_RISK_FOR_PROFESSIONAL:
          return this.intl.t('insurance-hub.footer.legal-notice-it.multi-risk-for-professional');
        case FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.TERM_LIFE:
        case FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.INJURIES:
          return this.intl.t('insurance-hub.footer.legal-notice-it.personal-protection');
        default:
          return null;
      }
    }

    return null;
  }

  get showFooterLegalNotice() {
    return this.isItalian && variation('feature--boolean-insurance-hub-it') && this.legalNotice;
  }

  @action
  handleIntersect(entries) {
    this.showCompactHeader = entries.some(entry => !entry.isIntersecting);
  }

  @action
  handleChangeTab(tab) {
    this.segment.track('insurance-hub-offer-details_tab_clicked', { tab });
  }

  get partnerName() {
    return PROVIDERS_MAP[this.legalCountry].providerName;
  }
}
