import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { receivableInvoicesURL } from 'qonto/constants/hosts';
import { STATUS } from 'qonto/constants/receivable-invoice';
import { EmailPreview } from 'qonto/react/components/receivable-invoices/email-preview';

export default class ReceivableInvoicesShareController extends Controller {
  emailPreview = EmailPreview;

  @service router;
  @service intl;
  @service organizationManager;
  @service upsellManager;
  @service mollie;
  @service theme;

  @reads('organizationManager.organization') organization;

  @tracked isAccountantTabActive;
  @tracked sendTo;
  @tracked sendToHasErrors;
  @tracked emailSubject;
  @tracked emailMessage;
  @tracked copyToSelf;
  @tracked contactEmail;

  isCurrentThemeDark = this.theme.isCurrentThemeDark;

  get emailMessageLines() {
    return this.emailMessage
      ? this.emailMessage.replace('\r\n', '\n').replace('\r', '\n').split('\n')
      : [];
  }

  @action
  onClose() {
    if (this.model.origin?.name === 'upcoming-transactions.index') {
      this.router.transitionTo('upcoming-transactions.index');
    } else if (this.shouldUpsellPaymentLinks) {
      this.router.transitionTo('receivable-invoices.payment-links-upgrade');
    } else {
      this.router.transitionTo('receivable-invoices.index');
    }
  }

  @action
  activateDefaultTab() {
    this.isAccountantTabActive = false;
    this.handleDefaultEmailTemplate();
  }

  @action
  activateAccountantTab() {
    this.isAccountantTabActive = true;
    this.handleAccountantEmailTemplate();
  }

  get isDefaultEmailTemplate() {
    return !this.model.emailTemplate?.updated_at;
  }

  @action
  handleDefaultEmailTemplate() {
    this.sendTo = this.model.invoice.customerSnapshot?.email || '';
    this.emailSubject = this.model.emailTemplate.subject;
    this.emailMessage = this.model.emailTemplate.body;
  }

  @action
  handleAccountantEmailTemplate() {
    this.sendTo = '';
    this.emailSubject = this.intl.t(
      'receivable-invoices.share-accountant-email.field-subject.placeholder',
      {
        language: this.emailPreviewLanguage,
      }
    );
    this.emailMessage = this.intl.t(
      'receivable-invoices.share-accountant-email.field-message.placeholder',
      {
        language: this.emailPreviewLanguage,
      }
    );
  }

  setDefaultParams() {
    let { membership } = this.organizationManager;

    this.contactEmail = this.model.settings?.contactEmail || membership.email;

    this.activateDefaultTab();

    this.sendToHasErrors = false;

    this.copyToSelf = true;
  }

  get isItalianOrganization() {
    return this.organization.legalCountry === 'IT';
  }

  get shareableLink() {
    return `${receivableInvoicesURL}/invoices/${this.model.invoice.id}`;
  }

  get isDraft() {
    return this.model.invoice.status === STATUS.DRAFT;
  }

  get isFrenchEinvoice() {
    return (
      this.model.invoice.isEinvoice ||
      Boolean(this.model.invoice?.customerSnapshot?.eInvoicingAddress)
    );
  }

  get getShareByLinkEvent() {
    return this.isDraft ? 'invoice-draft_send-by-link_copied' : 'invoice_send-by-link_copied';
  }

  get showUpdateEmailTrigger() {
    return (
      this.model.emailTemplate?.subject !== this.emailSubject ||
      this.model.emailTemplate?.body !== this.emailMessage
    );
  }

  get logo() {
    return this.organization.get('isDefaultAvatar') !== null &&
      this.organization.get('isDefaultAvatar') === false
      ? this.organization.picture
      : null;
  }

  get emailPreviewLanguage() {
    return this.model.invoice.locale ?? this.model.invoice.get('customer.locale');
  }

  get attachments() {
    return this.model.attachments;
  }

  get shouldUpsellPaymentLinks() {
    return this.upsellManager.shouldShowFreemiumUpgrade && !this.mollie.isEnabled;
  }
}
