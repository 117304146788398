export default {
  "bank-accounts-show": "qDa",
  "header": "qDH l-app-content__page-header",
  "share-and-certificate": "qDO",
  "share": "qDm",
  "certificate": "qDp",
  "section-divider": "qDx",
  "content-wrapper": "qDw",
  "content": "qDN"
};
