/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { APPROVAL_WORKFLOW_STATUSES } from 'qonto/constants/approval-workflow';
import { CARD_LEVELS } from 'qonto/constants/cards';
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { getEmptyStateConfig, TABS } from 'qonto/constants/empty-states/tasks-and-requests';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { ROLES } from 'qonto/constants/membership';
import {
  REQUEST_EVENT_METHOD,
  REQUEST_ORIGINS,
  REQUEST_TYPES,
  STATUS,
} from 'qonto/constants/requests';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { TRANSFER_FLOW_EXIT_ROUTE, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { userIsCurrent } from 'qonto/utils/approval-workflow-state';
import { isIbanFromSepaZone } from 'qonto/utils/beneficiaries';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';
import { convertTransferRequestToTransfer } from 'qonto/utils/transfer-requests';

const DEFAULT_SORT_BY = 'created_at:desc';
const NEXT_ELEMENT_DELAY = 800;

export default class TasksPendingIndexController extends Controller {
  @service featuresManager;
  @service abilities;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service store;
  @service segment;
  @service sentry;
  @service modals;
  @service router;
  @service notifierCounterManager;
  @service requestsManager;
  @service supplierInvoicesManager;
  @service sensitiveActions;
  @service emptyStates;
  @service flowLinkManager;
  @service internationalOutManager;

  CARD_LEVELS = CARD_LEVELS;

  currentTransfer = null;
  @tracked confirmWarnings = [];
  @tracked confirmErrors = [];
  @tracked beneficiaryWarning = [];
  @tracked spendLimitsWarning = [];
  @tracked spendLimits = [];
  @tracked allocatedBudget;
  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked meta = null;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked selectedAccountHasInsufficientFunds = false;
  @tracked canShowInsufficientWarning = false;

  @reads('organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts')
  hasMultipleActiveCurrentRemuneratedAccounts;

  get hasInsuficientFunds() {
    return this.selectedAccountHasInsufficientFunds;
  }

  get canShowAccountSelector() {
    return (
      this.abilities.can('review expense report request') &&
      this.hasMultipleActiveCurrentRemuneratedAccounts &&
      !this.spendLimitsWarning
    );
  }

  get counter() {
    return this.notifierCounterManager.counter?.request === 0
      ? null
      : this.notifierCounterManager.counter?.request;
  }

  get requests() {
    return this.fetchDataTask.last.value?.filter(({ status }) => status === STATUS.PENDING) ?? [];
  }

  get shouldSyncRequests() {
    return !this.requests.length && this.meta.total_pages > 1;
  }

  get localState() {
    let { isRunning, last } = this.fetchDataTask;
    let isEmpty = this.requests.length === 0;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
      };
    }

    if (last.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
      };
    }

    if (isEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
      };
    }
  }

  get hideAccountSelect() {
    return this.organizationManager.organization?.activeAccounts?.length === 1;
  }

  get isEmptyLocally() {
    return this.requests.length === 0;
  }

  get isEmptyGlobally() {
    let {
      totalDirectDebitCollectionRequests = 0,
      totalTransferRequests = 0,
      totalMileageRequests = 0,
      totalExpenseReportRequests = 0,
      totalCardRequests = 0,
      totalSupplierInvoiceRequests = 0,
    } = this.notifierCounterManager.counter || {};
    return (
      totalDirectDebitCollectionRequests +
        totalTransferRequests +
        totalMileageRequests +
        totalExpenseReportRequests +
        totalCardRequests +
        totalSupplierInvoiceRequests ===
      0
    );
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get emptyStateRevampOptions() {
    if (this.fetchDataTask.isRunning || this.fetchDataTask.last?.isError) {
      return;
    }

    let canUse = this.abilities.can('use request');
    let canReviewExpenseReportMileageTransferCardRequests =
      canUse &&
      (this.abilities.can('review card request') ||
        this.abilities.can('review transfer request') ||
        this.abilities.can('review expense-report request') ||
        this.abilities.can('review mileage request'));

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, 'tasks'),
      customInputs: {
        tab: TABS.PENDING,
      },
      abilities: {
        canReviewExpenseReportMileageTransferCardRequests,
        canReviewTransfers: this.abilities.can('review transfer request'),
      },
    });
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action
  setAccount(request, account) {
    request.bankAccount = account;

    this.setProperties({ confirmWarnings: [], confirmErrors: [] });

    this.segment.track('request_account_selected', {
      request_id: request.id,
      origin: REQUEST_ORIGINS.tasks,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
      role: this.organizationManager.membership.role,
      request_type: request.requestType,
      ...(request.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION && {
        direct_debits_count: request.totalCount,
      }),
    });

    return this.confirmRequestTask.perform(request);
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  @action handleDecline(request) {
    this.segment.track('request_declined_clicked', {
      origin: REQUEST_ORIGINS.tasks,
      method: 'sidepanel',
      request_type: request.requestType,
    });

    request.declineRequest();
    this.openNextElement(request);
  }

  @action handleCancel(request) {
    this.openNextElement(request);
  }

  @action
  showAccountInsufficientFundsWarning() {
    this.selectedAccountHasInsufficientFunds = true;
  }

  @action
  toggleCanShowInsufficientWarning(value) {
    this.canShowInsufficientWarning = value ?? !this.canShowAccountSelector;
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let { role } = this.organizationManager.membership;

    if (!requestId) {
      this.highlight = null;
      return;
    }

    let request = this.requests.find(({ id }) => id === requestId);

    this.segment.track('request_details_opened', {
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      role,
      origin: REQUEST_ORIGINS.tasks,
      ...(request.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION && {
        direct_debits_count: request.totalCount,
      }),
    });

    if (request.requestType === REQUEST_TYPES.SUPPLIER_INVOICE) {
      this.router.transitionTo('supplier-invoices.show', request.supplierInvoice.id);
      return;
    }

    if (!request.bankAccount) {
      this.toggleCanShowInsufficientWarning();
      this.setAccount(request, this.organizationManager.organization.mainAccount);
    }

    if (request.requestType === REQUEST_TYPES.TRANSFER) {
      this.currentTransfer = await convertTransferRequestToTransfer({
        dataStore: this.store,
        transferRequest: request,
        bankAccount: request.bankAccount,
      });

      await request.hasMany('attachments').load();
    }

    if (request.requestType === REQUEST_TYPES.MULTI_TRANSFER) {
      this.router.transitionTo('tasks.pending.multi-transfer-detail', request.id);
      return;
    }

    if (request.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION) {
      this.router.transitionTo('tasks.pending.multi-direct-debit-collection', request.id);
      return;
    }

    await request.belongsTo('approvalWorkflowState').reload();

    this.allocatedBudget = await this.requestsManager.fetchAllocatedBudgetTask.perform(request);

    this.highlight = request.id;

    return this.confirmRequestTask.perform(request);
  });

  approveTask = dropTask(async request => {
    this.segment.track('request_approved_clicked', {
      origin: REQUEST_ORIGINS.tasks,
      method: 'sidepanel',
      request_type: request.requestType,
      outcome: request.lastStep ? 'payment' : 'approval',
    });

    try {
      await request.approveRequest();
      this.notifierCounterManager.updateCounter();

      if (this.shouldSyncRequests) {
        this.resetQueryParams();
        this.reloadData();
      }

      this.openNextElement(request);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  supplierInvoiceQuickApproveTask = dropTask(async request => {
    if (request.lastStep) {
      await this.paySupplierInvoiceTask.perform(request);
    } else {
      await this.approveSupplierInvoiceTask.perform(request);
    }
  });

  paySupplierInvoiceTask = dropTask(async request => {
    // @ts-expect-error
    let supplierInvoice = await request.get('supplierInvoice');

    let queryParams = {
      origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      supplierInvoiceId: supplierInvoice.id,
      bankAccountId: request.bankAccount.id,
      exitRoute: TRANSFER_FLOW_EXIT_ROUTE.TASKS,
    };

    let { supplierSnapshot, totalAmount } = supplierInvoice;

    let isSwift = totalAmount && totalAmount?.currency !== CURRENCIES.EUR;

    let supplierIban = supplierSnapshot?.iban || supplierInvoice.iban;
    let supplierIbanIsNotSepa = Boolean(supplierIban && !isIbanFromSepaZone(supplierIban));

    if (isSwift || supplierIbanIsNotSepa) {
      let isEligibleToInternationalTransfers = await this.internationalOutManager.isEligible();

      if (!isEligibleToInternationalTransfers) {
        // In case that it is not eligible for international transfers, we redirect to the invoice modal
        // where the pay button is disabled and the user can review the supplier-invoice details .
        // This is to avoid disabling the pay button in Tasks quick actions
        // also to avoid triggering the international-out flow if the user is not eligible.
        return this.router.transitionTo('supplier-invoices.show', supplierInvoice.id);
      }

      return this.flowLinkManager.transitionTo({
        name: 'international-out',
        stepId: 'quote',
        queryParams,
      });
    }

    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'invoice',
      queryParams,
    });
  });

  quickApproveTask = dropTask(async request => {
    this.segment.track('request_approved_clicked', {
      request_id: request.id,
      origin: REQUEST_ORIGINS.tasks,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
      role: this.organizationManager.membership.role,
      outcome: request.lastStep ? 'payment' : 'approval',
      request_type: request.requestType,
      ...(request.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION && {
        direct_debits_count: request.totalCount,
      }),
    });

    switch (request.requestType) {
      case REQUEST_TYPES.MULTI_TRANSFER:
      case REQUEST_TYPES.TRANSFER:
        await this.requestsManager.quickApproveTransferRequestTask.perform(request);
        break;
      case REQUEST_TYPES.FLASH_CARD:
      case REQUEST_TYPES.VIRTUAL_CARD:
        await this.requestsManager.quickApproveCardRequestTask.perform(request);
        break;
      case REQUEST_TYPES.EXPENSE_REPORT:
      case REQUEST_TYPES.MILEAGE:
        await this.requestsManager.quickApproveReimbursementRequestTask.perform(request);
        break;
      case REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION:
        await this.sensitiveActions.runTaskNoDrop.perform(
          this.requestsManager.approveMultiRequestDirectDebitCollectionTask,
          request
        );
        break;
      case REQUEST_TYPES.SUPPLIER_INVOICE:
        await this.supplierInvoiceQuickApproveTask.perform(request);
        break;
    }

    this.notifierCounterManager.updateCounter();

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  quickRejectTask = dropTask(async request => {
    let isRequester = request.initiator.get('id') === this.organizationManager.membership.id;

    let trackedEvent =
      isRequester && request.requestType !== REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION
        ? 'request_cancel_clicked'
        : 'request_declined_clicked';

    this.segment.track(trackedEvent, {
      request_id: request.id,
      origin: REQUEST_ORIGINS.tasks,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
      role: this.organizationManager.membership.role,
      request_type: request.requestType,
      ...(request.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION && {
        direct_debits_count: request.totalCount,
      }),
    });

    switch (request.requestType) {
      case REQUEST_TYPES.MULTI_TRANSFER:
      case REQUEST_TYPES.TRANSFER:
        await this.requestsManager.quickRejectTransferRequestTask.perform(request);
        break;
      case REQUEST_TYPES.FLASH_CARD:
      case REQUEST_TYPES.VIRTUAL_CARD:
        await this.requestsManager.quickDeclineCardRequestTask.perform(request);
        break;
      case REQUEST_TYPES.EXPENSE_REPORT:
      case REQUEST_TYPES.MILEAGE:
        await this.requestsManager.quickRejectReimbursementRequestTask.perform(request);
        break;
      case REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION:
        await this.modals.open('request/sidebar/modals/decline-request', {
          request,
          confirmTask: this.confirmSDDRequestRejectionTask,
        });
        break;
      case REQUEST_TYPES.SUPPLIER_INVOICE:
        await this.modals.open('supplier-invoices/modals/decline-invoice', {
          confirmTask: this.rejectSupplierInvoiceTask,
          additionalData: {
            request,
          },
        });
        break;
    }

    this.notifierCounterManager.updateCounter();

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  cancelTask = dropTask(async request => {
    if ([REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD].includes(request.requestType)) {
      await request.cancelRequest();
    } else {
      return await this.quickRejectTask.perform(request);
    }

    this.notifierCounterManager.updateCounter();

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }

    this.openNextElement(request);
  });

  confirmRequestTask = dropTask(async request => {
    try {
      // In case the request is a transfer, mileage or expense report,
      // we only call `confirm` if it is at the last step of an approval workflow
      if (
        [REQUEST_TYPES.TRANSFER, REQUEST_TYPES.MILEAGE, REQUEST_TYPES.EXPENSE_REPORT].includes(
          request.requestType
        )
      ) {
        if (!request.lastStep) return;
        // If the user cannot create a transfer, we don't call `confirm`. The user can't pay.
        if (this.abilities.cannot('create transfer')) {
          return;
        }
      }
      let confirmResponse = await this.requestsManager.confirmRequestTask.perform(request);
      if (confirmResponse) {
        this.setProperties(parseConfirmResponse(confirmResponse, this.intl));
      } else {
        this.sentry.captureMessage(
          `No response for request id: ${request.id}, requestType: ${request.requestType}`
        );
      }
      return confirmResponse;
    } catch (error) {
      // If the backends sends a warning that is not handled by the app, parseConfirmResponse will throw an error
      // we want to know about
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  approveSupplierInvoiceTask = dropTask(async request => {
    let response = await this.supplierInvoicesManager.approveInvoiceRequestTask.perform(request);
    if (response) {
      this.updateSupplierInvoiceRecordTask.linked().perform(response).catch(ignoreCancelation);

      this.toastFlashMessages.toastSuccess(
        this.intl.t('requests.supplier-invoices.toasts.invoice-approved')
      );
    }
  });

  updateSupplierInvoiceRecordTask = dropTask(async supplierInvoiceRequestResponse => {
    let requestSupplierInvoice = this.store.peekRecord(
      'request-supplier-invoice',
      supplierInvoiceRequestResponse.request_supplier_invoice.id
    );
    let approvalWorkflowState = await requestSupplierInvoice?.get('approvalWorkflowState');

    if (
      approvalWorkflowState &&
      userIsCurrent(approvalWorkflowState, this.organizationManager.membership.id)
    ) {
      this.store.pushPayload(supplierInvoiceRequestResponse);
    } else {
      this.store.unloadRecord(requestSupplierInvoice);
    }
  });

  rejectSupplierInvoiceTask = dropTask(async (close, declinedNote, { request }) => {
    this.segment.track('supplier-invoices_reject-button_clicked', {
      origin: 'rejection_modal',
    });

    try {
      let response = await this.supplierInvoicesManager.rejectInvoiceTask.perform(
        request.get('supplierInvoice'),
        declinedNote
      );

      if (response?.supplierInvoice?.status === INVOICE_STATUSES.rejected) {
        this.toastFlashMessages.toastSuccess(
          this.intl.t('requests.supplier-invoices.toasts.invoice-rejected')
        );
        this.store.unloadRecord(request);
      }
    } catch (error) {
      this.errors.handleError(error);
    } finally {
      close();
    }
  });

  fetchDataTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by } = filterParams(params);

    let requestType = [
      ...(this.abilities.can('review transfer request')
        ? [REQUEST_TYPES.TRANSFER, REQUEST_TYPES.MULTI_TRANSFER]
        : []),
      ...(this.abilities.can('review card request')
        ? [REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD]
        : []),
      ...(this.abilities.can('review expense report request')
        ? [REQUEST_TYPES.EXPENSE_REPORT]
        : []),
      ...(this.abilities.can('review mileage request') ? [REQUEST_TYPES.MILEAGE] : []),
      ...(this.abilities.can('review direct debit collection request')
        ? [REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION]
        : []),
      ...(this.abilities.can('update supplier-invoice') ? [REQUEST_TYPES.SUPPLIER_INVOICE] : []),
    ];

    let requestModel = {
      includes: ['memberships'],
      organization_id: organization.id,
      status: ['pending'],
      page,
      per_page,
      sort_by,
      request_type: requestType,
    };

    if (this.featuresManager.isEnabled('approvalWorkflows')) {
      requestModel.approval_workflow_status = APPROVAL_WORKFLOW_STATUSES.AWAITS_MY_APPROVAL;
    }

    if (
      this.abilities.can('review transfer request') &&
      this.organizationManager.membership.role === ROLES.MANAGER
    ) {
      await this.organizationManager.membership.getSpendLimits();
    }

    let requests = await this.store.query('request', requestModel);
    this.meta = requests.meta;

    requests.forEach(request => {
      if (!request.bankAccount?.get('id')) {
        this.setAccount(request, organization.defaultAccount);
      }
    });

    return requests;
  });

  confirmSDDRequestRejectionTask = dropTask(async (closeRejectModal, multiRequest) => {
    await this.requestsManager.rejectMultiRequestDirectDebitCollectionTask.perform(multiRequest);

    closeRejectModal();
  });

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }

  destroyCurrentTransfer() {
    let transfer = this.currentTransfer;
    // Ember Data 4.7 does not like calling `destroy()` on a store
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.store.isDestroying || this.store.isDestroyed) return;
    if (transfer) {
      transfer.destroy();
    }
  }

  reloadData() {
    this.fetchDataTask.perform({}).catch(ignoreCancelation);
  }

  _isRequestInList(itemId) {
    return this.requests.some(({ id }) => id === itemId);
  }

  _displayToastRequestProcessed() {
    this.toastFlashMessages.toastInfo(
      this.intl.t('toasts.request_processed_refresh'),
      'transfer_create'
    );
  }

  get shouldRenderApprovalWorkflowDiscoveryCard() {
    return (
      variation('feature--boolean-approval-workflows-discovery') && !this.fetchDataTask.isRunning
    );
  }

  openNextElement(request) {
    this.highlight = '';
    let nextElementIndex = this.requests.findIndex(({ id }) => id === request.id) + 1;
    let nextElement = this.requests[nextElementIndex];

    if (nextElement && nextElement.requestType !== 'multi_transfer') {
      this.shouldPreserveHighlight = true;
      later(() => {
        this.highlight = nextElement.id;
      }, NEXT_ELEMENT_DELAY);
    }
  }
}
