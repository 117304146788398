import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class OauthRoute extends Route {
  @service refreshSessionManager;
  @service sessionManager;
  @service theme;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');
  }

  activate() {
    this.refreshSessionManager.startActivityTracking();
  }

  deactivate() {
    this.refreshSessionManager.cancelActivityTracking();
  }
}
