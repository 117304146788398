export default {
  "container": "qxG",
  "content": "qxr",
  "logos": "qxb",
  "logo": "qxy",
  "animation": "qxU",
  "title": "qxj title-2 mb-32",
  "avatar": "qxW",
  "dropdown": "qxu",
  "disabled": "qwS",
  "organization": "qwc",
  "actions": "qwq"
};
