export default {
  "container": "qYw",
  "security-method": "qYN",
  "icon": "qYv",
  "body": "qYo",
  "status": "qYn body-2",
  "device": "qYi",
  "device-icon": "qYA",
  "subtitle": "qYY body-2",
  "current": "qYh"
};
