export default {
  "signin": "qhi",
  "form": "qhA",
  "illustration": "qhY",
  "purple": "qhh",
  "mint": "qhf",
  "mustard": "qhK",
  "peach": "qhG",
  "form-wrapper": "qhr",
  "header-logo": "qhb",
  "links": "qhy",
  "no-account-label": "qhU",
  "spinner-container": "qhj",
  "main-image-container": "qhW",
  "main-image": "qhu"
};
