import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReceivableInvoicesNewPaymentRoute extends Route {
  @service intl;
  @service sentry;
  @service router;
  @service abilities;
  @service store;
  @service toastFlashMessages;
  @service mollie;

  beforeModel() {
    if (this.abilities.cannot('write paymentLink')) {
      this.router.transitionTo('receivable-invoices.new.index');
    }
  }

  async model() {
    await this.mollie.fetchConnectionTask.perform();
    let parentModel = this.modelFor('receivable-invoices.new');
    let { invoice } = parentModel;
    let paymentMethods = await this.store.query('payment-link-method', {
      'filter[amount]': invoice.totalAmount,
      'filter[currency]': invoice.currency,
      'filter[locale]': invoice.get('customer.locale') || invoice.locale,
    });
    if (!invoice.paymentLink) {
      invoice.paymentLink = {
        methods: paymentMethods.filter(({ enabled }) => enabled).map(({ method }) => method),
      };
    }
    return {
      ...parentModel,
      paymentMethods,
    };
  }

  @action
  error(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
    return this.router.transitionTo('receivable-invoices.new.index');
  }
}
