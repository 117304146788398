export const paymentLinksCreation = {
  steps: {
    'products-and-services': {
      componentName: 'payment-links/creation/products-and-services',
    },
    'payment-methods': {
      componentName: 'payment-links/creation/payment-methods',
    },
  },
  options: {
    enablePersistence: false,
  },
};
