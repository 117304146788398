export default {
  "wrapper": "qAi",
  "integrations": "qAA",
  "description": "qAY body-2",
  "api": "qAh flex flex-column",
  "api-container": "qAf flex large",
  "api-input": "qAK large",
  "api-btn": "qAG",
  "key-input": "qAr"
};
