/* eslint-disable @qonto/no-import-roles-constants */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import {
  MEMBERSHIP_BASE_EXCLUDES,
  MEMBERSHIP_UBO_EXCLUDES,
  ROLES,
} from 'qonto/constants/membership';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class MembershipEditController extends Controller {
  disclaimer = Disclaimer.Block;

  @service toastFlashMessages;
  @service intl;
  @service router;
  @service segment;

  @tracked showValidations = false;
  @tracked showEmptyPoiError = false;
  @tracked showEmptyKbisError = false;
  @tracked showEmptyBylawsError = false;

  @action
  setShowEmptyPoiError(showEmptyPoiError) {
    this.showEmptyPoiError = showEmptyPoiError;
  }

  @action
  setShowEmptyKbisError(showEmptyKbisError) {
    this.showEmptyKbisError = showEmptyKbisError;
  }

  @action
  setShowEmptyBylawsError(showEmptyBylawsError) {
    this.showEmptyBylawsError = showEmptyBylawsError;
  }

  get poiAttributesChanged() {
    let { membership, membershipChangeRequest } = this.model;
    if (!membership) return true;

    if (
      membershipChangeRequest.gender !== membership.gender ||
      membershipChangeRequest.firstName !== membership.firstName ||
      membershipChangeRequest.lastName !== membership.lastName ||
      membershipChangeRequest.nationality !== membership.nationality
    ) {
      return true;
    }

    return false;
  }

  get shouldValidateTaxPayerOutsideLegalCountry() {
    let legalCountry = this.model.organization.legalCountry;
    let taxCountry = this.model.membershipChangeRequest.taxCountry;

    return legalCountry === taxCountry;
  }

  get shouldDisplayUboConnectionToOrganization() {
    return this.model.organization.isItalianShareCapitalCompany;
  }

  get isUboConnectionToOrganizationFieldOptional() {
    return this.shouldDisplayUboConnectionToOrganization && !this.model.membershipChangeRequest.ubo;
  }

  @action
  onClose() {
    let { membership, membershipChangeRequest, updateProcess } = this.model;

    if (membershipChangeRequest.get('isNew')) {
      if (membership) {
        let address = membership.address;
        membershipChangeRequest.addressCity = address.city;
        membershipChangeRequest.addressCountry = address.country;
        membershipChangeRequest.addressFirstLine = address.firstLine;
        membershipChangeRequest.addressSecondLine = address.secondLine;
        membershipChangeRequest.addressThirdLine = address.thirdLine;
        membershipChangeRequest.addressZipCode = address.zipcode;
        membershipChangeRequest.birthdate = membership.birthdate;
        membershipChangeRequest.birthCity = membership.birthCity;
        membershipChangeRequest.birthCountry = membership.birthCountry;
        membershipChangeRequest.corporateOfficer = membership.corporateOfficer;
        membershipChangeRequest.firstName = membership.firstName;
        membershipChangeRequest.lastName = membership.lastName;
        membershipChangeRequest.gender = membership.gender;
        membershipChangeRequest.legalRepresentative =
          membership.legalRepresentative || membership.corporateOfficer;
        membershipChangeRequest.nationality = membership.nationality;
        membershipChangeRequest.nationalities = membership.nationalities;
        membershipChangeRequest.taxCountry = membership.taxResidenceCountry;
        membershipChangeRequest.taxIdentificationNumber =
          membership.taxResidenceTaxIdentificationNumber;
        membershipChangeRequest.ubo = membership.ubo;
        membershipChangeRequest.kycKybUpdateProcess = updateProcess;
      }
    } else {
      membershipChangeRequest.rollbackAttributes();
    }

    this.router.transitionTo('settings.company-profile.index');
  }

  scrollToFirstErrorInput() {
    schedule('afterRender', () => scrollIntoView('[data-has-error]', { block: 'center' }));
  }

  submitTask = dropTask(async () => {
    this.showValidations = true;

    let { membershipChangeRequest } = this.model;

    let shouldExcludeUboFields =
      !this.shouldDisplayUboConnectionToOrganization ||
      this.isUboConnectionToOrganizationFieldOptional;

    let excludes = [];

    if (!membershipChangeRequest.owner) {
      excludes = [...MEMBERSHIP_BASE_EXCLUDES];
    } else if (!this.shouldValidateTaxPayerOutsideLegalCountry) {
      excludes = ['taxPayerOutsideLegalCountry'];
    }

    if (shouldExcludeUboFields) {
      excludes = [...excludes, ...MEMBERSHIP_UBO_EXCLUDES];
    }

    let { validations } = await this.model.membershipChangeRequest.validate({ excludes });

    if (validations.isInvalid) {
      this.scrollToFirstErrorInput();
    }

    if (
      validations.isInvalid ||
      (this.model.membership?.role !== ROLES.OWNER &&
        this.poiAttributesChanged &&
        !this.model.pois.length) ||
      (membershipChangeRequest.legalRepresentative && !this.model.kbis.length)
    ) {
      if (this.poiAttributesChanged && !this.model.pois.length) {
        this.showEmptyPoiError = true;
      }
      if (membershipChangeRequest.legalRepresentative && !this.model.kbis.length) {
        this.showEmptyKbisError = true;
      }

      return;
    }

    membershipChangeRequest.set('didValidate', true);

    try {
      // create UpdateProcess, if it does not exist yet on the server
      if (this.model.updateProcess.get('isNew') || membershipChangeRequest.legalRepresentative) {
        if (this.model.kbis.length) {
          this.model.updateProcess.kycKybUpdateKbisFile = this.model.kbis.at(-1);
        }
        if (this.model.bylaws.length) {
          this.model.updateProcess.kycKybUpdateBylawFile = this.model.bylaws.at(-1);
        }

        await this.model.updateProcess.save();
      }

      if (this.model.pois.length) {
        membershipChangeRequest.kycKybUpdatePoiFile = this.model.pois.at(-1);
      }

      await membershipChangeRequest.save();

      this.segment.track('kycb_update_save_membership');

      this.showValidations = false;
      membershipChangeRequest.set('didValidate', false);

      this.router.transitionTo('settings.company-profile.index');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('organizations.profile.company-profile.success-toast.information-saved')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}
