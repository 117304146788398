import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PayLaterEarlyRepaymentFlow {
  currentRepaymentOption = null;
  repaymentOptions = null;
  financingId = null;
}

export default class PayLaterEarlyRepaymentFlowSetup extends FlowSetup {
  @service homePage;
  @service abilities;
  @service router;
  @service financing;

  constructor() {
    super(...arguments);

    this.dataContext = new PayLaterEarlyRepaymentFlow();

    let repaymentOptions = this.financing.repaymentOptions;

    this.dataContext.repaymentOptions = repaymentOptions;

    if (repaymentOptions?.length === 1) {
      this.dataContext.currentRepaymentOption = repaymentOptions[0].earlyRepaymentOption;
    }
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    let { financingId } = this.router.currentRoute.queryParams;

    if (this.abilities.cannot('access pay later in financing')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (!financingId || !this.dataContext.repaymentOptions) {
      return this.router.replaceWith('financing.pay-later');
    }

    this.dataContext.financingId = financingId;
  }

  get initialStepId() {
    if (this.dataContext.repaymentOptions.length < 2) {
      return 'overview';
    }

    return 'option-selection';
  }

  onComplete() {
    this.router.replaceWith('financing.pay-later');
  }

  onAbort() {
    this.router.replaceWith('financing.pay-later', {
      queryParams: { highlight: this.dataContext.financingId },
    });
  }
}
