import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PayLaterApplicationFlow {
  constructor({ eligibilityData, signature, origin }) {
    this.eligibilityData = eligibilityData;
    this.signature = signature;
    this.origin = origin;
  }
}

export default class PayLaterApplicationFlowSetup extends FlowSetup {
  @service router;
  @service segment;
  @service abilities;
  @service userManager;
  @service flowLinkManager;
  @service financing;

  constructor() {
    super(...arguments);

    this.dataContext = new PayLaterApplicationFlow({ signature: { url: '', status: '' } });
  }

  get initialStepId() {
    return 'signature';
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    if (!this.financing.hasEligibilityData || !this.router.currentRoute.queryParams.signatureUrl) {
      return this.router.replaceWith('financing.pay-later');
    }

    this.dataContext.signature.url = this.router.currentRoute.queryParams.signatureUrl;

    this.dataContext.eligibilityData = await this.financing.getLastPayLaterEligibility();

    this.dataContext.origin = this.router.currentRoute.queryParams.origin ?? 'navigation';
  }

  onComplete() {
    this.router.transitionTo('financing.pay-later');
  }

  onAbort() {
    this.router.transitionTo('financing.pay-later');
  }
}
