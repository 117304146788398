import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import HRIS_INTEGRATION_STATUS from 'qonto/constants/hris-integration';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

/**
 * This route is responsible to fetch the list of memberships and redirect to the first active member.
 * It is also responsible to handle the search input focus.
 * This route should be extended by all the tab routes.
 */

export default class TabRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service organizationManager;
  @service router;
  @service store;
  @service homePage;
  @service subscriptionManager;
  @service notifierManager;

  hrisEventTriggered = false;

  queryParams = {
    page: {
      defaultValue: 1,
      refreshModel: true,
    },
  };

  async model(params) {
    let hrisIntegration = [];

    if (this.abilities.can('view connect') && this.isToInviteRoute && !this.hrisEventTriggered) {
      hrisIntegration = await this.store.query('hris-integration', {});
    }

    // An integration is initiated if the user tries to perform the connection but closes the connection
    // modal before the confirm. Initiated connections should not be considered in this case.
    let filteredHrisIntegrations = hrisIntegration.filter(({ status }) => {
      return status !== HRIS_INTEGRATION_STATUS.INITIATED;
    });

    let hrisIsSyncing = filteredHrisIntegrations.every(
      ({ status }) =>
        status !== HRIS_INTEGRATION_STATUS.SYNCED && status !== HRIS_INTEGRATION_STATUS.INITIATED
    );

    if (!filteredHrisIntegrations.length || !hrisIsSyncing) {
      await this.performPaginatedMembersResponseTask(params);
    }

    return {
      paginatedMembersTask: this.modelFor('members').paginatedMembersResponseTask,
      hrisIntegration: filteredHrisIntegrations,
    };
  }

  async performPaginatedMembersResponseTask({ page, perPage }) {
    let { teamId } = this.paramsFor('members');
    await this.modelFor('members')
      .paginatedMembersResponseTask.perform(
        {
          page,
          perPage,
          teamId,
          paginatedFilters: this.paginatedFilters,
        },
        {
          suggested: this.isToInviteRoute,
        }
      )
      .catch(ignoreCancelation);
  }

  activate() {
    if (this.abilities.can('view connect') && this.rootRouteName === 'members.to-invite') {
      this.notifierManager.on('hris_integration.synced', this, 'handleEventHrisIntegration');
    }
  }

  deactivate() {
    if (this.abilities.can('view connect') && this.rootRouteName === 'members.to-invite') {
      this.notifierManager.off('hris_integration.synced', this, 'handleEventHrisIntegration');
    }
  }

  handleEventHrisIntegration(event) {
    if (event?.hris_integration.status === HRIS_INTEGRATION_STATUS.SYNCED) {
      this.hrisEventTriggered = true;
      this.refresh();
    }
  }

  // Override this parameter to apply specific filter to the memberships endpoint
  paginatedFilters = {};

  async beforeModel() {
    await this.cardsManager.fetchCounters(true);
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    let { query } = this.paramsFor('members');

    controller.setProperties({
      query,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({ page: 1, query: null, teamId: null });
    }
  }

  redirect(model, transition) {
    let memberRouteName = `${this.rootRouteName}.member`;
    let membershipIdParam = transition.to?.params?.membership_id;
    let membershipQueryParams =
      transition.to?.queryParams?.teamId || transition.to?.queryParams?.query;
    let firstActive = model.paginatedMembersTask.last?.value.find(
      ({ invitable, revoked, active }) => {
        switch (this.rootRouteName) {
          case 'members.active': {
            return !invitable && !revoked;
          }
          case 'members.inactive': {
            return !invitable && !active;
          }
          case 'members.to-invite': {
            return invitable;
          }
          default: {
            return !invitable;
          }
        }
      }
    );

    if (membershipIdParam) {
      let currentMember = model.paginatedMembersTask.last.value.find(
        ({ id }) => id === membershipIdParam
      );
      if (currentMember) {
        return this.transitionTo(
          memberRouteName,
          this.organizationManager.organization.slug,
          currentMember.id
        );
      } else if (membershipQueryParams) {
        if (firstActive) {
          return this.transitionTo(
            memberRouteName,
            this.organizationManager.organization.slug,
            firstActive.id
          );
        }
      }
    } else {
      if (firstActive) {
        return this.transitionTo(
          memberRouteName,
          this.organizationManager.organization.slug,
          firstActive.id
        );
      }
    }
  }

  get isToInviteRoute() {
    return this.rootRouteName === 'members.to-invite';
  }
}
