export default {
  "page-wrapper": "qDC",
  "container": "qDk",
  "sidebar": "qDJ",
  "search-input": "qDs",
  "organizations-list": "qDF",
  "content": "qDg",
  "no-result": "qDD",
  "lottie-illustration": "qDV",
  "accounting-header": "qDL",
  "logo": "qDT",
  "accounting-title": "qDz"
};
