import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';

export default class ReceivableInvoicesEditIndexController extends Controller {
  @service router;
  @service segment;
  @service abilities;
  @service receivableInvoicesBreadcrumb;

  @controller('receivable-invoices.edit') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.editInvoiceOrigin') editInvoiceOrigin;

  @reads('parentController.finalizeDraftTask') finalizeDraftTask;
  @reads('parentController.updateDraftTask') updateDraftTask;
  @reads('parentController.onCloseTask') onCloseTask;

  header = Header;

  get breadcrumb() {
    return this.receivableInvoicesBreadcrumb.breadcrumbWithStates;
  }

  get hasNextStep() {
    let hasPaymentLink = this.abilities.can('write paymentLink');
    let hasProductAndServices = variation('feature--boolean-ar-advanced-customization');

    return (!this.model.isBanklessOrganization && hasPaymentLink) || hasProductAndServices;
  }

  continueTask = dropTask(async () => {
    try {
      await this.model.invoice.validate({ skipSectionsValidation: true });

      if (variation('feature--boolean-ar-advanced-customization')) {
        await this.router.transitionTo('receivable-invoices.edit.products');
      } else {
        await this.router.transitionTo('receivable-invoices.edit.payment');
      }
    } catch (error) {
      this.parentController.handleError(error);
    }
  });

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'invoiceForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        ...this.editInvoiceOrigin,
      },
    });
  }
}
