import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ConnectHubHubApplicationSetupRoute extends Route {
  @service menu;
  @service segment;

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
    this.segment.track('hris_connection-status_shown', {
      status: 'success',
    });
  }

  deactivate() {
    this.menu.show();
  }

  model() {
    let { integration } = this.modelFor('settings.connect-hub.applications.hub-application');
    return {
      name: integration.name,
      logoUrl: integration.logo.url,
    };
  }
}
