import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  REDIRECT_TO_STEP_ON_ERROR,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ReceivableInvoicesEditController extends Controller {
  @service intl;
  @service router;
  @service modals;
  @service segment;
  @service toastFlashMessages;
  @service sentry;
  @service store;
  @service abilities;

  @service organizationManager;

  @reads('organizationManager.organization') organization;

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  get editInvoiceOrigin() {
    let { currentRoute } = this.router;
    let { parent } = currentRoute;
    return { origin: currentRoute.name, params: parent?.params?.id };
  }

  onCloseTask = dropTask(async () => {
    await this.modals.open(
      'receivable-invoices/cancel-invoice-form-modal',
      {
        title: this.intl.t('receivable-invoices.invoice-creation.exit-modal.draft.title'),
        description: this.intl.t(
          'receivable-invoices.invoice-creation.exit-modal.draft.description'
        ),
        closeWithoutSaving: this.intl.t(
          'receivable-invoices.invoice-creation.exit-modal.draft.cta.discard'
        ),
        saveAsDraft: this.intl.t(
          'receivable-invoices.invoice-creation.exit-modal.draft.cta.save-changes'
        ),
        closeWithoutSavingTask: this.modalConfirmTask,
        saveAsDraftTask: this.updateDraftFromModalTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  modalConfirmTask = dropTask(async close => {
    this.segment.track('invoice_creation_canceled');
    this.model.invoice.rollbackAttributes();
    this.model.invoice.items.forEach(item => item.rollbackAttributes());
    this.router.transitionTo('receivable-invoices.index');
    await close();
  });

  finalizeDraftTask = dropTask(async closeModal => {
    try {
      if (this.model.settings?.numberingMode === 'automatic') {
        this.model.invoice.number = null;
      }
      await this.model.invoice.finalizeDraft();
      await this.savePaymentLinkTask.perform(this.model.invoice).catch(this.handlePaymentLinkError);

      this.model.invoice.clearItemsWithNoId();

      let origin = this.router.currentRouteName;

      this.router.transitionTo('receivable-invoices.share', this.model.invoice.id, {
        queryParams: { origin },
      });

      this.toastFlashMessages.toastSuccess(this.intl.t('receivable-invoices.issue-modal.toaster'));
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
    }
  });

  updateDraftTask = dropTask(async () => {
    try {
      this.segment.track('invoice_draft_edited');
      if (this.model.settings?.numberingMode === 'automatic') {
        this.model.invoice.number = null;
      }
      await this.model.invoice.updateDraft();
      this.model.invoice.clearItemsWithNoId();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoices-list.draft-updated.toast-success')
      );
      this.router.transitionTo('receivable-invoices.index', {
        queryParams: {
          status: 'draft',
        },
      });
    } catch (error) {
      this.handleError(error);
    }
  });

  updateDraftFromModalTask = dropTask(async close => {
    this.segment.track('invoice-draft_exit_save-as-draft');
    try {
      this.segment.track('invoice_draft_edited');
      await this.model.invoice.updateDraft();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoices-list.draft-updated.toast-success')
      );
      this.router.transitionTo('receivable-invoices.index', {
        queryParams: {
          status: 'draft',
        },
      });
    } catch (error) {
      this.router.transitionTo('receivable-invoices.edit');
      this.handleError(error);
    } finally {
      await close();
    }
  });

  savePaymentLinkTask = dropTask(async invoice => {
    if (this.abilities.cannot('write paymentLink')) return;
    await this.store
      .createRecord('payment-link', {
        paymentInitiator: {
          resource_id: invoice.id,
          resource_type: 'Invoice',
          resource_number: invoice.number,
        },
        debitorName: invoice.customerSnapshot.name,
        amount: {
          value: invoice.totalAmount,
          currency: invoice.currency,
        },
        potentialPaymentMethods: invoice.paymentLink?.methods,
      })
      .save();
  });

  updateDraftBeforeOnboardingTask = dropTask(async () => {
    try {
      await this.model.invoice.updateDraft();
      this.model.invoice.clearItemsWithNoId();
    } catch (error) {
      this.handleError(error);
    }
  });

  validateTask = dropTask(async () => {
    try {
      await this.model.invoice.validate();
      return true;
    } catch (error) {
      this.handleError(error);
      return false;
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } else {
      let { redirectToIndexStep, redirectToProductStep } = this._parseErrors(error);

      if (redirectToIndexStep) {
        this.router.transitionTo('receivable-invoices.edit.index');
      } else if (redirectToProductStep) {
        this.router.transitionTo('receivable-invoices.edit.products');
      }

      later(this, this.scrollIntoError, macroCondition(isTesting()) ? 0 : 200);
    }
  }

  _parseErrors(error) {
    let redirectToIndexStep = false;
    let redirectToProductStep = false;

    error.errors.forEach(err => {
      redirectToIndexStep ||= REDIRECT_TO_STEP_ON_ERROR.indexStep.some(attribute =>
        err.source?.pointer?.startsWith(`/data/attributes/${attribute}`)
      );
      redirectToProductStep ||= REDIRECT_TO_STEP_ON_ERROR.productsStep.some(attribute =>
        err.source?.pointer?.startsWith(`/data/attributes/${attribute}`)
      );
    });

    return {
      redirectToIndexStep,
      redirectToProductStep,
    };
  }

  handlePaymentLinkError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
  }

  scrollIntoError() {
    scrollIntoView('[data-has-error]');
  }
}
