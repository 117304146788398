/* eslint-disable @qonto/no-import-roles-constants */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

/**
 * This route is responsible of the management of the membership details page.
 * It is responsible of fetching the membership and the related data.
 * This route should be extended by the other routes that need to fetch the membership data.
 */

export default class MembershipDetailsRoute extends Route {
  @service router;
  @service abilities;
  @service store;
  @service sentry;
  @service segment;
  @service organizationManager;
  @service connectManager;

  get origin() {
    let routeName = this.router.currentRoute.name;
    return /(inactive|active|to-invite)/.exec(routeName)[0];
  }

  getMembershipTask = restartableTask(async ({ membership_id, suggested }) => {
    this.segment.track('user-access_details_displayed', {
      origin: this.origin,
    });

    if (suggested) {
      let suggestedInvitation = await this.store.findRecord('suggested-invitation', membership_id);

      // Fetch and store the provider infos. Using the local storage to avoid repeated calls for
      // future highlighted members
      if (suggestedInvitation.provider) {
        let localStorageIntegration = safeLocalStorage.getItem(
          `integration_${suggestedInvitation.provider}`
        );
        if (!localStorageIntegration) {
          let integration = await this.connectManager.getIntegration(suggestedInvitation.provider);
          safeLocalStorage.setItem(
            `integration_${suggestedInvitation.provider}`,
            JSON.stringify({
              name: integration?.data?.name,
              logo: integration?.data?.logo.url,
            })
          );
        }
      }

      return suggestedInvitation;
    }
    let membership = await this.store.findRecord('membership', membership_id);
    let currentUserId = this.organizationManager.membership.id;
    let isManager = this.organizationManager.membership.role === ROLES.MANAGER;
    let managerCondition = isManager && currentUserId === membership_id;

    let adminCondition = this.abilities.can('view transfer-limit');

    let membershipPromises = [];
    if (!membership.hasFetchedSpendLimits && (managerCondition || adminCondition)) {
      membershipPromises.push(membership.getSpendLimits());
    }

    if (this.abilities.can('view allowed-bank-account') && membership.role === ROLES.MANAGER) {
      membershipPromises.push(membership.getAllowedBankAccounts());
    }

    if (this.abilities.can('read role') && membership.role === ROLES.MANAGER) {
      if (!membership.customPermissions) {
        membershipPromises.push(membership.getRole());
      }
    } else {
      membership.customPermissions = null;
    }

    this.handleSearchInputFocus();

    try {
      await Promise.all(membershipPromises);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return membership;
  });

  handleSearchInputFocus() {
    let membersModel = this.modelFor('members');
    let { query } = this.paramsFor('members');

    if (membersModel && query) {
      let { inputId } = membersModel;
      let queryString = `#${inputId}`;
      document.querySelector(queryString)?.focus();
    }
  }
}
