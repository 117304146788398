import { ForbiddenError } from '@ember-data/adapter/error';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { REQUEST_ORIGINS } from 'qonto/constants/requests';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class ExpenseReportFlowDataContext {
  @tracked requestExpenseReport = null;
  @tracked shouldShowReviewAndPayStep = null;
  @tracked hasBankAccountFeature = null;
  constructor(requestExpenseReport, shouldShowReviewAndPayStep, hasBankAccountFeature) {
    this.requestExpenseReport = requestExpenseReport;
    this.shouldShowReviewAndPayStep = shouldShowReviewAndPayStep;
    this.hasBankAccountFeature = hasBankAccountFeature;
  }
}

export default class RequestExpenseReportFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service subscriptionManager;
  @service router;
  @service store;
  @service segment;
  @service sentry;

  @tracked dataContext = null;

  constructor() {
    super(...arguments);

    let { organization } = this.organizationManager;

    let requestExpenseReport = this.store.createRecord('request-expense-report', {
      organization,
    });

    let shouldShowReviewAndPayStep = this.abilities.can('review expense report request');

    this.dataContext = new ExpenseReportFlowDataContext(
      requestExpenseReport,
      shouldShowReviewAndPayStep,
      this.hasBankAccountFeature
    );
  }

  get hasBankAccountFeature() {
    return this.subscriptionManager.hasFeature('bank_account');
  }

  async beforeFlow() {
    let origin = this.router.currentRoute.queryParams.origin;
    this.segment.track('request_reimbursement_started', {
      ...(origin && { origin }),
    });

    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    let cannotUseRequestExpenseReport = this.abilities.cannot('use expense reports request');
    let cannotCreateRequestExpenseReport = this.abilities.cannot('create expense report request');

    if (cannotUseRequestExpenseReport || cannotCreateRequestExpenseReport) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.hasBankAccountFeature) {
      let { membership } = this.organizationManager;

      try {
        await membership.getIban();
      } catch (error) {
        if (!(error instanceof ForbiddenError)) {
          this.sentry.captureException(error);
        }
      }

      Object.assign(this.dataContext.requestExpenseReport, {
        iban: membership?.iban,
      });
    }
  }

  onComplete({ hasBankAccountFeature }) {
    if (!hasBankAccountFeature) {
      this.router.transitionTo('reimbursements.completed');
      return;
    }

    let isReimbursementsOrigin = [
      REQUEST_ORIGINS.reimbursements,
      REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE,
    ].includes(this.router.currentRoute.queryParams.origin);

    if (isReimbursementsOrigin) {
      this.router.transitionTo('reimbursements.requests');
      return;
    }

    this.router.transitionTo('requests.pending');
  }

  onAbortTask = dropTask(async ({ hasBankAccountFeature }) => {
    if (!hasBankAccountFeature) {
      return await this.handleAbort.perform('reimbursements.completed');
    }

    let transitionToReimbursements =
      this.router.currentRoute.queryParams.origin === REQUEST_ORIGINS.reimbursements;

    let transitionToReimbursementsEmpty =
      this.router.currentRoute.queryParams.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    if (transitionToReimbursements) {
      return await this.handleAbort.perform('reimbursements.pending');
    }

    if (transitionToReimbursementsEmpty) {
      return await this.handleAbort.perform('reimbursements');
    }

    return await this.handleAbort.perform('requests.pending');
  });

  handleAbort = dropTask(async transitionToRoute => {
    if (this.dataContext.requestExpenseReport.attachmentCount > 0) {
      this.segment.track('reimbursement_request_inprogress_cancel_started');

      let result = await this.openAbortModalTask.perform();

      if (result === 'confirm') {
        this.segment.track('reimbursement_request_inprogress_cancelled');
        if (this.dataContext.requestExpenseReport.isNew) {
          this.dataContext.requestExpenseReport.destroyRecord();
        }
      } else {
        return false;
      }
    }

    this.router.transitionTo(transitionToRoute);
    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.reimbursement.close.title'),
      description: this.intl.t('requests.reimbursement.close.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
    });
  });

  beforeRestoreTask = dropTask(async ({ requestExpenseReport }) => {
    let { attachments, organization } = requestExpenseReport.relationships;

    await this.store.query('attachment', {
      organization_id: organization.id,
      filters: { ids: attachments.map(({ id }) => id) },
      per_page: 500,
    });
  });
}
