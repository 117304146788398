export default {
  "container": "qik",
  "wrapper": "qiJ",
  "grey-header-block": "qis",
  "connect-app-header": "qiF",
  "connect-app-header-link": "qig",
  "connect-app-header-content": "qiD",
  "connect-app-header-content-default": "qiV",
  "connect-app-header-content-left": "qiL",
  "button-block": "qiT",
  "connect-app-body": "qiz",
  "connect-app-body-left-content": "qia",
  "connect-app-body-sidebar": "qiH"
};
