import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { markHandledBySentry } from 'qonto/utils/handled-by-sentry';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SettingsApprovalWorkflowsController extends Controller {
  @service abilities;
  @service errors;
  @service intl;
  @service sentry;
  @service store;

  @tracked highlight = '';

  queryParams = ['highlight'];

  get workflows() {
    return this.fetchDataTask.last?.value || [];
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  get hasError() {
    return this.fetchDataTask.last?.error;
  }

  fetchDataTask = restartableTask(async () => {
    try {
      return await this.store.query('approval-workflow', {});
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      markHandledBySentry(error);
      throw error;
    }
  });

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  @action
  clearHighlight() {
    this.highlight = '';
  }

  @action
  reloadData() {
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }
}
