export default {
  "container": "qiw",
  "link-grey": "qiN",
  "slack-section-title": "qiv title-4 mb-8",
  "edit-channel-text": "qio body-2 mb-16",
  "rule-section-title": "qin title-4 mb-16",
  "rule-cards-container": "qii",
  "edit-slack-container": "qiA",
  "rule-card": "qiY",
  "add-rule-container": "qih",
  "add-rule-link-button": "qif",
  "add-rule-text": "qiK",
  "empty-state": "qiG",
  "empty-state-textblock": "qir",
  "empty-state-title": "qib title-2",
  "empty-state-description": "qiy body-2",
  "empty-state-illustration": "qiU",
  "rules": "qij"
};
