export default {
  "dropdown-icon": "qfu",
  "dropdown": "qKS",
  "download-button": "qKc",
  "connections-banner": "qKq",
  "recommendation-cards": "qKZ",
  "wrapper-with-cards": "qKR",
  "transactions-wrapper": "qKQ",
  "separator": "qKe",
  "with-transaction": "qKB",
  "annual-billing-banner": "qKE",
  "mt-132": "qKd",
  "transactions-sidebar": "qKI",
  "empty-state-wrapper": "qKt",
  "wrapper-with-modular": "qKM",
  "modular-table-container": "qKP",
  "modular-table-sidebar": "qKl",
  "modular-table-no-overlay": "qKX",
  "sidebar-overlay": "qKC",
  "overlay-visible": "qKk"
};
