import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PaymentLinksOnboardingFlowDataContext {
  @tracked userBankAccountId;
  @tracked userPhoneNumber;
  @tracked userWebsiteUrl;
  @tracked businessDescription;
  @tracked tosAccepted = false;
  @tracked isNotConnected = true;
  @tracked origin;
  @tracked draftInvoiceId;
  @tracked action;

  isMultipleStepsFlow = true;

  constructor({
    userBankAccountId,
    userPhoneNumber,
    userWebsiteUrl,
    businessDescription,
    origin,
    draftInvoiceId,
    action,
  } = {}) {
    this.userBankAccountId = userBankAccountId;
    this.userPhoneNumber = userPhoneNumber;
    this.userWebsiteUrl = userWebsiteUrl;
    this.businessDescription = businessDescription;
    this.origin = origin;
    this.draftInvoiceId = draftInvoiceId;
    this.action = action;
  }
}

export default class PaymentLinksOnboardingFlowSetup extends FlowSetup {
  @service abilities;
  @service router;
  @service homePage;
  @service organizationManager;
  @service mollie;
  @service flow;
  @service flowLinkManager;
  @service sentry;
  @service reactPopup;
  @service intl;
  @service segment;

  initialStepId = 'intro';

  constructor() {
    super(...arguments);
    let queryParams = this.router.currentRoute.queryParams;
    if (this.flow.dataContext.isAccountReceivableOnboardingFlow && !queryParams.origin) {
      this.router.replaceWith({
        queryParams: { ...queryParams, origin: 'account-receivable-onboarding' },
      });
    }
    this.dataContext = new PaymentLinksOnboardingFlowDataContext({
      userBankAccountId: this.initialBankAccountId,
      origin: queryParams.origin,
      draftInvoiceId: queryParams.draftInvoiceId,
      action: queryParams.action,
    });
  }

  get initialBankAccountId() {
    return this.organizationManager.organization.mainAccount.id;
  }

  async beforeFlow({ stepId }) {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('write paymentLink')) {
      this.transitionBack();
    }

    try {
      await this.mollie.fetchConnectionTask.perform();
    } catch (error) {
      if (stepId === 'intro') {
        // fallback to the default error handling
        throw error;
      } else {
        // let the feedback step handle the error
        this.sentry.captureException(error);
      }
    }
    if (!this.mollie.isNotConnected) {
      this.dataContext.isNotConnected = false;
      if (stepId !== 'feedback') {
        next(() => this.flow.next());
      }
    }
  }

  @action
  onComplete(dataContext) {
    let { action, draftInvoiceId } = dataContext;

    if (action === 'ctaClick') {
      if (dataContext.origin === 'account-receivable-onboarding') {
        return this.flowLinkManager.transitionTo({
          name: 'account-receivable-onboarding',
          stepId: 'summary',
          queryParams: { origin: null },
        });
      }
      if (!draftInvoiceId) {
        return this.router.transitionTo('receivable-invoices.new');
      }
    } else {
      if (dataContext.origin === 'account-receivable-onboarding') {
        return this.router.transitionTo('receivable-invoices.index');
      }
    }
    this.transitionBack();
  }

  onSuccessToast() {}

  shouldDisplayAbortModal(stepId, dataContext) {
    return (
      stepId === 'form' &&
      (dataContext.userPhoneNumber ||
        dataContext.userWebsiteUrl ||
        dataContext.tosAccepted ||
        dataContext.userBankAccountId !== this.initialBankAccountId)
    );
  }

  onAbortTask = dropTask(async (dataContext, { id: stepId }) => {
    if (this.shouldDisplayAbortModal(stepId, dataContext)) {
      let result = await this.openAbortModalTask.perform();
      if (result === 'confirm') {
        this.segment.track('payment-link_activation_canceled');
        this.transitionBack();
        return true;
      }
      return false;
    }

    this.transitionBack();
    return false;
  });

  openAbortModalTask = dropTask(
    async () =>
      await this.reactPopup.open(PopupDestructive, {
        title: this.intl.t('payment-links.onboarding.modal.title'),
        subtitle: this.intl.t('flow-in-flow.modal.abort.subtitle'),
        cancelText: this.intl.t('btn.cancel'),
        confirmText: this.intl.t('btn.leave'),
      })
  );

  transitionBack() {
    if (this.dataContext.origin && this.dataContext.origin !== 'account-receivable-onboarding') {
      if (this.dataContext.draftInvoiceId) {
        this.router.transitionTo(this.dataContext.origin, this.dataContext.draftInvoiceId);
      } else {
        this.router.transitionTo(this.dataContext.origin);
      }
    } else {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
