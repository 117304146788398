import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import {
  ALL_CATEGORIES,
  ALL_STAKEHOLDERS,
  COLLECTIONS,
  COLLECTIONS_EBICS_ACCESS,
} from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubCollectionsRoute extends Route {
  @service abilities;
  @service router;
  @service intl;
  @service connectManager;

  beforeModel(params) {
    let collectionSlug = params.to.params.collection_slug;
    let availableCollections = this.abilities.can('access ebics connect')
      ? COLLECTIONS_EBICS_ACCESS
      : COLLECTIONS;

    if (!availableCollections.includes(collectionSlug)) {
      this.router.transitionTo('settings.connect-hub.applications');
    }
  }

  async model(params) {
    let {
      categorySlug,
      collection_slug: collectionSlug,
      searchQuery,
      stakeholderSlug,
      page,
    } = params;

    if (!variation('feature--boolean-qontent-connect-hub')) {
      let allCategories = await this.connectManager.getAllCategories();
      let allStakeholders = await this.connectManager.getAllStakeholders();

      let category = allCategories.find(({ slug }) => slug === categorySlug);
      let stakeholder = allStakeholders.find(({ slug }) => slug === stakeholderSlug);

      let integrations = [];

      if (searchQuery) {
        integrations = await this.connectManager.searchIntegrationsTask
          .perform(searchQuery)
          .catch(ignoreCancelation);
      } else {
        integrations = await this.connectManager.getIntegrationsTask
          .perform({
            categoryId: category?.id,
            stakeholderId: stakeholder?.id,
            collectionSlug,
            page,
          })
          .catch(ignoreCancelation);
      }

      return {
        integrations,
        allCategories,
        allStakeholders,
        categoryId: category?.id,
        collectionSlug,
      };
    }

    let integrations = [];

    if (searchQuery) {
      integrations = await this.connectManager.searchIntegrationsTask
        .perform(searchQuery)
        .catch(ignoreCancelation);
    } else {
      integrations = await this.connectManager.getIntegrationsTask
        .perform({
          categorySlug,
          stakeholderSlug,
          page,
        })
        .catch(ignoreCancelation);
    }

    return {
      integrations,
      allCategories: ALL_CATEGORIES(this.intl),
      allStakeholders: ALL_STAKEHOLDERS(this.intl),
      categoryId: categorySlug,
      collectionSlug,
    };
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setData(model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.searchQuery = undefined;
      controller.categorySlug = undefined;
      controller.stakeholderSlug = undefined;
      controller.page = 1;
    }
  }
}
