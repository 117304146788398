import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  FREQUENCY_CUSTOM_UNITS,
} from 'qonto/constants/direct-debit-subscriptions';
import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  ES_FREELANCER_LEGAL_FORM,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class InvoiceSubscriptionsNewController extends Controller {
  disclaimerInline = Disclaimer.Inline;

  @service modals;
  @service segment;
  @service store;
  @service organizationManager;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service router;
  @service directDebitCollectionsManager;

  @reads('organizationManager.organization') organization;

  queryParams = ['customerId', 'origin'];

  @tracked customerId = null;
  @tracked origin = null;

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  get customers() {
    let clients = this.store
      .peekAll('client-hub')
      .filter(customer => {
        return (
          customer.belongsTo('organization').id() === this.organizationManager.organization.id &&
          !customer.isNew
        );
      })
      .sort(sortByKey('name'));

    return clients;
  }

  get isItalianOrganization() {
    return this.organization.legalCountry === 'IT';
  }

  get isGermanOrganization() {
    return this.organization.legalCountry === 'DE';
  }

  get isSpanishFreelancer() {
    return (
      this.organization.legalCountry === 'ES' &&
      this.organization.legalForm === ES_FREELANCER_LEGAL_FORM
    );
  }

  get showFiscalDetails() {
    let clientId = this.model.subscription?.customer?.get('id');
    let client = clientId ? this.store.peekRecord('client-hub', clientId) : null;
    let clientBillingCountry = client?.get('billingAddress.countryCode');

    return (
      this.isItalianOrganization || (this.isSpanishFreelancer && clientBillingCountry === 'ES')
    );
  }

  get emailDetailsErrorMessage() {
    // We're dealing with the Ember-Data Error class
    // eslint-disable-next-line ember/no-array-prototype-extensions
    let hasError = this.model.subscription.errors.any(({ attribute }) =>
      attribute.includes('emailTemplate/')
    );
    if (hasError) {
      return this.intl.t('recurring-invoices.new.form.validation.email-details.empty');
    }
  }

  get emailDetailsRecipient() {
    return (this.model.subscription.emailTemplate?.sendTo ?? []).join(', ');
  }

  get shouldDisplayFrEinvoicing() {
    let clientId = this.model.subscription?.customer?.get('id');
    let client = clientId ? this.store.peekRecord('client-hub', clientId) : null;

    return (
      (this.model.subscription?.isEinvoice &&
        variation('run-task--boolean-einvoicing-fr-rollback')) ||
      client?.eInvoicingAddress
    );
  }

  @action editEmailDetails() {
    this.modals.open(
      'invoice-subscriptions/form/email-details/modal',
      {
        subscription: this.model.subscription,
        settings: this.model.settings,
        isFullScreenModal: true,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  abortCreationTask = dropTask(async (close, { origin }) => {
    this.segment.track('recurring-invoices_creation_canceled', { origin });
    this.router.transitionTo(this.origin ? this.origin : 'invoice-subscriptions.index');
    await close();
  });

  @action showClosePopup(origin) {
    return this.modals.open('popup/confirmation', {
      title: this.intl.t('recurring-invoices.new.form.abort-popup.title'),
      description: this.intl.t('recurring-invoices.new.form.abort-popup.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.abortCreationTask,
      origin,
    });
  }

  onCustomerUpdate = dropTask(async customer => {
    let subscription = this.model.subscription;
    let { emailTemplate } = subscription;

    this.model.subscription.emailTemplate = {
      ...emailTemplate,
      sendTo: customer.email ? [customer.email] : ['-'],
      emailTitle:
        emailTemplate?.emailTitle ??
        this.intl.t('recurring-invoices.new.modal.email-details.subject-placeholder', {
          language: customer.locale,
        }),
      emailBody:
        emailTemplate?.emailBody ??
        this.intl.t('recurring-invoices.new.modal.email-details.message-placeholder', {
          language: customer.locale,
        }),
    };

    let { mandates, isError } = await this.model.loadMandatesTask.perform(customer.id);

    if (isError) {
      subscription.directDebitEnabled = false;
      subscription.directDebitCollectionMandate = null;
    } else if (mandates.length) {
      subscription.directDebitCollectionMandate = mandates[0];
    }
  });

  openConfirmSaveModal = dropTask(async () => {
    this.segment.track('recurring-invoice_creation_submitted');
    await this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('recurring-invoices.new.form.confirmation-popup.title'),
        description: this.intl.t('recurring-invoices.new.form.confirmation-popup.subtitle', {
          date: new Date(this.model.subscription.startDate).toLocaleDateString(
            this.intl.primaryLocale,
            {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              timeZone: 'UTC',
            }
          ),
        }),
        confirm: this.intl.t('recurring-invoices.new.form.confirmation-popup.create-cta'),
        cancel: this.intl.t('recurring-invoices.new.form.confirmation-popup.cancel-cta'),
        confirmTask: this.onConfirmSaveSubscriptionTask,
        shouldDisplayFrEinvoicing: this.shouldDisplayFrEinvoicing,
        isSubscription: true,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  onConfirmSaveSubscriptionTask = dropTask(async closeModal => {
    let sddActivation = this.model.sddActivation;
    let subscription = this.model.subscription;
    let {
      totalAmount,
      currency,
      dueDate,
      directDebitEnabled,
      directDebitCollectionMandate,
      frequency,
      customer,
    } = subscription;

    let isNewMandate = !(await directDebitCollectionMandate); // async relationship

    if (directDebitEnabled && isNewMandate && customer) {
      try {
        let { scheduleType, scheduleCustomUnit, interval } =
          this._mapInvoiceFrequencyToMandateSchedule(frequency);

        let client = await this.store.findRecord('client-hub', customer.id);

        if (!client) {
          throw new Error('Client not found');
        }

        let mandate = this.store.createRecord('direct-debit-collection-mandate', {
          client,
          paymentInfo: {
            firstPayment: {
              amount: {
                value: totalAmount,
                currency,
              },
              collectionDate: dueDate,
            },
            notifyClient: true,
            scheduleType,
            scheduleCustomUnit,
            interval,
          },
        });

        await mandate.save();

        subscription.directDebitCollectionMandate = mandate;
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        closeModal?.();
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
        return;
      }
    }

    try {
      await subscription.save();
    } catch (error) {
      subscription.directDebitCollectionMandate = null;
      this._handleSaveSubscriptionError(error);
      closeModal?.();
      return;
    }

    // Remove locally stored items because backend return copies of those items with new ids
    subscription.clearItemsWithNoId();

    this._trackSuccessSave(directDebitEnabled, sddActivation);

    this.toastFlashMessages.toastSuccess(this._getSuccessMessage(directDebitEnabled, isNewMandate));

    if (directDebitEnabled && isNewMandate) {
      this.router.transitionTo('invoice-subscriptions.sdd.payment-link', subscription.id);
    } else {
      this.router.transitionTo('invoice-subscriptions.index');
    }

    closeModal?.();
  });

  saveTask = dropTask(async closeModal => {
    try {
      await this.model.subscription.save();
      // Remove locally stored items because backend return copies of those items with new ids
      this.model.subscription.clearItemsWithNoId();
      this.toastFlashMessages.toastSuccess(this.intl.t('recurring-invoices.toasts.success.create'));
      this.segment.track('recurring-invoices_creation_confirmed', {
        success: true,
        autoDebit: this.model.subscription.directDebitEnabled,
      });

      if (this.model.subscription.directDebitEnabled) {
        return this.router.transitionTo(
          'invoice-subscriptions.sdd.setup',
          this.model.subscription.id
        );
      }
      this.router.transitionTo('invoice-subscriptions.index');
    } catch (error) {
      this.segment.track('recurring-invoices_creation_confirmed', {
        success: false,
      });
      this._handleSaveSubscriptionError(error);
    } finally {
      closeModal?.();
    }
  });

  _handleSaveSubscriptionError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
    later(this, this._scrollToError, macroCondition(isTesting()) ? 0 : 200);
  }

  _scrollToError() {
    scrollIntoView('[data-has-error]');
  }

  _mapInvoiceFrequencyToMandateSchedule = ({ recurrence, value }) => {
    let frequencyUnitMap = {
      weekly: FREQUENCY_CUSTOM_UNITS.WEEKS,
      monthly: FREQUENCY_CUSTOM_UNITS.MONTHS,
      yearly: FREQUENCY_CUSTOM_UNITS.YEARS,
      quarterly: FREQUENCY_CUSTOM_UNITS.MONTHS, // Special case for quarterly
    };

    if (value === 1) {
      return { scheduleType: recurrence };
    }

    let scheduleCustomUnit = frequencyUnitMap[recurrence];
    let interval = recurrence === 'quarterly' ? value * 3 : value;

    return {
      scheduleType: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
      scheduleCustomUnit,
      interval,
    };
  };

  _trackSuccessSave(directDebitEnabled, sddActivation) {
    this.segment.track('recurring-invoices_creation_confirmed', {
      autoDebit: directDebitEnabled,
      ...(directDebitEnabled &&
        sddActivation.hasGuarding && {
          guarding_percentage: sddActivation.guardingDepositPercentage,
        }),
    });
  }

  _getSuccessMessage(directDebitEnabled, isNewMandate) {
    return this.intl.t(
      directDebitEnabled
        ? isNewMandate
          ? 'recurring-invoices.toasts.success.create-with-sdd-new-mandate'
          : 'recurring-invoices.toasts.success.create-with-sdd'
        : 'recurring-invoices.toasts.success.create'
    );
  }
}
