export default {
  "title": "qYg title-3",
  "personal-profile-container": "qYD",
  "personal-profile-section": "qYV",
  "member-info-title": "qYL",
  "kyc-complete-profile": "qYT",
  "info-fields": "qYz",
  "field": "qYa",
  "address-fields": "qYH",
  "field-span": "qYO",
  "input": "qYm",
  "cancel": "qYp"
};
