import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import * as Sentry from '@sentry/ember';
import { dropTask, restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardAcquirerPaymentsRoute extends Route {
  @service abilities;
  @service homePage;
  @service modals;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  queryParams = {
    page: {
      defaultValue: 1,
      refreshModel: true,
    },
    per_page: {
      defaultValue: 25,
      refreshModel: true,
    },
    status: {
      defaultValue: '',
      refreshModel: true,
    },
    'show-modal': {
      defaultValue: null,
    },
  };

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    if (this.abilities.cannot('read card-acquirer-payment')) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  async model(params) {
    this.fetchPaymentsTask.perform(params).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    await this.fetchOnboardedTask.perform().catch(ignoreCancelation);

    return {
      fetchPaymentsTask: this.fetchPaymentsTask,
      isTtpOnboarded: this.fetchOnboardedTask.lastSuccessful?.value ?? false,
    };
  }

  afterModel() {
    if (this.router.currentRoute.queryParams['show-modal']) {
      this.modals.open('card/modals/card-acquirer-value-proposition-wrapper', {
        isFullScreenModal: true,
      });
    }
  }

  async search(query) {
    let { organization } = this.organizationManager;

    let result = await this.store.adapterFor('card-acquirer-payment').search(query, {
      'X-Qonto-Organization-ID': organization.id,
    });
    return result;
  }

  fetchPaymentsTask = dropTask(
    waitFor(async params => {
      let { page, per_page, status } = params;

      page = page ?? 1;
      per_page = per_page ?? 25;
      status = status ?? '';

      let query = {
        pagination: {
          page,
          per_page,
        },
        filters: {
          status: status ? [status] : null,
        },
      };

      let { payments, meta } = await this.search(query);

      return { payments, meta };
    })
  );

  fetchOnboardedTask = restartableTask(async () => {
    let isTtpOnboarded;

    try {
      isTtpOnboarded = await this.store
        .adapterFor('card-acquirer-onboarding')
        .fetchTapToPayOnboarded({ organizationId: this.organizationManager.organization.id });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
    return isTtpOnboarded;
  });
}
