import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ONBOARDING_ENTRY_POINT_PARAMS } from 'qonto/constants/onboarding';

export default class KycStartRoute extends Route {
  @service homePage;
  @service kycVerification;
  @service segment;
  @service organizationManager;
  @service store;
  @service userManager;
  @service router;
  @service networkManager;

  redirectionTimeout;
  isPoIRenewalFlow = false;

  // Checks if the user is coming from an onboarding entry point.
  // This helps determine if we should redirect them to the onboarding top-up flow.
  isFromOnboardingEntryPoint(transition) {
    if (!variation('feature--boolean-easy-top-up-desktop')) return false;
    return ONBOARDING_ENTRY_POINT_PARAMS.includes(transition?.to?.queryParams?.trackingEventOrigin);
  }

  getRedirectRoute(transition) {
    return transition.to.queryParams.redirectRoute || this.homePage.defaultRouteName;
  }

  // If the user is from an onboarding entry point, redirects to the top-up flow.
  // Otherwise, falls back to the standard redirect route.
  getRedirectRouteOnboarding(transition) {
    return this.isFromOnboardingEntryPoint(transition)
      ? 'onboarding.topup.amount'
      : this.getRedirectRoute(transition);
  }

  async beforeModel(transition) {
    let isFromOnboardingEntryPoint = this.isFromOnboardingEntryPoint(transition);
    let redirectRoute = this.getRedirectRouteOnboarding(transition);
    let trackingEventOrigin = transition.to.queryParams.trackingEventOrigin || null;

    this.segment.track('submit_kyc_information_start', {
      origin: trackingEventOrigin,
    });

    let { organization, membership } = this.organizationManager;

    let kyc = await this.store.queryRecord('identitiesKyc', {
      identityId: membership.identityId,
    });

    if (kyc.isRenewalPoiRequired) {
      this.isPoIRenewalFlow = true;
    }

    let shouldRedirectToVerification =
      !kyc.isRenewalPoiRequired &&
      !(kyc.isProviderFourthline && organization.hasFourthlineWorkflow);

    if (shouldRedirectToVerification) {
      window.location.href = this.kycVerification.getUrl(redirectRoute, {
        ...(isFromOnboardingEntryPoint && { trackingEventOrigin, kycCompleted: true }),
      });
      transition.abort();
    }
  }

  async putFourthlineProcess() {
    let identityId = this.organizationManager.membership.identityId;
    let response = await this.networkManager.request(
      `${apiBaseURL}/v1/identities/${identityId}/fourthline_process`,
      {
        method: 'PUT',
      }
    );

    return {
      providerToken: response.data.attributes.provider_token,
      redirectUrl: response.data.attributes.redirect_url,
      isQesRequired: response.data.attributes.is_qes_required,
    };
  }

  events(transition) {
    return {
      desktop: {
        onOnboardingStarted: () => {
          this.segment.track('verification_fourthline_verification_screen_loaded', {
            domain: window.location.hostname,
          });
        },
        onOnboardingCompleted: () => {
          let isFromOnboardingEntryPoint = this.isFromOnboardingEntryPoint(transition);
          let redirectRoute = this.getRedirectRouteOnboarding(transition);
          let trackingEventOrigin = transition.to.queryParams.trackingEventOrigin;

          this.segment.track('verification_kyc_succeeded', {
            domain: window.location.hostname,
            membership_id: this.organizationManager.membership.id,
            user_id: this.userManager.currentUser.id,
          });

          this.organizationManager.membership.setProperties({
            kycSubmitted: true,
          });

          let timeout = 4000;
          if (macroCondition(isTesting())) {
            timeout = 1;
          }
          this.redirectionTimeout = setTimeout(() => {
            if (isFromOnboardingEntryPoint) {
              return this.router.replaceWith(redirectRoute, this.organizationManager.organization, {
                queryParams: {
                  trackingEventOrigin,
                  kycCompleted: true,
                },
              });
            }

            // In some places of the codebase that we redirect to kyc.start we may have dynamic parts in the url.
            // To keep the direction to these areas, we allow passing a full url instead of an ember route.
            // This is also to be backwards compatible, as the routes passed a full url to `qonto-verification`.
            if (redirectRoute.startsWith('https://')) {
              window.location.href = redirectRoute;
              return;
            }

            return this.router.replaceWith(redirectRoute);
          }, timeout);
        },
        onOnboardingCompletedError: () => {
          this.segment.track('verification_fourthline_error_screen_loaded', {
            domain: window.location.hostname,
          });
        },
        onOnboardingRestartRequest: async webSdkElement => {
          this.segment.track('verification_fourthline_regenerate_link_clicked', {
            domain: window.location.hostname,
          });
          let { providerToken } = await this.putFourthlineProcess();
          webSdkElement.setToken(providerToken);
        },
      },
    };
  }

  async model(params, transition) {
    let legalCountry = this.organizationManager.organization.legalCountry;
    let locale = this.userManager.currentUser.locale;
    let { providerToken, redirectUrl, isQesRequired } = await this.putFourthlineProcess();
    let redirectRouteOnClose = this.getRedirectRoute(transition);
    let isPoIRenewalFlow = this.isPoIRenewalFlow;

    return {
      legalCountry,
      redirectUrl,
      redirectRouteOnClose,
      isQesRequired,
      embeddedWebSdkArgs: {
        locale,
        events: this.events(transition),
        token: providerToken,
      },
      isPoIRenewalFlow,
    };
  }

  @action
  error(error) {
    if (error.status === 422) {
      this.router.replaceWith('kyc.fail');
    } else {
      return true;
    }
  }

  activate() {
    this.segment.track('verification_kyc_started', {
      domain: window.location.hostname,
      membership_id: this.organizationManager.membership.id,
      user_id: this.userManager.currentUser.id,
      ...(this.isPoIRenewalFlow && { flow: 'PoIRenewal' }),
    });
  }

  deactivate() {
    // If the user has left the page on its own we do not want to redirect.
    if (this.redirectionTimeout) {
      clearTimeout(this.redirectionTimeout);
    }
  }
}
