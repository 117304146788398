export default {
  "page-container": "qmY",
  "form": "qmh",
  "close-button": "qmf btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmK",
  "preview-padding": "qmG",
  "title": "qmr",
  "header": "qmb",
  "without-tabs": "qmy",
  "form-section": "qmU",
  "form-footer": "qmj",
  "disclaimer-text": "qmW"
};
