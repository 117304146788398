import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SETTINGS_DEFAULT_VALUES } from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesNewProductsRoute extends BaseReceivableInvoicesRoute {
  @service router;
  @service store;
  @service organizationManager;

  beforeModel() {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      this.router.transitionTo('receivable-invoices.new.index');
    }
  }

  async model() {
    let parentModel = this.modelFor('receivable-invoices.new');
    let { invoice } = parentModel;

    let organization = this.organizationManager.organization;

    if (organization.legalCountry === 'DE') {
      await this.fetchIsFirstInvoice
        .perform()
        .catch(ignoreCancelation)
        .finally(() => {
          let isFirst = this.fetchIsFirstInvoice.lastSuccessful?.value;

          if (isFirst) {
            invoice.header = invoice.header || SETTINGS_DEFAULT_VALUES.invoiceHeader;
            invoice.footer = invoice.footer || SETTINGS_DEFAULT_VALUES.invoiceFooter;
            invoice.saveCustomMessages = true;
          }
        });
    }

    let customerCountry = invoice.customer?.get('billingAddress')?.countryCode;

    if (customerCountry !== organization.legalCountry) {
      let item = this.store.createRecord('receivable-invoice/item', defaultValues(this));

      item.vatRate = '0.00';

      if (organization.legalCountry === 'IT') {
        item.vatExemptionCode = 'N3.2';
      } else if (organization.legalCountry === 'FR') {
        item.vatExemptionCode = 'S262';
      } else if (organization.legalCountry === 'DE') {
        item.vatExemptionCode = 'S4.1B';
      }

      let section = this.store.createRecord('receivable-invoice/section', {
        isDefault: true,
        items: [item],
      });

      invoice.sections = [section];
    }

    return {
      ...parentModel,
      invoice,
      isBanklessOrganization: this.isBanklessOrganization,
    };
  }

  fetchIsFirstInvoice = restartableTask(async () => {
    let invoiceStats = await this.store.modelFor('receivable-invoice').getStats(this.store);

    let isFirstInvoice = invoiceStats.created.total <= 0;

    return isFirstInvoice;
  });
}
