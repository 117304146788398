import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class SubscriptionChangeDataContext {
  @tracked recurrence;
  @tracked upsellTrigger;
  @tracked isInitialTrialUpsell;
  @tracked event;
  @tracked refererPage;

  constructor({ upsellTrigger, isInitialTrialUpsell, event, recurrence, refererPage }) {
    this.recurrence = recurrence;
    this.upsellTrigger = upsellTrigger;
    this.isInitialTrialUpsell = isInitialTrialUpsell;
    this.event = event;
    this.refererPage = refererPage;
  }
}

export default class SubscriptionChangeFlowSetup extends FlowSetup {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service subscriptionManager;
  @service flow;
  @service store;
  @service abilities;

  dataContext = null;

  constructor() {
    super(...arguments);

    if (this.organizationManager.organization.status === ORGA_STATUS.DEACTIVATED) {
      this.modals.open('subscription/deactivated-account-blocker-modal', {
        isFullScreenModal: true,
        onCloseModal: () => this._goBackToMainPage(),
      });
      return;
    }

    let { queryParams } = this.router.currentRoute;
    let { dataContext } = this.flow;

    // When transitioning to this flow using pushFlow() (and not using router + queryParam) the params are passed down in the data context
    let event = queryParams.event || dataContext?.event;
    let isInitialTrialUpsell =
      queryParams.isInitialTrialUpsell ?? dataContext?.isInitialTrialUpsell;
    let selectedPricePlanRecurrence = queryParams.recurrence || dataContext?.recurrence;
    let upsellTrigger = queryParams.upsellTrigger || dataContext?.upsellTrigger;
    let changeType = '';
    let currentPlan = '';

    if (queryParams.changeType) {
      changeType = queryParams.changeType;
      this.flow.dataContext.changeType = changeType;
    }

    if (queryParams.currentPlan) {
      currentPlan = queryParams.currentPlan;
      this.flow.dataContext.currentPlan = currentPlan;
    }

    this.dataContext = new SubscriptionChangeDataContext({
      upsellTrigger,
      isInitialTrialUpsell,
      event,
      changeType,
      currentPlan,
      recurrence: selectedPricePlanRecurrence || SUBSCRIPTION_RECURRENCES.ANNUAL,
      refererPage: this.flow.refererPage,
    });
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');
    if (this.abilities.cannot('view subscription')) {
      this.segment.track('cannot_view_subscription_page_redirection', {
        refererPage: this.flow.refererPage,
      });
      return this.router.replaceWith('settings');
    }
  }

  beforeRestoreTask = dropTask(async ({ subscription }) => {
    if (subscription?.relationships?.product.id) {
      await this.store.findRecord('subscription-product', subscription.relationships.product.id);
    }
  });

  @action
  onComplete() {
    return this._goBackToMainPage();
  }

  @action
  onSuccessToast(dataContext) {
    if (dataContext.isItalyError) {
      this.toastFlashMessages.toastError(
        this.intl.t('subscription.change.error.italy-restrictions.toast')
      );
    } else {
      this.toastFlashMessages.toastSuccess(this.intl.t('subscription.change.success.toast'));
    }
  }

  onAbortTask = dropTask(async context => {
    let currentStepId = this.router.currentRoute?.params?.step_id;
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;

    if (currentStepId === 'benefits-list') {
      this.segment.track('change_plan_lose_benefits_closed');
    }

    if (currentStepId === 'confirm') {
      this.segment.track('checkout_rejected', {
        current_plan: price_plan,
        target_plan: context.subscription.get('pricePlan.code'),
        recurrence: context.recurrence,
      });
    }

    this.segment.track('plans_page_closed', {
      current_plan: price_plan,
    });
    await this._goBackToMainPage();
  });

  _goBackToMainPage() {
    if (this.flow.refererPage) {
      this.router.transitionTo(this.flow.refererPage);
    } else {
      let organizationSlug = this.organizationManager.organization.slug;
      this.router.transitionTo('settings.subscription.index', organizationSlug);
    }
  }
}
