import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import {
  GMI_STATUSES_CONNECTING_STATUSES,
  GMI_STATUSES_VERIFICATION_STATUSES,
} from 'qonto/constants/gmi-solution-instance';

const SUCCESS_EVENTS = ['SUPPLIER_ADDED', 'SUPPLIER_UPDATED'];

export default class GmiSetupRoute extends Route {
  @service segment;

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'connect');
    let queryParamStatus = transition.to.queryParams.status;
    let status = 'aborted';

    if (variation('feature--bolean-gmi-async-flow')) {
      status = GMI_STATUSES_CONNECTING_STATUSES.includes(queryParamStatus) ? 'success' : status;
    } else {
      status = SUCCESS_EVENTS.includes(queryParamStatus) ? 'success' : status;
    }

    if (GMI_STATUSES_VERIFICATION_STATUSES.includes(queryParamStatus)) {
      status = 'success';

      window.opener?.postMessage({
        queryParamStatus,
        status,
      });

      window.close();

      return;
    }

    window.opener?.postMessage({
      queryParamStatus,
      status,
    });
  }
}
