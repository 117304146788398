export default {
  "page": "qNe",
  "container": "qNB",
  "form-container": "qNE",
  "preview-container": "qNd",
  "pdf-preview-container": "qNI",
  "content": "qNt",
  "header": "qNM",
  "close-button": "qNP btn btn--icon-only btn--tertiary btn--large"
};
