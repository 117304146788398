export default {
  "team": "qxE",
  "no-members": "qxd",
  "teams-header": "qxI",
  "header-search": "qxt",
  "search-bar": "qxM",
  "invite": "qxP body-2",
  "invite-text": "qxl",
  "empty-illustration": "qxX",
  "empty-title": "qxC title-3",
  "empty-description": "qxk body-2",
  "pagination-footer": "qxJ",
  "filter-search": "qxs",
  "fullsize": "qxF",
  "tabs-container": "qxg",
  "tabs-separator": "qxD",
  "tooltip-wrapper": "qxV",
  "invite-cta": "qxL",
  "kyc-disclaimer": "qxT",
  "kyc-disclaimer-width": "qxz"
};
