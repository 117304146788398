import Controller from '@ember/controller';
import { service } from '@ember/service';

import { ThemeSelector } from 'qonto/react/components/theme-selector/theme-selector';

export default class SettingsAppearancePersonalController extends Controller {
  themeSelector = ThemeSelector;

  @service theme;
}
