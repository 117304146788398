import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ONBOARDING_ENTRY_POINT_PARAMS } from 'qonto/constants/onboarding';

export default class TopupAmountRoute extends Route {
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;
  @service intl;
  @service router;

  beforeModel(transition) {
    let { topupsStatus } = this.organizationManager.membership;

    if (topupsStatus === 'completed') {
      return this.router.transitionTo('onboarding.topup.completed');
    }

    if (this.isKycCompleted(transition)) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('on-boarding.top-up.add-amount.toasts.kyc-submitted')
      );

      // We remove the `kycCompleted` query parameter to avoid the toast being displayed again on page refresh.
      this.router.replaceWith({
        queryParams: { ...transition?.to.queryParams, kycCompleted: undefined },
      });
    }
  }

  afterModel(_, transition) {
    let queryParams = transition?.to.queryParams;
    let origin = ONBOARDING_ENTRY_POINT_PARAMS.includes(queryParams?.trackingEventOrigin)
      ? queryParams.trackingEventOrigin
      : 'other';

    this.segment.track('onboarding_top_up_landing', { origin });
  }

  isKycCompleted(transition) {
    let queryParams = transition?.to.queryParams;
    return queryParams && queryParams.kycCompleted;
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model);
    controller.set('_isKycCompleted', this.isKycCompleted(transition));
  }
}
