import Route from '@ember/routing/route';
import { service } from '@ember/service';

import {
  getRequestMultiTransferOptions,
  getRequestMultiTransferTransferOptions,
  reassignAttachmentsBetweenModels,
} from 'qonto/utils/transfers';

export default class MultiReviewConfirmRoute extends Route {
  @service store;

  model() {
    let multiTransfer = this.modelFor('transfers.multi.review');

    multiTransfer.addIdempotencyKey();

    return !multiTransfer.limitsData.isAboveLimits
      ? multiTransfer
      : this.convertMultiTransferToRequestMultiTransfer(multiTransfer);
  }

  convertMultiTransferToRequestMultiTransfer(multiTransfer) {
    let options = getRequestMultiTransferOptions(multiTransfer);

    let requestMultiTransfer = this.store.createRecord('request-multi-transfer', options);

    multiTransfer.selectedTransfers.forEach(bulkTransfer => {
      let requestMultiTransferTransfer = this.store.createRecord(
        'request-multi-transfer-transfer',
        getRequestMultiTransferTransferOptions(bulkTransfer)
      );

      requestMultiTransferTransfer.requestMultiTransfer = requestMultiTransfer;

      reassignAttachmentsBetweenModels(bulkTransfer, requestMultiTransferTransfer);
    });

    return requestMultiTransfer;
  }

  resetController(controller) {
    controller.submitTask.cancelAll();
    controller.saveTransferTask.cancelAll();
    controller.saveTransferLegacyTask.cancelAll();
    controller.saveRequestTask.cancelAll();
  }
}
