export default {
  "bank-accounts": "qnD l-app-content__wrapper",
  "header-wrapper": "qnV",
  "scrolled-top": "qnL",
  "header": "qnT l-app-content__page-header",
  "create-account-button": "qnz btn btn--primary",
  "main": "qna",
  "accounts": "qnH",
  "subtitle-container": "qnO",
  "subtitle": "qnm title-3 mb-16",
  "amount": "qnp body-2",
  "list--cashbeeSavings": "qnx"
};
