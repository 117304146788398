export default {
  "main-container": "qLn",
  "main": "qLi",
  "main-wrapper": "qLA",
  "close-button": "qLY",
  "active-block": "qLh",
  "title": "qLf",
  "list-options": "qLK",
  "card": "qLG",
  "card-container": "qLr",
  "card-image": "qLb",
  "card-tiles": "qLy"
};
