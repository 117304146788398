import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask, task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SPEND_LIMIT_DISCLAIMER_TYPES } from 'qonto/constants/transfers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export function isAboveLimits(error) {
  return error.errors?.find(err =>
    [
      SPEND_LIMIT_DISCLAIMER_TYPES.INSUFFICENT_PER_TRANSFER_LIMITS,
      SPEND_LIMIT_DISCLAIMER_TYPES.INSUFFICENT_MONTHLY_LIMITS,
    ].includes(err.detail)
  );
}

export default class TransfersMultiReviewConfirmController extends Controller {
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service router;
  @service modals;
  @service notifierManager;
  @service sensitiveActions;
  @service sentry;

  submitTask = dropTask(async closeModal => {
    let saveTask;
    if (this.model.limitsData.isAboveLimits) {
      saveTask = this.saveRequestTask;
    } else if (variation('feature--boolean-bulk-instant-out')) {
      saveTask = this.saveTransferTask;
    } else {
      saveTask = this.saveTransferLegacyTask;
    }

    await this.sensitiveActions.runTask.perform(saveTask, closeModal);
  });

  saveTransferLegacyTask = task(async closeModal => {
    this.model.addIdempotencyHeader();
    try {
      let mt = await this.model.submit();

      let bulkTransfers = mt.get('transfers');
      bulkTransfers.forEach(bt => {
        // destroy attachments,
        // indeed we send the attachments within the transfer
        // and get back attachmentsIds.
        let savedAttachements = bt.get('attachments').filter(item => item.isNew);
        savedAttachements.forEach(a => a.unloadRecord());
      });

      this.segment.track('app_multi_transfers_file_submitted');
      this.model.removeIdempotencyHeader();
      closeModal('submit');

      let message = this.intl.t('toasts.multi_transfer_created');
      this.toastFlashMessages.toastInfo(message, 'transfer_create');
      this.router.transitionTo('transfers.index');
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (error.status === 500) {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
        return;
      } else if (isAboveLimits(error)) {
        this.toastFlashMessages.toastError(
          this.intl.t('requests.transfers.modals.confirm_multi_transfer.errors.insufficient-funds')
        );
        return;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  saveTransferTask = task(async closeModal => {
    this.model.addIdempotencyHeader();
    try {
      await this.model.submit();

      closeModal('submit');

      await this.modals.open('transfer-multi/modals/transfer-status-modal', {
        hideClose: true,
        fullScreen: true,
        data: {
          transferId: this.model.id,
        },
      });
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  saveRequestTask = task(async closeModal => {
    try {
      let request = await this.model.save();

      let transfers = request.get('transfers');
      transfers
        .filter(transfer => transfer.isNew)
        .forEach(transfer => {
          // destroy attachments,
          // indeed we send the attachments within the transfer
          // and get back attachmentsIds.
          transfer
            .get('attachments')
            .filter(item => item.isNew)
            .forEach(a => a.unloadRecord());

          // Cleanup request-multi-transfer-transfer records
          transfer.unloadRecord();
        });

      closeModal('submit');

      this.displaySuccessRequestModalTask.perform(request).catch(ignoreCancelation);
    } catch (error) {
      // in case of MFA error, it is thrown so that it can get caught by {{perform-sensitive-task}}
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  displaySuccessRequestModalTask = task(async () => {
    let shouldRedirectToDetails = await this.modals.open('requests/success-modal', {
      hideClose: true,
    });

    // Can't be `if (shouldRedirectToDetails)` because by default click event is returned
    if (shouldRedirectToDetails === true) {
      return this.router.transitionTo('transfers.requests');
    }

    this.router.transitionTo('transfers.pending');
  });

  @action
  handleCloseConfirm(closeModal) {
    if (closeModal !== 'submit') {
      this.router.transitionTo('transfers.multi.review');
    }
  }
}
