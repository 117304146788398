import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { ErrorState } from '@repo/design-system-kit';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { dropTask, restartableTask } from 'ember-concurrency';

import { RIBA_STATUS } from 'qonto/constants/riba';
import { DEFAULT_SORT_ORDER, RIBA_SORT_BYS } from 'qonto/constants/sort';
import RibaPaymentModel from 'qonto/models/riba-payment';
import { Sidebar } from 'qonto/react/components/riba/sidebar';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class RibaProcessingController extends Controller {
  emptyStatesInform = EmptyStatesInform;
  ErrorState = ErrorState;

  @service intl;
  @service organizationManager;
  @service segment;
  @service store;
  @service toastFlashMessages;
  @service router;
  @service sentry;
  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = `${RIBA_SORT_BYS.EXECUTION_DATE}:${DEFAULT_SORT_ORDER}`;
  @tracked selectedPayments = [];
  @tracked sidebarPayment = null;

  Sidebar = Sidebar;

  queryParams = ['page', { perPage: 'per_page' }, { sortBy: 'sort_by' }];

  performRibaAction = dropTask(async (action, paymentId) => {
    try {
      let bankAccountId = this.organizationManager.organization.mainAccount.id;
      await RibaPaymentModel.updateBulk(this.store, action, bankAccountId, [paymentId]);

      if (action === 'reject') {
        this.toastFlashMessages.toastSuccess(this.intl.t('riba.toast.rejected.single'));
        this.router.transitionTo('riba.completed');
      }

      this.reloadData();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });

  rejectRibaTask = dropTask(async paymentId => {
    this.sidebarPayment = null;
    await this.performRibaAction.perform('reject', paymentId);
  });

  @action updateParam(param, value) {
    this[param] = value;
  }

  get payments() {
    return this.fetchDataTask.last?.value || [];
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  get isError() {
    return this.fetchDataTask.last?.isError;
  }

  get isEmptyState() {
    return this.payments.length === 0 && !this.isLoading && !this.isError;
  }

  get hasNoActiveAccounts() {
    return !this.organizationManager.organization.activeAccounts.length;
  }

  get isClosedAccountState() {
    return this.hasNoActiveAccounts && this.payments.length === 0;
  }

  @action closeSidebar() {
    this.sidebarPayment = null;
  }

  @action openSidebar(payment) {
    this.sidebarPayment = payment;
    this.segment.track('riba_processing_payment_details_opened');
  }

  fetchDataTask = restartableTask(async () => {
    return await this.store.query('riba-payment', {
      statuses: [RIBA_STATUS.SCHEDULED],
      page: this.page,
      per_page: this.perPage,
      sort_by: this.sortBy,
    });
  });

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.perPage = value;
    this.reloadData();
  }

  @action changePage(page) {
    this.page = page;
    this.reloadData();
  }

  @action
  changeSortBy(value) {
    this.sortBy = value;
    this.page = 1;
    this.reloadData();
  }

  @action
  reloadData() {
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }
}
