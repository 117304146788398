import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { IS_CONTRACT_ACKNOWLEDGMENT_VIEWED } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';
import { setSessionStorageItem } from 'qonto/utils/session-storage';

export default class ContractAcknowledgmentController extends Controller {
  @service sentry;
  @service router;
  @service intl;
  @service networkManager;
  @service toastFlashMessages;
  @service segment;

  queryParams = ['redirectRoute'];

  setContractAcknowledgmentViewed() {
    setSessionStorageItem(IS_CONTRACT_ACKNOWLEDGMENT_VIEWED, 'yes');
  }

  acknowledgeContractTask = dropTask(async () => {
    let { membership, redirectRoute } = this.model;
    let { id } = membership;
    let apiUrl = `${apiBaseURL}/v3/memberships/${id}/ack_contract`;

    try {
      await this.networkManager.request(apiUrl, { method: 'PUT' });

      this.segment.track('overview_contractackscreen_confirmed');

      this.setContractAcknowledgmentViewed();

      this.router.replaceWith(redirectRoute);

      this.toastFlashMessages.toastSuccess(
        this.intl.t('contract-acknowledgment.modal.success.toast')
      );
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });

  @action
  handleOnClose() {
    this.segment.track('overview_contractackscreen_closed');

    this.setContractAcknowledgmentViewed();

    return this.router.replaceWith(this.model.redirectRoute);
  }
}
