export default {
  "page-header": "qKW",
  "header-breadcrumb": "qKu",
  "tabs-container": "qGS",
  "bottom-border": "qGc",
  "tabs-separator": "qGq",
  "previous-request": "qGZ",
  "body-link": "qGR",
  "border-top": "qGQ"
};
