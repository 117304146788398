export default {
  "supplier-invoices-page": "qfd",
  "supplier-invoices-page-header": "qfI",
  "filters-container": "qft",
  "search-bar": "qfM",
  "filters": "qfP",
  "bulk-actions": "qfl",
  "item-count": "qfX",
  "higher-index": "qfC",
  "title-wrapper": "qfk",
  "title": "qfJ",
  "subtitle": "qfs",
  "table-container": "qfF",
  "file-dropzone": "qfg",
  "dropzone-visible": "qfD",
  "header-actions": "qfV",
  "header-wrapper": "qfL",
  "disclaimer-wrapper": "qfT"
};
