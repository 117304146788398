import { variation } from 'ember-launch-darkly';

export const accountReceivableOnboarding = {
  steps: {
    summary: {
      componentName: 'account-receivable-onboarding/summary',
      nextStepId: ({ nextStepId }) => nextStepId,
    },
    initial: {
      componentName: 'account-receivable-onboarding/initial',
      nextStepId: 'summary',
    },
    numbering: {
      componentName: 'account-receivable-onboarding/numbering',
      nextStepId: 'summary',
    },
    'company-details': {
      componentName: 'account-receivable-onboarding/company-details',
      nextStepId: ({ isFrenchOrganization }) => {
        let isAdvancedCustomizationEnabled = variation(
          'feature--boolean-ar-advanced-customization'
        );
        return isFrenchOrganization || isAdvancedCustomizationEnabled
          ? 'terms-and-conditions'
          : 'summary';
      },
    },
    'terms-and-conditions': {
      componentName: 'account-receivable-onboarding/terms-and-conditions',
      nextStepId: 'summary',
    },
    'it-company-details': {
      componentName: 'account-receivable-onboarding/it-company-details',
      nextStepId: () => {
        let isAdvancedCustomizationEnabled = variation(
          'feature--boolean-ar-advanced-customization'
        );
        return isAdvancedCustomizationEnabled ? 'terms-and-conditions' : 'summary';
      },
    },
    'customization-logo': {
      componentName: 'account-receivable-onboarding/customization-logo',
      nextStepId: 'customization-brand-color',
    },
    'customization-brand-color': {
      componentName: 'account-receivable-onboarding/customization-brand-color',
      nextStepId: ({ isGermanOrganization }) => {
        let isAdvancedCustomizationEnabled = variation(
          'feature--boolean-ar-advanced-customization'
        );
        return isGermanOrganization || isAdvancedCustomizationEnabled
          ? 'customization-custom-messages'
          : 'summary';
      },
    },
    'customization-custom-messages': {
      componentName: 'account-receivable-onboarding/customization-custom-messages',
      nextStepId: 'summary',
    },
    'success-page': {
      componentName: 'account-receivable-onboarding/success-page',
    },
    'accountant-access-upgrade': {
      componentName: 'account-receivable-onboarding/accountant-access-upgrade',
    },
  },
  options: {
    enablePersistence: false,
  },
};
