export default {
  "page": "qNV",
  "container": "qNL",
  "form-container": "qNT",
  "preview-container": "qNz",
  "pdf-preview-container": "qNa",
  "content": "qNH",
  "header": "qNO",
  "close-button": "qNm btn btn--icon-only btn--tertiary btn--large"
};
