import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class OverviewIndexRoute extends Route {
  @service router;
  @service organizationManager;
  @service subscriptionManager;
  @service sentry;
  @service periodicUpdate;

  model() {
    let {
      transactionsTask,
      updateProcess,
      bankAccounts,
      clientInvoicesOnboardingState,
      invoicesStatsTask,
    } = this.modelFor('overview');

    return {
      updateProcess,
      transactionsTask,
      bankAccounts,
      currentPricePlanCode: this.subscriptionManager.currentPricePlan?.code,
      clientInvoicesOnboardingState,
      invoicesStatsTask,
    };
  }

  afterModel({ updateProcess }) {
    this.periodicUpdate.setupPeriodicPopup({ updateProcess });
  }

  setupController(controller) {
    super.setupController(...arguments);

    let {
      organization: { isDeactivated },
    } = this.organizationManager;

    if (!isDeactivated) {
      controller.fetchUserActionsTask.perform().catch(ignoreCancelation);
    }
  }

  resetController(controller) {
    super.resetController(...arguments);

    controller.fetchUserActionsTask.cancelAll();
    controller.hasNotFoundError = false;
  }
}
