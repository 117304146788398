import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';

export default class ReceivableInvoicesNewPaymentController extends Controller {
  @service router;
  @service segment;
  @service flowLinkManager;
  @service receivableInvoicesBreadcrumb;

  @controller('receivable-invoices.new') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.saveTask') saveTask;
  @reads('parentController.saveAsDraftTask') saveAsDraftTask;
  @reads('parentController.onCloseTask') onCloseTask;
  @reads('parentController.saveAsDraftBeforeOnboardingTask') saveAsDraftBeforeOnboardingTask;

  header = Header;

  get breadcrumb() {
    return this.receivableInvoicesBreadcrumb.breadcrumbWithStates;
  }

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'invoiceForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        origin: 'receivable-invoices.new.payment',
      },
    });
  }

  activatePaymentLinksTask = dropTask(async () => {
    let draft = await this.saveAsDraftBeforeOnboardingTask.perform(this.model.invoice);
    let queryParams = {
      origin: draft ? 'receivable-invoices.edit.payment' : 'receivable-invoices.new',
      ...(draft && { draftInvoiceId: draft.id }),
    };

    this.segment.track('payment-link_activation_started', {
      origin: 'invoice',
    });

    if (draft) {
      await this.router.replaceWith('receivable-invoices.edit.payment', draft.id);
    }

    await this.flowLinkManager.transitionTo({
      name: 'payment-links-onboarding',
      stepId: 'intro',
      queryParams,
    });
  });
}
