import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import {
  INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING,
  INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER,
  PROVIDERS_MAP,
} from 'qonto/constants/insurance-hub';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InsuranceHubPoliciesController extends Controller {
  @service insuranceManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;

  @reads('organizationManager.organization.legalCountry') legalCountry;

  @tracked highlight;

  get insuranceContracts() {
    let contracts = this.model.fetchInsuranceContractsTask.lastSuccessful?.value ?? [];

    // Sort contracts avoiding mutating the original array
    let sortedContracts = [...contracts].sort((a, b) => {
      let statusA = INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER.indexOf(
        INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING[a.status]
      );
      let statusB = INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER.indexOf(
        INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING[b.status]
      );

      if (statusA !== statusB) {
        return statusA - statusB;
      }

      return a.name.localeCompare(b.name);
    });

    return sortedContracts;
  }

  get insuranceProvider() {
    let provider = this.fetchProviderTask.lastSuccessful?.value;

    if (!variation('feature--boolean-qontent-insurance-hub')) {
      return {
        name: provider?.data.name,
        logo: provider?.data.logo,
      };
    }

    return {
      name: provider?.name,
      logo: provider?.logo,
    };
  }

  get hasNoResult() {
    return (
      this.insuranceContracts.length === 0 &&
      !this.model.fetchInsuranceContractsTask.isRunning &&
      !this.model.fetchInsuranceContractsTask.last.isError
    );
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
    let { contractId, name, status, providerSlug, type } = this.store.peekRecord(
      'insurance-contract',
      itemId
    );

    this.fetchProviderTask
      .perform(providerSlug)
      .then(provider => {
        let insurer = variation('feature--boolean-qontent-insurance-hub')
          ? provider?.name
          : provider?.data?.name;

        this.segment.track('insurance-hub_my-policies_table-item_clicked', {
          policy_number: contractId,
          document_name: name,
          policy_status: status,
          insurer,
          country: this.legalCountry,
          partner: PROVIDERS_MAP[this.legalCountry].providerName,
          product: type,
        });
      })
      .catch(ignoreCancelation);
  }

  fetchProviderTask = dropTask(async slug => {
    try {
      return await this.insuranceManager.getInsuranceProvider(slug);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
