import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { CARD_FLOWS_TRACKING_ORIGINS } from 'qonto/constants/cards';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';

export default class CardNewAbstractFlowSetup {
  @service organizationManager;
  @service router;
  @service segment;
  @service reactPopup;
  @service intl;

  dataContext = null;

  get organization() {
    return this.organizationManager.organization;
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    let result = await this.openAbortModalTask.perform();
    if (result === 'confirm') {
      this.segment.track('cancel-modal_cancel_clicked', {
        origin: this.dataContext.flowTrackingOrigin,
        step: stepId,
      });
      this._exit();
    }
    return result === 'confirm';
  });

  _exit() {
    if (this.dataContext.flowTrackingOrigin === CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION) {
      return this.router.transitionTo('members.active');
    }
    let { id } = this.dataContext.card;
    if (id && !this.organizationManager.membership.kycSubmitted) {
      this.router.transitionTo('cards.index', { queryParams: { highlight: id } });
    } else {
      this.router.transitionTo('cards.index');
    }
  }

  openAbortModalTask = dropTask(async () => {
    return await this.reactPopup.open(PopupDestructive, {
      title: this.intl.t('cards.modals.cancel-creation.title'),
      subtitle: this.intl.t('cards.modals.cancel-creation.subtitle'),
      cancelText: this.intl.t('cards.modals.cancel-creation.back-cta'),
      confirmText: this.intl.t('cards.modals.cancel-creation.leave-cta'),
    });
  });
}
