export default {
  "container": "qoa",
  "form-container": "qoH",
  "title": "qoO",
  "title-border": "qom",
  "tabs-container": "qop",
  "preview-container": "qox",
  "email-preview-container": "qow",
  "close-button": "qoN btn btn--icon-only btn--tertiary btn--large"
};
