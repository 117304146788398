import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import {
  IRPF_ES_DEFAULT_RATE,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesEditRoute extends BaseReceivableInvoicesRoute {
  @service toastFlashMessages;
  @service intl;
  @service menu;
  @service sentry;
  @service router;
  @service mollie;
  @service abilities;
  @service receivableInvoicesBreadcrumb;
  @service accountReceivableDocumentsUploadManager;

  @reads('organizationManager.organization.activeSortedAccounts') activeAccounts;

  activate() {
    this.menu.hide();
  }

  async beforeModel() {
    let canReadInvoice = this.abilities.can('read receivableInvoice');
    let canUpdateDraft = this.abilities.can('update receivableInvoice');
    let { organization } = this.organizationManager;

    if (!(canUpdateDraft && canReadInvoice)) {
      this._redirectOnError();
    }

    // IT organizations need to have filled the e-invoice-activation step before creating or editing invoices
    if (organization.legalCountry === 'IT') {
      let eInvoiceActivation = await this.store.findRecord('e-invoice-activation', organization.id);

      if (!eInvoiceActivation.eInvoicingActivated) {
        this._redirectOnError();
      }
    }

    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('receivable-invoice').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }
  }

  async model({ id }, transition) {
    try {
      let { organization } = this.organizationManager;

      let settings;

      try {
        settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
      } catch (error) {
        if (error instanceof NotFoundError) {
          return;
        } else if (!(error instanceof NotFoundError)) {
          throw error;
        }
      }

      await this.fetchClientsTask.perform().catch(ignoreCancelation);
      let editedDraft = await this.store.findRecord('receivable-invoice', id);

      if (!settings.contactEmail) {
        settings.contactEmail = this.organizationManager.membership.email;
      }
      editedDraft.contactEmail = settings.contactEmail;

      await this.fetchOrganizationAvatarTask.perform(organization);

      editedDraft.welfareFund =
        editedDraft.welfareFund ?? this.store.createRecord('receivable-invoice/welfare-fund');
      editedDraft.payment =
        editedDraft.payment ?? this.store.createRecord('receivable-invoice/payment');

      editedDraft.organization.vatNumber = settings.vatNumber;

      if (organization.legalCountry === 'DE') {
        editedDraft.header = settings.invoiceHeader;
        editedDraft.footer = settings.invoiceFooter;
        editedDraft.organization.taxNumber = settings.taxNumber;
        editedDraft.organization.companyLeadership = settings.companyLeadership;
        editedDraft.organization.districtCourt = settings.districtCourt;
        editedDraft.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
      }

      if (variation('feature--boolean-ar-advanced-customization')) {
        editedDraft.termsAndConditions = null;
      }

      let { lastNumber, lastInvoice } = await this._getLastInvoiceDetails();
      editedDraft.lastNumber = lastNumber;

      await editedDraft.customer.catch(error => {
        let client = this.store.peekRecord('client-hub', editedDraft.belongsTo('customer').id());
        if (client) {
          editedDraft.customer = client;
        } else {
          editedDraft.customer = null;
        }

        this.handleError(error);
      });

      editedDraft.currency =
        editedDraft?.customer.get('currency') || editedDraft.currency || CURRENCIES.default;

      let customerBillingCountry = editedDraft?.customer?.get('billingAddress.countryCode');

      if (this.isSpanishFreelancer && customerBillingCountry === 'ES') {
        editedDraft.withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
          type: '',
          rate: editedDraft.withholdingTax?.rate || IRPF_ES_DEFAULT_RATE,
          reason: '',
        });
      } else if (organization.legalCountry === 'IT') {
        editedDraft.withholdingTax =
          editedDraft.withholdingTax ??
          this.store.createRecord('receivable-invoice/withholding-tax');
      }

      editedDraft.number =
        settings?.numberingMode === 'automatic'
          ? settings.invoiceNextNumberFormatted || settings.nextInvoiceNumber
          : editedDraft.number;

      let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(this.handleError);

      let isFirstFrenchEinvoice = false;
      if (organization.legalCountry === 'FR') {
        await this.fetchFirstEInvoice();

        let allEinvoices = this.fetchFirstEInvoiceTask?.lastSuccessful?.value || false;

        isFirstFrenchEinvoice = allEinvoices && allEinvoices.length === 0;
      }

      editedDraft.isEinvoice =
        (canCreateFrEinvoice && editedDraft?.customer.get('einvoicing')) ||
        Boolean(editedDraft?.customer.get('eInvoicingAddress')) ||
        false;

      if (editedDraft.belongsTo('depositInvoice').id()) {
        let depositInvoice = await editedDraft.belongsTo('depositInvoice').reload();
        editedDraft.depositPaymentDate = depositInvoice.paidAt;
        editedDraft.depositInvoiceNumber = depositInvoice.number;
        editedDraft.depositAmount = depositInvoice.amountDue;
      }

      let isFirstInvoice = Boolean(!lastInvoice);

      let invoice = {};

      // when coming from the settings/flows modal, there might be already some changed applied to the opened edited draft
      // instead of initializing fetching the edited one, we will peek in the store the the one with the latest changes
      if (
        (transition?.from?.name === 'invoicing-settings' || transition?.from?.name === 'flows') &&
        this.peekRecordedInvoices.length > 0
      ) {
        // only one newly created invoice can be expected inside the array
        invoice = this.peekRecordedInvoices[0];

        // the email needs to be the latest one
        invoice.contactEmail = settings.contactEmail;
      } else {
        invoice = editedDraft;
      }

      if (variation('feature--boolean-ar-advanced-customization') && isEmpty(invoice.sections)) {
        let item = this.store.createRecord('receivable-invoice/item', defaultValues(this));
        invoice.sections = [
          this.store.createRecord('receivable-invoice/section', {
            isDefault: true,
            items: [item],
          }),
        ];
      }

      if (this.isBanklessOrganization) {
        invoice.iban = editedDraft?.iban || lastInvoice?.iban || '';
        invoice.bic = editedDraft?.bic || lastInvoice?.bic || '';
      } else if (this.abilities.can('write paymentLink')) {
        let bankAccount = await this._getBankAccount(invoice.iban);
        invoice.bankAccount = bankAccount;
        invoice.iban = bankAccount?.iban;
        invoice.bic = bankAccount?.bic;
      } else {
        invoice.iban = '';
        invoice.bic = '';
      }

      return {
        invoice,
        settings,
        isFirstInvoice,
        canCreateFrEinvoice,
        isFirstFrenchEinvoice,
        isBanklessOrganization: this.isBanklessOrganization,
      };
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }
  }

  afterModel(model) {
    if (model.invoice.status !== 'draft') {
      this._redirectOnError();
    }
  }

  get peekRecordedInvoices() {
    // peek in the store the already created but not saved invoice without an id
    return this.store
      .peekAll('receivable-invoice')
      .filter(invoice => invoice.isNew && invoice.id === null);
  }

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('receivable-invoices.edit') &&
      !transition.targetName.includes('invoicing-settings') &&
      !transition.targetName.includes('flows')
    ) {
      let { invoice } = this.context;
      invoice.rollbackAttributes();
      if (!isEmpty(invoice.sections)) {
        invoice.sections.forEach(section => {
          section.rollbackAttributes();
          section.items.forEach(item => item.rollbackAttributes());
        });
      } else {
        invoice.items.forEach(item => item.rollbackAttributes());
      }
    }
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
        this.sentry.captureException(error);
      }
    }
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    this.receivableInvoicesBreadcrumb.loadBreadcrumb(this.#getBreadcrumb());
    this.receivableInvoicesBreadcrumb.registerCallback(this.controller.validateTask.perform);
  }

  resetController(_controller, isExiting) {
    if (isExiting) {
      this.receivableInvoicesBreadcrumb.reset();
      this.accountReceivableDocumentsUploadManager.resetFiles();
    }
  }

  deactivate() {
    this.menu.show();
  }

  async _getBankAccount(iban) {
    await this.mollie.fetchConnectionTask.perform();
    let mollieBankAccount = this.mollie.bankAccountId
      ? this.store.peekRecord('bank-account', this.mollie.bankAccountId)
      : null;
    let mainAccount = this.organizationManager.organization.mainAccount;
    let invoiceAccount = this.activeAccounts.find(account => account.iban === iban);

    if (!this.mollie.isConnected && invoiceAccount) {
      return invoiceAccount;
    }

    return mollieBankAccount || mainAccount;
  }

  async _getLastInvoiceDetails() {
    let termsAndConditions,
      lastNumber = '',
      lastInvoice;
    try {
      lastInvoice = await this.store.modelFor('receivable-invoice').last(this.store);
      termsAndConditions = variation('feature--boolean-ar-advanced-customization')
        ? null
        : lastInvoice.termsAndConditions;
      lastNumber = lastInvoice.number;
    } catch (error) {
      // we expect 404 if no last invoice
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
        this.sentry.captureException(error);
      }
    }
    return { lastNumber, termsAndConditions, lastInvoice };
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }

  #getBreadcrumb() {
    let items = [
      {
        route: 'receivable-invoices.edit.index',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.invoice-details'
        ),
      },
      {
        route: 'receivable-invoices.edit.products',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
      },
    ];

    if (this.abilities.can('write paymentLink') && !this.isBanklessOrganization) {
      items.push({
        route: 'receivable-invoices.edit.payment',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.payment-details'
        ),
      });
    }

    return items;
  }
}
