import { action } from '@ember/object';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { COMMON_CREDIT_PERIODS } from 'qonto/components/receivable-invoices/form/due-date-selector';
import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { IRPF_ES_DEFAULT_RATE } from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { differenceInCalendar } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class ReceivableInvoicesNewRoute extends BaseReceivableInvoicesRoute {
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service localeManager;
  @service menu;
  @service router;
  @service sentry;
  @service organizationManager;
  @service modals;
  @service mollie;
  @service receivableInvoicesBreadcrumb;
  @service accountReceivableDocumentsUploadManager;
  @service abilities;

  noAccountsModal = null;

  activate() {
    this.menu.hide();
  }

  async beforeModel({ to, from }) {
    let organization = this.organizationManager.organization;
    let membership = this.organizationManager.membership;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'receivable-invoices',
        },
      });
    }

    let { quoteId } = to.queryParams;
    let canReadInvoice = this.abilities.can('read receivableInvoice');

    if (canReadInvoice) {
      let { organization } = this.organizationManager;

      if (organization.legalCountry === 'IT') {
        let eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organization.id
        );
        if (!eInvoiceActivation.eInvoicingActivated) {
          this.flowLinkManager.transitionTo({
            name: 'invoice-onboarding',
            stepId: 'einvoice-activation',
            queryParams: { destination: 'new', quoteId, abortFallback: from.name },
          });
        }
      }
    }

    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('quote').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoice').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }

    if (
      this.isBanklessOrganization &&
      !variation('feature--boolean-ar-qf-manual-iban') &&
      variation('feature--boolean-aggregated-account-discoverability')
    ) {
      this.noAccountsModal = this.modals.open('receivable-invoices/no-accounts-modal', {
        onClose: () => window.history.back(),
      });
    }
  }

  async model(params, transition) {
    let { organization } = this.organizationManager;
    let { locale } = this.localeManager;

    let { quoteId, customerId, isDeposit, depositId } = params;

    let termsAndConditions,
      lastWithholdingTaxRate,
      lastNumber = '',
      lastCreditPeriod,
      lastInvoice,
      nextNumber,
      settings;

    await this.fetchClientsTask.perform().catch(ignoreCancelation).catch(this.handleError);

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
      nextNumber =
        settings?.numberingMode === 'automatic'
          ? settings.invoiceNextNumberFormatted || settings.nextInvoiceNumber
          : '';

      settings.contactEmail = settings.contactEmail || this.organizationManager.membership.email;
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }

      this._redirectOnError();
    }

    try {
      await this.fetchOrganizationAvatarTask.perform(organization);
    } catch (error) {
      this.handleError(error);
    }

    let item = this.store.createRecord('receivable-invoice/item', defaultValues(this));
    let section = this.store.createRecord('receivable-invoice/section', {
      isDefault: true,
      items: [item],
    });

    try {
      lastInvoice = await this.store.modelFor('receivable-invoice').last(this.store);

      lastWithholdingTaxRate = lastInvoice.withholdingTax?.rate;
      termsAndConditions = variation('feature--boolean-ar-advanced-customization')
        ? null
        : lastInvoice.termsAndConditions;
      lastNumber = lastInvoice.number;
      if (variation('feature--boolean-ar-advanced-customization')) {
        item.vatRate = lastInvoice.sections[0]?.items[0]?.vatRate;
      } else {
        item.vatRate = lastInvoice.items[0]?.vatRate;
      }
      lastCreditPeriod = lastInvoice
        ? differenceInCalendar(lastInvoice.dueDate, lastInvoice.issueDate, 'day')
        : 15;
    } catch (error) {
      // we expect 404 if no last invoice
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }
    }

    let isFirstInvoice = Boolean(!lastInvoice);

    let welfareFund = this.store.createRecord('receivable-invoice/welfare-fund');
    let withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax');
    let stampDutyAmount, discount;
    let currency = CURRENCIES.default;

    let quote,
      customer,
      customerBillingCountry,
      header,
      footer,
      performanceDate,
      deposit,
      sections = [],
      items = [];
    let isFromQuote = false;

    let isGermanTemplate = !quoteId && organization.legalCountry === 'DE';

    if (customerId) {
      customer = this.store.peekRecord('client-hub', customerId);
      customerBillingCountry = customer?.get('billingAddress.countryCode');
    }

    if (quoteId) {
      quote = await this.store.findRecord('quote', quoteId);
      if (quote) {
        customer = await this.store.peekRecord('client-hub', quote.customerSnapshot.id);
        customerBillingCountry = customer?.get('billingAddress.countryCode');

        lastWithholdingTaxRate = quote.withholdingTax?.rate;
        termsAndConditions = variation('feature--boolean-ar-advanced-customization')
          ? null
          : quote.termsAndConditions;
        currency = customer?.currency || quote.currency || CURRENCIES.default;

        if (!isDeposit) {
          discount = quote.discount;

          if (variation('feature--boolean-ar-advanced-customization')) {
            quote.sections.forEach(section => {
              let { title, description, isDefault } = section;

              let invoiceSection = this.store.createRecord('receivable-invoice/section', {
                title,
                description,
                isDefault,
              });

              section.items.forEach(item => {
                let {
                  title,
                  description,
                  quantity,
                  unitPrice,
                  vatRate,
                  vatExemptionCode,
                  discount,
                  unit,
                  productId,
                  links,
                  type,
                } = item;

                this.store.createRecord('receivable-invoice/item', {
                  title,
                  description,
                  quantity,
                  unitPrice,
                  vatRate,
                  vatExemptionCode,
                  discount,
                  unit,
                  productId,
                  links,
                  type,
                  section: invoiceSection,
                });
              });

              sections = [...sections, invoiceSection];
            });
          } else {
            quote.items.forEach(item => {
              let {
                title,
                description,
                quantity,
                unitPrice,
                vatRate,
                vatExemptionCode,
                discount,
                unit,
                productId,
                links,
                type,
              } = item;

              let invoiceItem = this.store.createRecord('receivable-invoice/item', {
                title,
                description,
                quantity,
                unitPrice,
                vatRate,
                vatExemptionCode,
                discount,
                unit,
                productId,
                links,
                type,
              });
              items = [...items, invoiceItem];
            });
          }
        }

        isFromQuote = true;
      }

      if (
        organization.legalCountry !== 'DE' &&
        !variation('feature--boolean-ar-advanced-customization')
      ) {
        header = quote.header;
      }
    } else if (isGermanTemplate) {
      performanceDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);

      header = settings.invoiceHeader;
      footer = settings.invoiceFooter;
    }

    if (variation('feature--boolean-ar-advanced-customization')) {
      header = settings.invoiceHeader;
      footer = settings.invoiceFooter;
    }

    if (depositId) {
      deposit = await this.store.findRecord('receivable-invoice', depositId);

      if (deposit && !quote) {
        customer = await this.store.peekRecord('client-hub', deposit.customerSnapshot.id);
        customerBillingCountry = customer?.get('billingAddress.countryCode');
        currency = customer?.currency || deposit.currency || CURRENCIES.default;
      }
    }

    if (organization.legalCountry === 'IT' && (lastInvoice || quote)) {
      welfareFund.rate = quote?.welfareFund?.rate || lastInvoice?.welfareFund?.rate;
      welfareFund.type = quote?.welfareFund?.type || lastInvoice?.welfareFund?.type;
      withholdingTax.rate = quote?.withholdingTax?.rate || lastInvoice?.withholdingTax?.rate;
      withholdingTax.reason = quote?.withholdingTax?.reason || lastInvoice?.withholdingTax?.reason;
      withholdingTax.type = quote?.withholdingTax?.type || lastInvoice?.withholdingTax?.type;
      stampDutyAmount = quote?.stampDutyAmount || lastInvoice?.stampDutyAmount;
      item.vatExemptionCode = variation('feature--boolean-ar-advanced-customization')
        ? quote?.sections[0]?.items[0]?.vatExemptionCode ||
          lastInvoice?.sections[0]?.items[0]?.vatExemptionCode
        : quote?.items[0]?.vatExemptionCode || lastInvoice?.items[0]?.vatExemptionCode;
    }

    if (this.isSpanishFreelancer && customerBillingCountry === 'ES') {
      withholdingTax.rate = lastWithholdingTaxRate || IRPF_ES_DEFAULT_RATE;
      withholdingTax.reason = '';
      withholdingTax.type = '';
    }

    let payment;
    if (organization.legalCountry === 'IT') {
      if (lastInvoice) {
        payment = this.store.createRecord('receivable-invoice/payment', {
          conditions: lastInvoice.payment?.conditions,
          method: lastInvoice.payment?.method,
        });
      } else {
        payment = this.store.createRecord('receivable-invoice/payment', {
          conditions: 'TP02',
          method: 'MP05',
        });
      }
    } else {
      payment = this.store.createRecord('receivable-invoice/payment');
    }

    if (settings) {
      organization.vatNumber = settings.vatNumber;
      organization.taxNumber = settings.taxNumber;
      organization.companyLeadership = settings.companyLeadership;
      organization.districtCourt = settings.districtCourt;
      organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(this.handleError);

    let isFirstFrenchEinvoice = false;
    if (organization.legalCountry === 'FR') {
      await this.fetchFirstEInvoice();

      let allEinvoices = this.fetchFirstEInvoiceTask?.lastSuccessful?.value || false;

      isFirstFrenchEinvoice = allEinvoices && allEinvoices.length === 0;
    }

    let invoice = {};

    // when coming from the settings modal or flows, there might be already one recorded invoice in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (
      (transition?.from?.name === 'invoicing-settings' || transition?.from?.name === 'flows') &&
      this.peekRecordedInvoices.length > 0
    ) {
      // only one newly created invoice can be expected inside the array
      invoice = this.peekRecordedInvoices[0];

      // the fields need to be the latest one
      invoice.contactEmail = settings.contactEmail;
      if (isGermanTemplate) {
        invoice.header = settings.invoiceHeader;
        invoice.footer = settings.invoiceFooter;
      }
    } else {
      let freshInvoice = {
        isEinvoice:
          (canCreateFrEinvoice && customer?.get('einvoicing')) ||
          Boolean(customer?.get('eInvoicingAddress')) ||
          false,
        header,
        footer,
        lastNumber,
        number: nextNumber,
        issueDate: dayjs().format(DATE_PICKER_FIELD_FORMAT),
        performanceDate,
        discount,
        dueDate: dayjs()
          .add(COMMON_CREDIT_PERIODS.includes(lastCreditPeriod) ? lastCreditPeriod : 15, 'day')
          .format(DATE_PICKER_FIELD_FORMAT),
        termsAndConditions,
        organization,
        customer,
        status: 'unpaid',
        contactEmail: settings.contactEmail,
        locale,
        welfareFund,
        withholdingTax,
        payment,
        stampDutyAmount,
        currency,
        quote,
        isDeposit,
        ...(variation('feature--boolean-ar-advanced-customization')
          ? {
              sections: (sections.length && sections) || [section],
            }
          : {
              items: (items.length && items) || [item],
            }),
      };

      if (this.isBanklessOrganization && lastInvoice) {
        freshInvoice.iban = lastInvoice?.iban || '';
        freshInvoice.bic = lastInvoice?.bic || '';
      } else if (
        !this.isBanklessOrganization &&
        (this.abilities.can('write paymentLink') ||
          variation('feature--boolean-ar-advanced-customization'))
      ) {
        freshInvoice.bankAccount = await this._getBankAccount();
        freshInvoice.iban = freshInvoice.bankAccount?.iban;
        freshInvoice.bic = freshInvoice.bankAccount?.bic;
      } else {
        freshInvoice.iban = '';
        freshInvoice.bic = '';
      }

      freshInvoice.beneficiaryName = this.organizationManager.organization.name;

      if (deposit) {
        freshInvoice.depositPaymentDate = deposit.paidAt;
        freshInvoice.depositInvoiceNumber = deposit.number;
        freshInvoice.depositAmount = deposit.amountDue;
        freshInvoice.depositInvoice = deposit;
      }

      invoice = this.store.createRecord('receivable-invoice', freshInvoice);
    }

    return {
      invoice,
      settings,
      isFirstInvoice,
      isFromQuote,
      canCreateFrEinvoice,
      isFirstFrenchEinvoice,
      lastWithholdingTaxRate,
      isBanklessOrganization: this.isBanklessOrganization,
    };
  }

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('receivable-invoices.new') &&
      !transition.targetName.includes('invoicing-settings') &&
      !transition.targetName.includes('flows')
    ) {
      let { invoice } = this.context;
      invoice.rollbackAttributes();
    }
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  get peekRecordedInvoices() {
    // peek in the store the already created but not saved invoice without an id
    return this.store
      .peekAll('receivable-invoice')
      .filter(invoice => invoice.isNew && invoice.id === null);
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }

  @action
  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    this.receivableInvoicesBreadcrumb.loadBreadcrumb(this.#getBreadcrumb());
    this.receivableInvoicesBreadcrumb.registerCallback(this.controller.validateTask.perform);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.quoteId = null;
      controller.customerId = null;
      controller.origin = null;
      controller.isDeposit = false;
      controller.depositId = null;

      this.receivableInvoicesBreadcrumb.reset();
      this.accountReceivableDocumentsUploadManager.resetFiles();
    }
  }

  deactivate() {
    this.menu.show();
    this.noAccountsModal?.close();
  }

  async _getBankAccount() {
    await this.mollie.fetchConnectionTask.perform();
    let mainAccount = this.organizationManager.organization.mainAccount;
    let mollieBankAccount = this.mollie.bankAccountId
      ? this.store.peekRecord('bank-account', this.mollie.bankAccountId)
      : null;

    if (this.mollie.isNotConnected) {
      return mainAccount;
    }

    return mollieBankAccount || mainAccount;
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }

  #getBreadcrumb() {
    let items = [
      {
        route: 'receivable-invoices.new.index',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.invoice-details'
        ),
      },
      {
        route: 'receivable-invoices.new.products',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
      },
    ];

    if (this.abilities.can('write paymentLink') && !this.isBanklessOrganization) {
      items.push({
        route: 'receivable-invoices.new.payment',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.payment-details'
        ),
      });
    }

    return items;
  }
}
