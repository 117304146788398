/* eslint-disable require-await */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class ReceiptsReminderRoute extends Route {
  @service organizationManager;
  @service abilities;
  @service router;
  @service store;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);
    if (this.abilities.cannot('view receipt-reminder')) {
      return this.router.replaceWith('settings');
    }
  }

  async model() {
    return this.store.findRecord('receipt-reminder', this.organizationManager.organization.id);
  }
}
