import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SupplierInvoicesMatchTransactionRoute extends Route {
  @service router;

  queryParams = {
    invoice_id: {
      refreshModel: false,
    },
    origin: {
      refeshModel: false,
    },
  };

  beforeModel(params) {
    if (!params.to.queryParams.invoice_id) {
      this.router.transitionTo('supplier-invoices.list');
    }
  }
}
