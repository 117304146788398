export default {
  "container": "qxv",
  "content": "qxo",
  "logos": "qxn",
  "logo": "qxi",
  "animation": "qxA",
  "title": "qxY title-2 mb-32",
  "avatar": "qxh",
  "actions": "qxf mb-32",
  "footer": "qxK body-2"
};
