import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class DirectDebitCollectionsIncreaseCollectionLimitRoute extends Route {
  @service homePage;
  @service menu;

  queryParams = {
    origin: {
      refreshModel: true,
    },
  };

  model(params) {
    let { origin } = params;

    return {
      origin,
    };
  }

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    this.menu.show();
  }
}
