import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { CATEGORIES, PRODUCT_ROUTE, PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
import { ProductCard } from 'qonto/react/components/insurance-hub/product-card';
import { ProductCardLoading } from 'qonto/react/components/insurance-hub/product-card-loading';
import { getCategoryTitle } from 'qonto/utils/insurance-hub/categories';

export default class InsuranceHubController extends Controller {
  lottiePlayer = LottiePlayer;
  productCard = ProductCard;
  productCardLoading = ProductCardLoading;

  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service zendeskLocalization;

  get organization() {
    return this.organizationManager.organization;
  }

  get legalCountry() {
    return this.organization.legalCountry;
  }

  get isFrench() {
    return this.organization.isFrench;
  }

  get partnerships() {
    return PROVIDERS_MAP[this.legalCountry].providerLogos;
  }

  get categorySlug() {
    return this.router.currentRoute.params.category_slug;
  }

  get title() {
    return getCategoryTitle(this.categorySlug, this.intl);
  }

  get displayCoachTile() {
    return this.isFrench;
  }

  get faqLink() {
    let key = PROVIDERS_MAP[this.legalCountry].faqKey;

    return this.zendeskLocalization.getLocalizedArticle(key);
  }

  get productRoute() {
    return PRODUCT_ROUTE;
  }

  get textKey() {
    let translationKey = this.categorySlug || this.legalCountry;

    switch (translationKey) {
      case CATEGORIES.BUSINESSES:
        return 'insurance-hub.marketplace.header.description.businesses';
      case CATEGORIES.EMPLOYEES:
        return 'insurance-hub.marketplace.header.description.employees';
      case CATEGORIES.OWNERS:
        return 'insurance-hub.marketplace.header.description.owners';
      case 'DE':
      case 'IT':
        return 'insurance-hub.marketplace.header.description.all-insurance.de-orgas';
      case 'FR':
      default:
        return 'insurance-hub.marketplace.header.description.all-insurance.fr-orgas';
    }
  }

  get linkKey() {
    switch (this.legalCountry) {
      case 'FR':
        return 'insurance-hub.marketplace.header.description.link-text.fr-orgas';
      case 'DE':
      default:
        return 'insurance-hub.marketplace.header.description.link-text.de-orgas';
    }
  }

  get url() {
    switch (this.legalCountry) {
      case 'FR':
        return 'https://quote.stello.eu/coach';
      case 'IT':
        return this.zendeskLocalization.getLocalizedArticle('insurance-hub-facile');
      case 'DE':
      default:
        return this.zendeskLocalization.getLocalizedArticle('insurance-hub');
    }
  }

  get showLegalNoticeSuperscript() {
    return this.legalCountry === 'IT' && variation('feature--boolean-insurance-hub-it');
  }

  @action
  handleCardClick(product) {
    this.segment.track('insurance-hub_card_clicked', {
      country: this.legalCountry,
      product,
      provider: PROVIDERS_MAP[this.legalCountry].providerName,
    });
  }
}
