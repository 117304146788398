import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ArFeaturesPromoPopup } from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvoicingSettingsController extends Controller {
  @service router;
  @service upsellManager;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service sentry;
  @service intl;

  @tracked popupFeature = '';

  arFeaturesPromoPopup = ArFeaturesPromoPopup;

  get isLoading() {
    return this.model.fetchInvoiceSubscriptionsStatsTask.isRunning;
  }

  get isManualNumberingDisabled() {
    let stats = this.model.fetchInvoiceSubscriptionsStatsTask?.lastSuccessful?.value.created;

    if (stats) {
      let { active, suspended, scheduled } = stats;
      return active + suspended + scheduled > 0;
    }

    return false;
  }

  get popupIsOpen() {
    return Boolean(this.popupFeature);
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  @action handleClose() {
    let { queryParams } = this.router.currentRoute || {};
    let origin = queryParams?.origin || 'receivable-invoices.index';
    if (queryParams?.params) {
      this.router.transitionTo(origin, queryParams?.params);
    } else {
      this.router.transitionTo(origin);
    }
  }

  @action showPopup(feature) {
    this.popupFeature = feature;
  }

  @action hidePopup() {
    this.popupFeature = '';
  }

  onCtaClickTask = dropTask(async () => {
    try {
      await this.upsellManager.upgradeRecommendationWithTransition('ar_advanced_customization');
      this.hidePopup();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
