import Controller from '@ember/controller';

import { Error } from 'qonto/react/financing/components/pay-later/cockpit/error';
import { PageHeader } from 'qonto/react/financing/components/pay-later/cockpit/page-header';

import styles from './styles.strict-module.css';

export default class FinancingPayLaterErrorController extends Controller {
  error = Error;
  PageHeader = PageHeader;
  styles = styles;
}
