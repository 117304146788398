import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class ReceivableInvoicesAddonRoute extends Route {
  @service menu;
  @service router;
  @service abilities;

  beforeModel() {
    if (
      this.abilities.cannot('view accountsReceivableAdvancedCustomization') ||
      this.abilities.can('use accountsReceivableAdvancedCustomization') ||
      !variation('feature--boolean-ar-advanced-customization')
    ) {
      this.router.replaceWith('receivable-invoices.index');
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
