import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class PaymentLinksRoute extends Route {
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    let isFFActive = variation('feature--boolean-mollie-payment-links');

    if (!isFFActive) {
      return this.homePage.replaceWithDefaultPage();
    }
  }
}
