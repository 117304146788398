import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class SignInRoute extends Route {
  @service sessionManager;
  @service modals;
  @service intl;
  @service bannerFlashMessages;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
    this.sessionManager.prohibitAuthentication();
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.arrivedOnPageAt = Date.now();
    if (controller.isUpdateSessionModalShown) {
      this.modals.open('popup/security/update-email/update-session');
      next(() => (controller.isUpdateSessionModalShown = false));
    }
  }

  activate() {
    this.triggerTopBanners();
  }

  deactivate() {
    this.bannerFlashMessages.clearTopBannerMessages();
  }

  triggerTopBanners() {
    let bannerData = variation('operational--unauthenticated-temporary-top-banner');

    if (bannerData?.message) {
      let message = bannerData.message[this.intl.locale];
      this.bannerFlashMessages.topBannerWarning(message);
    }
  }
}
