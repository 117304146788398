export default {
  "details": "qpt",
  "header": "qpM",
  "avatar": "qpP mr-16",
  "header-beneficiary": "qpl",
  "header-beneficiary-activity": "qpX",
  "header-beneficiary-details": "qpC",
  "header-beneficiary-details-recurring": "qpk",
  "infos": "qpJ",
  "infos-title": "qps",
  "infos-empty": "qpF",
  "infos-list": "qpg",
  "infos-list-item": "qpD",
  "infos-list-item-left": "qpV",
  "infos-list-item-right": "qpL",
  "infos-list-item-attachment": "qpT",
  "infos-transactions": "qpz",
  "actions": "qpa"
};
