import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SettingsPersonalNotificationsRoute extends Route {
  @service organizationManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  model() {
    return this.organizationManager.organizations;
  }
}
