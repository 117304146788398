import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class QuotesShareRoute extends Route {
  @service menu;
  @service store;
  @service organizationManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service networkManager;
  @service intl;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('quote').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }
  }

  async model({ id }) {
    let { organization } = this.organizationManager;

    this.fetchOrganizationAvatarTask.perform(organization).catch(error => {
      this.handleError(error);
    });

    let organizationId = organization.id;

    let quote, settings;

    try {
      quote = await this.store.findRecord('quote', id);
      settings = await this.store.findRecord('receivable-invoices-settings', organizationId);
      if (quote && !quote.isCanceledOrExpired) {
        await this.fetchAttachments.perform(quote, settings).catch(ignoreCancelation);

        await this.fetchEmailTemplate.perform(id);
      }
    } catch (error) {
      this.handleError(error);
    }

    return {
      quote,
      settings,
      emailTemplate: this.fetchEmailTemplate.lastSuccessful?.value.data.attributes,
      attachments: this.fetchAttachments.lastSuccessful?.value,
    };
  }

  redirect({ quote }) {
    if (!quote || quote.isCanceledOrExpired) {
      return this.router.replaceWith('quotes.index');
    }
  }

  fetchEmailTemplate = dropTask(async id => {
    try {
      return await this.store.adapterFor('quote').fetchEmailTemplate(id);
    } catch (error) {
      this.handleError(error);
      this.router.transitionTo('quotes.index');
    }
  });

  fetchAttachments = dropTask(async (quote, settings) => {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      return [];
    }

    try {
      let attachments = [];

      let attachmentFileId = quote.belongsTo('receivableInvoicingUpload').id();

      if (attachmentFileId) {
        let attachmentFile = await this.store.findRecord(
          'receivable-invoices-upload',
          attachmentFileId
        );
        attachments.push(attachmentFile);
      }

      let termsAndConditionsFileId = settings.belongsTo('termsConditionsUpload').id();

      if (termsAndConditionsFileId) {
        let termsAndConditionsFile = await this.store.findRecord(
          'receivable-invoices-upload',
          termsAndConditionsFileId
        );
        attachments.push(termsAndConditionsFile);
      }

      return attachments;
    } catch (error) {
      this.handleError(error);
    }
  });

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setDefaultParams();
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  }
}
