import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SupplierInvoicesIndexRoute extends Route {
  @service router;
  @service supplierInvoicesManager;

  async model() {
    let isFirstTimeUsage = await this.supplierInvoicesManager.fetchFirstTimeUsageTask.perform();

    return {
      isFirstTimeUsage,
    };
  }

  afterModel({ isFirstTimeUsage }) {
    if (isFirstTimeUsage) {
      return this.router.replaceWith('supplier-invoices.intro');
    } else {
      return this.router.replaceWith('supplier-invoices.list');
    }
  }
}
