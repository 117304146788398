import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class TransfersMultiNewController extends Controller {
  @service segment;
  @service router;

  @controller('transfers.multi') parentController;

  get disableSubmitButton() {
    return !this.model.file || this.parentController.uploadTask.isRunning;
  }

  @action
  handleGoBack() {
    this.segment.track('transfer-sepa-bulk_upload-page_cancel_clicked', {
      state: 'inactive',
    });
    this.router.transitionTo('transfers.landing');
  }
}
