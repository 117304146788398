export default {
  "multi-transfer": "qKL",
  "container": "qKT",
  "title": "qKz",
  "subtitle": "qKa",
  "form-wrapper": "qKH",
  "form": "qKO",
  "form-title": "qKm",
  "files-buttons": "qKp",
  "files-buttons-item": "qKx",
  "selected": "qKw",
  "ebics-button": "qKN",
  "divider": "qKv"
};
