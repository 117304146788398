export default {
  "body": "qpU",
  "members": "qpj",
  "members-results": "qpW",
  "members-list": "qpu",
  "members-title": "qxS caption-bold",
  "member": "qxc",
  "empty-illustration": "qxq",
  "empty-title": "qxZ title-3",
  "empty-description": "qxR body-2",
  "invitable-members": "qxQ",
  "details": "qxe",
  "pagination-footer": "qxB"
};
