import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { COMMON_QUOTE_EXPIRY_PERIODS } from 'qonto/components/receivable-invoices/form/due-date-selector';
import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { ES_FREELANCER_LEGAL_FORM, IRPF_ES_DEFAULT_RATE } from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import { differenceInCalendar } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class QuoteNewRoute extends Route {
  @service abilities;
  @service intl;
  @service toastFlashMessages;
  @service localeManager;
  @service menu;
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;
  @service receivableInvoicesBreadcrumb;
  @service accountReceivableDocumentsUploadManager;

  previousRoute;

  async beforeModel(transition) {
    this.previousRoute = transition?.from?.name;

    if (!this.abilities.can('create receivableInvoice')) {
      return this.router.replaceWith('receivable-invoices');
    }

    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('quote').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }

    let remainingQuotes;
    try {
      remainingQuotes = (await this.store.modelFor('quote').getStats(this.store)).quotasRemaining
        ?.total;
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }
      this._redirectOnError();
    }

    if (remainingQuotes === 0) {
      return this.router.replaceWith('quotes');
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model(params) {
    let { organization } = this.organizationManager;
    let { customerId } = params;

    let settings, lastInvoice, lastQuote, lastWithholdingTaxRate;

    let item = this.store.createRecord('receivable-invoice/item', defaultValues(this));

    let welfareFund = this.store.createRecord('receivable-invoice/welfare-fund');
    let withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax');
    let stampDutyAmount;

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);

      await this.fetchClientsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(this._catchErrorAndSendToSentry);
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }

      if (error instanceof NotFoundError) {
        return;
      }
      this._redirectOnError();
    }

    let { locale } = this.localeManager;

    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }
    let contactEmail = settings.contactEmail;
    let nextNumber =
      settings?.numberingMode === 'automatic' ? settings.quoteNextNumberFormatted : '';

    let header,
      footer = '';

    let currency = CURRENCIES.default;

    if (
      organization.legalCountry === 'DE' ||
      variation('feature--boolean-ar-advanced-customization')
    ) {
      header = settings.quoteHeader;
      footer = settings.quoteFooter;
    }

    lastInvoice = await this.store
      .modelFor('receivable-invoice')
      .last(this.store)
      .catch(this._catchErrorAndSendToSentry);
    lastQuote = await this.store
      .modelFor('quote')
      .last(this.store)
      .catch(this._catchErrorAndSendToSentry);

    let lastNumber, termsAndConditions;
    let daysToAdd = organization.legalCountry === 'FR' ? 90 : 30;
    let expiryDate = dayjs().add(daysToAdd, 'day').format(DATE_PICKER_FIELD_FORMAT);

    if (lastQuote) {
      lastNumber = lastQuote.number;
      termsAndConditions = variation('feature--boolean-ar-advanced-customization')
        ? null
        : lastQuote.termsAndConditions;
      let lastExpiryDate = differenceInCalendar(lastQuote.expiryDate, lastQuote.issueDate, 'day');
      expiryDate = COMMON_QUOTE_EXPIRY_PERIODS.includes(lastExpiryDate)
        ? dayjs().add(lastExpiryDate, 'day').format(DATE_PICKER_FIELD_FORMAT)
        : expiryDate;
    }

    let latestDocument = [lastInvoice, lastQuote].sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )?.[0];

    if (latestDocument) {
      if (variation('feature--boolean-ar-advanced-customization')) {
        item.vatRate = latestDocument.sections[0]?.items[0]?.vatRate;
        if (organization.legalCountry === 'IT') {
          item.vatExemptionCode = latestDocument.sections[0]?.items[0]?.vatExemptionCode;
        }
      } else {
        item.vatRate = latestDocument.items[0]?.vatRate;
        if (organization.legalCountry === 'IT') {
          item.vatExemptionCode = latestDocument.items[0]?.vatExemptionCode;
        }
      }
      if (organization.legalCountry === 'IT') {
        welfareFund.rate = latestDocument.welfareFund?.rate;
        welfareFund.type = latestDocument.welfareFund?.type;
        withholdingTax.rate = latestDocument.withholdingTax?.rate;
        withholdingTax.reason = latestDocument.withholdingTax?.reason;
        withholdingTax.type = latestDocument.withholdingTax?.type;
        stampDutyAmount = latestDocument.stampDutyAmount;
      }

      if (organization.isSpanish) {
        lastWithholdingTaxRate = latestDocument.withholdingTax?.rate;
      }
    }

    let isFirstQuote = Boolean(!lastQuote);

    if (settings) {
      organization.vatNumber = settings.vatNumber;
      organization.taxNumber = settings.taxNumber;
      organization.companyLeadership = settings.companyLeadership;
      organization.districtCourt = settings.districtCourt;
      organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    let customer;
    if (customerId) {
      customer = this.store.peekRecord('client-hub', customerId);

      let customerBillingCountry = customer?.get('billingAddress.countryCode');
      // adding withholding tax for spanish freelancers
      if (
        organization.isSpanish &&
        organization.legalForm === ES_FREELANCER_LEGAL_FORM &&
        customerBillingCountry === 'ES'
      ) {
        withholdingTax.rate = IRPF_ES_DEFAULT_RATE;
        withholdingTax.reason = '';
        withholdingTax.type = '';
      }
    }

    let quote = {};

    if (
      (this.previousRoute === 'invoicing-settings' || this.previousRoute === 'flows') &&
      this.peekRecordedQuotes.length > 0
    ) {
      // only one newly created quote can be expected inside the array
      quote = this.peekRecordedQuotes[0];

      // these fields need to be the latest one
      quote.contactEmail = settings.contactEmail;
      quote.number = nextNumber;

      if (organization.legalCountry === 'DE') {
        quote.header = settings.quoteHeader;
        quote.footer = settings.quoteFooter;
      }
    } else {
      let hasCustomMessages = Boolean(settings.quoteHeader) || Boolean(settings.quoteFooter);

      let freshQuote = {
        lastNumber,
        header,
        footer,
        number: nextNumber,
        issueDate: dayjs().format(DATE_PICKER_FIELD_FORMAT),
        expiryDate,
        termsAndConditions,
        organization,
        status: 'pending_approval',
        ...(variation('feature--boolean-ar-advanced-customization')
          ? {
              sections: [
                this.store.createRecord('receivable-invoice/section', {
                  isDefault: true,
                  items: [item],
                }),
              ],
            }
          : { items: [item] }),
        contactEmail,
        locale,
        welfareFund,
        withholdingTax,
        stampDutyAmount,
        currency,
        customer,
        saveCustomMessages: hasCustomMessages,
      };

      quote = this.store.createRecord('quote', freshQuote);
    }
    return {
      quote,
      settings,
      isFirstQuote,
      lastWithholdingTaxRate,
    };
  }

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  get peekRecordedQuotes() {
    // peek in the store the already created but not saved quote without an id
    return this.store.peekAll('quote').filter(quote => quote.isNew && quote.id === null);
  }

  _redirectOnError() {
    this.router.transitionTo('quotes');
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  _catchErrorAndSendToSentry(error) {
    if (
      ErrorInfo.for(error).shouldSendToSentry &&
      !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
    ) {
      this.sentry.captureException(error);
    }
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    this.receivableInvoicesBreadcrumb.loadBreadcrumb(this.#getBreadcrumb());
    this.receivableInvoicesBreadcrumb.registerCallback(controller.validateTask.perform);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      controller.customerId = null;
      controller.origin = null;
      this.receivableInvoicesBreadcrumb.reset();
      this.accountReceivableDocumentsUploadManager.resetFiles();
    }
  }

  #getBreadcrumb() {
    let items = [
      {
        route: 'quotes.new.index',
        title: this.intl.t(
          'receivable-invoices.quote-creation.navigation.breadcrumb.quote-details'
        ),
      },
      {
        route: 'quotes.new.products',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
      },
    ];

    return items;
  }

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('quotes.new') &&
      !transition.targetName.includes('invoicing-settings') &&
      !transition.targetName.includes('flows')
    ) {
      let { quote } = this.context;
      quote.rollbackAttributes();
    }
  }
}
