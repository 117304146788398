import Route from '@ember/routing/route';
import { service } from '@ember/service';

import window from 'ember-window-mock';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
import { IDENTITY_TYPES, LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/oauth';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { markHandledBySentry } from 'qonto/utils/handled-by-sentry';

const SENTRY_IGNORE_HTTP_STATUS = 410;

export default class OauthConsentRoute extends Route {
  @service networkManager;
  @service organizationManager;
  @service sentry;
  @service sessionManager;

  beforeModel(transition) {
    let { consent_challenge: consentChallenge } = transition.to.queryParams;

    if (!consentChallenge) {
      return this.replaceWith('/404');
    }

    this.sessionManager.requireAuthentication(transition, 'signin');
  }

  async model(params, transition) {
    await this.sessionManager.setupData();

    let { consent_challenge: consentChallenge } = params;
    let organizationId;

    try {
      let consent = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/consents?consent_challenge=${consentChallenge}`
      );

      if (consent.identity_type === IDENTITY_TYPES.MEMBERSHIP) {
        organizationId = safeLocalStorage.getItem(LOCAL_STORAGE_ORGANIZATION_KEY);
      }

      if (consent.client.skip_consent) {
        transition.abort();

        let { redirect_to: redirectTo } = await this.networkManager.request(
          `${apiBaseURL}/${oauthNamespace}/oauth/consents/accept`,
          {
            method: 'POST',
            data: JSON.stringify({
              consent_challenge: consentChallenge,
              consent: {
                grant_scope: consent.requested_scope.split(' '),
                session: {
                  ...(organizationId ? { organization_id: organizationId } : {}),
                },
              },
            }),
          }
        );

        window.location.replace(redirectTo);

        return;
      }

      return { consent, organizationId };
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== SENTRY_IGNORE_HTTP_STATUS) {
        this.sentry.captureException(error);
      }
      markHandledBySentry(error);
      throw error;
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    safeLocalStorage.removeItem(LOCAL_STORAGE_ORGANIZATION_KEY);
  }
}
