import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { LottiePlayer } from '@repo/design-system-kit';

import { codeToName } from 'qonto/models/subscription-product';
import { DetailedFees } from 'qonto/react/components/subscription/detailed-fees';
import { TotalFees } from 'qonto/react/components/subscription/total-fees';

export default class SettingsPricePlanDetailsController extends Controller {
  totalFees = TotalFees;

  @service intl;
  @service router;
  @service subscriptionManager;

  lottiePlayer = LottiePlayer;

  detailedFees = DetailedFees;

  get pricePlanOnFreeTrial() {
    let availableTrials = this.subscriptionManager.currentSubscription?.availableTrials;
    if (!availableTrials?.length) {
      return;
    }

    let trial = availableTrials.find(trial => trial.productType === 'core');
    return trial ? codeToName(this.intl, trial.productGroupCode) : null;
  }

  get currentMonthYear() {
    return dateToken({
      date: new Date(),
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
    });
  }

  @action
  reloadSubscription() {
    this.router.refresh('settings.price-plan.details');
  }
}
