import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class FinancingPartnersRoute extends Route {
  @service abilities;
  @service segment;
  @service router;
  @service menu;
  @service userManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');
  }

  redirect() {
    if (this.abilities.cannot('access financing')) {
      this.router.replaceWith('organizations.show');
    } else {
      this.segment.track('financing_hub_displayed');
    }
  }

  deactivate() {
    if (!this.menu.isVisible) {
      this.menu.show();
    }
  }
}
