import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import {
  GMI_SOLUTION_INSTANCE_STATUSES,
  GMI_STATUSES_CONNECTING_STATUSES,
} from 'qonto/constants/gmi-solution-instance';
import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubHubApplicationSetupController extends Controller {
  lottiePlayer = LottiePlayer;

  @service deviceManager;
  @service intl;
  @service toastFlashMessages;
  @service router;
  @service modals;
  @service organizationManager;
  @service segment;
  @service webviewManager;

  @tracked gmiSolutionInstanceStatus = null;

  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';
  popup = null;

  constructor() {
    super(...arguments);
    this.handleMessage = this.handleMessage.bind(this);
  }

  get integrationName() {
    return this.model.integration.name;
  }

  get wizardIsPopup() {
    return this.model.solutionInstance?.wizardIsPopup;
  }

  get isGeneratingWizard() {
    return (
      (this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.PENDING ||
        this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.CREATED) &&
      (!this.model.solutionInstance?.wizardUrl || this.wizardIsPopup)
    );
  }

  get isMarkedForEnablement() {
    return this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.MARKED_FOR_ENABLEMENT;
  }

  get isConnecting() {
    return GMI_STATUSES_CONNECTING_STATUSES.includes(this.gmiSolutionInstanceStatus);
  }

  get isEnabled() {
    return (
      !this.webviewManager.isWebview &&
      this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.ENABLED
    );
  }

  get isTransitionState() {
    return (
      this.isGeneratingWizard || this.isMarkedForEnablement || this.hasFailed || this.isConnecting
    );
  }

  get hasFailed() {
    return (
      !this.webviewManager.isWebview &&
      this.model.solutionInstance?.status === SOLUTION_INSTANCE_STATUS.FAILED
    );
  }

  get applicationIcon() {
    return this.model.integration.logo.url;
  }

  get transitionCopy() {
    if (this.isConnecting && variation('feature--bolean-gmi-async-flow')) {
      return {
        title: this.intl.t('qonto-hub.connect.setup-integration.success-screen-title', {
          name: this.integrationName,
        }),
        subtitle: this.intl.t('qonto-hub.connect.setup-integration.gmi-success-screen-description'),
      };
    }
    if (this.wizardIsPopup && !this.hasFailed) {
      return {
        title: this.intl.t('qonto-hub.connect.setup-flow.start-connection.title'),
        subtitle: this.intl.t('qonto-hub.connect.setup-flow.start-connection.subtitle', {
          integration_name: this.integrationName,
        }),
      };
    } else if (this.isGeneratingWizard) {
      return {
        title: this.intl.t('qonto-hub.connect.setup-flow.loader-title', {
          integration_name: this.integrationName,
        }),
        subtitle: this.intl.t('qonto-hub.connect.setup-flow.loader-subtitle'),
      };
    } else if (this.isMarkedForEnablement) {
      return {
        title: this.intl.t('qonto-hub.connect.setup-flow.activation-loader-title', {
          integration_name: this.integrationName,
        }),
        subtitle: this.intl.t('qonto-hub.connect.setup-flow.activation-loader-subtitle'),
      };
    } else if (this.hasFailed) {
      return {
        title: this.intl.t('qonto-hub.authentication.error.title'),
        subtitle: this.intl.t('qonto-hub.authentication.error.subtitle', {
          app: this.integrationName,
        }),
      };
    }
  }

  cancelSolutionTask = task(async () => {
    if (this.webviewManager.isWebview) {
      return this.webviewManager._callBridgeMethod('onConnectionCancelled');
    } else if (this.deviceManager.isMobile) {
      return window.location.replace(
        `/detectapp.html?appUrl=deeplinks?action=connect-hub.hub-application.setup.close&organization_slug=${this.organizationManager.organization.slug}`
      );
    }
    if (this.isEnabled) {
      this.closeSetup();
    } else {
      let result = await this.modals.open('popup/destructive', {
        title: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.title'),
        description: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.description'),
        cancel: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.cancel'),
        confirm: this.intl.t('qonto-hub.connect.setup-flow.cancel-modal.stop-setup'),
      });
      if (result === 'confirm') {
        try {
          await this.model.solutionInstance.destroyRecord();
          this.closeSetup();
        } catch {
          this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
        }
      }
    }
  });

  resetSolutionTask = task(async () => {
    let solutionId = this.model.solutionInstance.solutionId;
    await this.model.solutionInstance.destroyRecord();
    let solutionInstance = await this.model.initSolutionTask.perform(solutionId);
    this.model = { ...this.model, solutionInstance };
  });

  enableSolutionTask = dropTask(async () => {
    try {
      let application = this.model.integration;
      await this.model.solutionInstance.enable();
      this.segment.track('connect_app_activation_flow_success_cta_clicked', {
        name: application.name,
        integrationType: application.integration_type,
        solutionId: application.tray_solution_id,
        legal_country: this.organizationManager.organization.legalCountry,
      });
      if (this.webviewManager.isWebview) {
        return this.webviewManager._callBridgeMethod('onConnectionSuccess');
      } else if (this.deviceManager.isMobile) {
        return window.location.replace(
          `/detectapp.html?appUrl=deeplinks?action=connect-hub.hub-application.setup.success&organization_slug=${this.organizationManager.organization.slug}`
        );
      }
    } catch {
      if (this.webviewManager.isWebview) {
        return this.webviewManager._callBridgeMethod('onConnectionFailed');
      } else if (this.deviceManager.isMobile) {
        return window.location.replace(
          `/detectapp.html?appUrl=deeplinks?action=connect-hub.hub-application.setup.error&organization_slug=${this.organizationManager.organization.slug}`
        );
      } else {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    }
  });

  @action
  closeSetup() {
    if (this.fromTransition) {
      this.router.transitionTo(
        this.fromTransition.name,
        ...Object.values(this.fromTransition.params),
        {
          queryParams: this.fromTransition.queryParams,
        }
      );
    } else {
      this.router.transitionTo(this.detailsRouteName);
    }
  }

  @action
  openPopup() {
    window.addEventListener('message', this.handleMessage);
    this.popup = window.open(this.model.solutionInstance.wizardUrl, 'gmi', 'popup');
  }

  closePopup() {
    window.removeEventListener('message', this.handleMessage);
    this.popup?.close();
  }

  trigger2faToast(queryParamStatus) {
    switch (queryParamStatus) {
      case GMI_SOLUTION_INSTANCE_STATUSES.COMPLETED_VERIFICATION:
        this.toastFlashMessages.toastInfo(
          this.intl.t('settings.connections.toast.2fa-sent', {
            GMIintegrationName: this.integrationName || '',
          })
        );
        break;
      case GMI_SOLUTION_INSTANCE_STATUSES.RESTARTED_VERIFICATION:
        this.toastFlashMessages.toastInfo(
          this.intl.t('supplier-invoices.gmi.banner.2fa-challenge.text', {
            GMIintegrationName: this.integrationName || '',
            link: this.intl.t('supplier-invoices.gmi.banner.2fa-challenge.link-text'),
          })
        );
        break;
      default:
        break;
    }
  }

  async handleMessage(evt) {
    if (
      evt.origin === new URL(this.model.solutionInstance.wizardUrl).origin ||
      window.location.origin
    ) {
      let { queryParamStatus, status } = evt.data;

      this.segment.track('connect_gmi_setup_completed', {
        status: queryParamStatus,
      });

      if (
        GMI_STATUSES_CONNECTING_STATUSES.includes(queryParamStatus) &&
        variation('feature--bolean-gmi-async-flow')
      ) {
        this.closePopup();

        await this.enableSolutionTask.perform().catch(ignoreCancelation);
        this.gmiSolutionInstanceStatus = queryParamStatus;

        this.trigger2faToast(queryParamStatus);

        return;
      }

      if (status === 'aborted') {
        this.closePopup();
        this.model.solutionInstance.status = SOLUTION_INSTANCE_STATUS.FAILED;

        if (this.webviewManager.isWebview) {
          return this.webviewManager._callBridgeMethod('onConnectionFailed');
        } else if (this.deviceManager.isMobile) {
          return window.location.replace(
            `/detectapp.html?appUrl=deeplinks?action=connect-hub.hub-application.setup.error&organization_slug=${this.organizationManager.organization.slug}`
          );
        }
      }

      if (status === 'success') {
        this.closePopup();
        await this.enableSolutionTask.perform().catch(ignoreCancelation);
      }
    }
  }
}
