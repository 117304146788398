import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SettingsPersonalRoute extends Route {
  @service zendeskWidget;
  queryParams = {
    openZendeskWidget: {
      refreshModel: true,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  model({ openZendeskWidget }) {
    if (openZendeskWidget === 'true') {
      this.zendeskWidget.openAfterMounting();
    }
  }
}
