import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

export const ABORT_DESTINATIONS = {
  APPROVAL_WORKFLOW: 'approval_workflow',
  USER_ACCESS: 'user_access',
};

class ApprovalWorkflowsFlowDataContext {
  workflow;
  abortDestination;

  constructor({ id, abortDestination }) {
    this.id = id;
    this.abortDestination = abortDestination;
  }
}

export default class ApprovalWorkflowsFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service router;
  @service sentry;
  @service segment;
  @service store;
  @service toastFlashMessages;

  constructor() {
    super(...arguments);
    this.dataContext = new ApprovalWorkflowsFlowDataContext({
      id: this.router.currentRoute.queryParams.id,
      abortDestination: ABORT_DESTINATIONS.APPROVAL_WORKFLOW,
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
    if (
      this.abilities.cannot('create approval-workflow') &&
      this.abilities.cannot('try-before-buy approval-workflow')
    ) {
      this.homePage.visitDefaultPage();
    }

    try {
      let approvalWorkflow = await this.store.findRecord('approval-workflow', this.dataContext.id);
      approvalWorkflow.addErrors(approvalWorkflow.workflowErrors);
      this.dataContext.workflow = this.copyDefaultWorkflow(approvalWorkflow);

      let options = await this.store
        .adapterFor('approval-workflow')
        .getOptions(this.dataContext.workflow.requestType);

      this.dataContext.approverOptions = options.approvers;
      this.dataContext.payerOptions = options.payers;
    } catch (error) {
      if (ErrorInfo.for(error)) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      this.router.transitionTo('settings.approval-workflows');
    }
  }

  // Default workflow is not editable, we need to copy it to allow user to edit it
  copyDefaultWorkflow(workflow) {
    if (workflow.isDefault) {
      return this.store.createRecord('approval-workflow', {
        createdBy: workflow.createdBy,
        isDefault: false,
        isValid: workflow.isValid,
        organization: workflow.organization,
        requestType: workflow.requestType,
        updatedAt: workflow.updatedAt,
        updatedBy: workflow.updatedBy,
        workflowErrors: workflow.workflowErrors,
        rulesets: workflow.rulesets.map(ruleset => {
          return this.store.createRecord('approval-workflow/ruleset', {
            conditions: ruleset.conditions.map(condition => {
              return this.store.createRecord('approval-workflow/condition', {
                field: condition.field,
                operation: condition.operation,
                value: condition.value,
              });
            }),
            steps: ruleset.steps.map(step => {
              return this.store.createRecord('approval-workflow/step', {
                field: step.field,
                operation: step.operation,
                value: step.value,
                order: step.order,
              });
            }),
          });
        }),
      });
    } else {
      return workflow;
    }
  }

  onComplete() {
    this.router.transitionTo('settings.approval-workflows');
  }

  onAbortTask = dropTask(async ({ abortDestination }) => {
    this.segment.track('approval-workflow-edit_close_clicked');
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('approval-workflows.exit-modal.title'),
      description: this.intl.t('approval-workflows.exit-modal.description'),
      cancel: this.intl.t('approval-workflows.exit-modal.cancel'),
      confirm: this.intl.t('approval-workflows.exit-modal.cta'),
    });

    if (result === 'confirm') {
      let workflows = await this.store.findAll('approval-workflow');
      if (workflows.length > 0) {
        workflows.forEach(workflow =>
          workflow.rulesets.forEach(ruleset => {
            ruleset.conditions.forEach(condition => condition.unloadRecord());
            ruleset.steps.forEach(step => step.unloadRecord());
            ruleset.unloadRecord();
          })
        );
      }
      switch (abortDestination) {
        case ABORT_DESTINATIONS.USER_ACCESS:
          this.router.transitionTo('members');
          break;
        case ABORT_DESTINATIONS.APPROVAL_WORKFLOW:
        default:
          this.router.transitionTo('settings.approval-workflows');
          break;
      }
    } else {
      return false;
    }
  });
}
