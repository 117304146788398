export const addon = {
  steps: {
    addons: {
      componentName: 'addon-change/addons',
      nextStepId: ({ isRemoval }: { isRemoval: boolean }) => {
        return isRemoval ? 'benefits-list' : 'confirm';
      },
    },
    'benefits-list': {
      componentName: 'addon-change/benefits-list',
      nextStepId: 'confirm-remove',
    },
    'confirm-remove': {
      componentName: 'addon-change/confirm-remove',
      nextStepId: 'success',
    },
    confirm: {
      componentName: 'addon-change/confirm',
      nextStepId: ({ isItalyError }: { isItalyError: boolean }) => {
        return isItalyError ? 'success-italy' : 'success';
      },
    },
    success: {
      componentName: 'addon-change/success',
    },
    'success-italy': {
      componentName: 'addon-change/success-italy',
    },
  },
  options: {
    enablePersistence: true,
  },
};
