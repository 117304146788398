export const requestExpenseReport = {
  steps: {
    receipt: {
      componentName: 'request-expense-report/receipt',
      nextStepId: 'details',
    },
    details: {
      componentName: 'request-expense-report/details',
      nextStepId: ({ shouldShowReviewAndPayStep, hasBankAccountFeature }) => {
        if (hasBankAccountFeature) {
          return shouldShowReviewAndPayStep ? 'review-and-pay' : 'success';
        }

        return 'success-light';
      },
    },
    'review-and-pay': {
      componentName: 'request-expense-report/review-and-pay',
    },
    success: {
      componentName: 'request-expense-report/success',
    },
    'success-light': {
      componentName: 'request-expense-report/success-light',
    },
  },
  options: {
    enablePersistence: true,
  },
};
