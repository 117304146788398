import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class OrganizationProfileRoute extends Route {
  @service abilities;
  @service activityType;
  @service annualTurnover;
  @service organizationManager;
  @service router;
  @service store;

  queryParams = {
    per_page: {
      defaultValue: 100,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
  }

  async model(params) {
    let { organization, membership } = this.organizationManager;

    await this.store.findRecord('organization', organization.id, {
      include: ['organization_kyb_details'],
    });

    let representativeChangeRequests = [];
    let ownerChangeRequest;

    let updateProcess;
    if (
      organization.belongsTo('ongoingKycKybUpdateProcess').id() &&
      this.abilities.can('read kyc kyb update process organization')
    ) {
      updateProcess = await organization.belongsTo('ongoingKycKybUpdateProcess').load();
    }

    if (!updateProcess || updateProcess.status === 'accepted') {
      updateProcess = this.store.createRecord('kyc-kyb-update-process', {
        legallyRequired: false,
        needsReview: false,
        organization,
        status: 'created',
      });
    }

    let organizationChangeRequest;
    if (updateProcess.belongsTo('kycKybUpdateOrganizationChangeRequest').id()) {
      organizationChangeRequest = await updateProcess
        .belongsTo('kycKybUpdateOrganizationChangeRequest')
        .load();
    } else {
      let annualTurnover = null;

      if (
        this.annualTurnover
          .getAnnualTurnover(organization.legalCountry)
          .options.includes(organization.kybDetails.annualTurnover)
      ) {
        annualTurnover = organization.kybDetails.annualTurnover;
      }

      organizationChangeRequest = this.store.createRecord(
        'kyc-kyb-update-organization-change-request',
        {
          activityDescription: organization.activityDescription,
          addressCity: organization.address.city,
          addressCountry: organization.address.country,
          addressFirstLine: organization.address.firstLine,
          addressSecondLine: organization.address.secondLine,
          addressThirdLine: organization.address.thirdLine,
          addressZipCode: organization.address.zipcode,
          annualTurnover,
          estimatedAnnualTurnover: organization.kybDetails.estimatedAnnualTurnover,
          contactEmail: organization.contactEmail,
          estimatedYearlyIncome: organization.estimatedYearlyIncome,
          euVatNumber: organization.euVatNumber,
          legalCode: organization.legalCode,
          legalName: organization.legalName,
          legalNumber: organization.legalNumber,
          legalSector: organization.legalSector,
          maxSinglePaymentAmount: organization.kybDetails.maxSinglePaymentAmount,
          name: organization.tradeName,
          natureOfOperations: organization.kybDetails.natureOfOperations,
          taxCountry: organization.taxResidenceCountry,
          taxIdentificationNumber: organization.taxResidenceTaxIdentificationNumber,
          websiteUrl: organization.websiteUrl,
          legalRegistrationDate: organization.legalRegistrationDate,
          kycKybUpdateProcess: updateProcess,
          yearlyTransactionVolume: organization.kybDetails.yearlyTransactionVolume,
        }
      );
    }

    // load all membership updates
    let membershipUpdates = await updateProcess
      .hasMany('kycKybUpdateMembershipChangeRequests')
      .load();

    let memberships = [];
    let total = 1;

    for (let page = 1; page <= total; page++) {
      let membershipsPage = await this.store.query('membership', {
        organization_id: organization.id,
        page,
        per_page: params.per_page,
        filters: {
          is_ubo_or_owner_or_corporate_officer: true,
        },
        includes: ['membership_kyb_details'],
      });

      total = membershipsPage.meta.total_pages;
      memberships = memberships.concat(...membershipsPage);
    }

    await this.activityType.loadActivityCodesTask.perform();

    let membershipIds = [];

    for (let membership of memberships) {
      membershipIds.push(membership.id);

      if (!ownerChangeRequest && membership.owner) {
        ownerChangeRequest = membershipUpdates.find(update => {
          return update.belongsTo('membership').id() === membership.id;
        });

        if (!ownerChangeRequest) {
          let corporateOfficerValue =
            membership.corporateOfficer === null ? membership.owner : membership.corporateOfficer;

          let legalRepresentativeValue =
            membership.legalRepresentative || membership.corporateOfficer;

          ownerChangeRequest = this.store.createRecord('kyc-kyb-update-membership-change-request', {
            addressCity: membership.address.city,
            addressCountry: membership.address.country,
            addressFirstLine: membership.address.firstLine,
            addressSecondLine: membership.address.secondLine,
            addressThirdLine: membership.address.thirdLine,
            addressZipCode: membership.address.zipcode,
            birthdate: membership.birthdate,
            birthCity: membership.birthCity,
            birthCountry: membership.birthCountry,
            corporateOfficer: corporateOfficerValue,
            firstName: membership.firstName,
            lastName: membership.lastName,
            gender: membership.gender,
            legalRepresentative: legalRepresentativeValue,
            membership,
            nationality: membership.nationality,
            nationalities: membership.nationalities,
            owner: true,
            position: membership.position,
            taxCountry: membership.taxResidenceCountry,
            taxIdentificationNumber: membership.taxResidenceTaxIdentificationNumber,
            usTaxPayer: membership.kybDetails.usTaxPayer,
            taxPayerOutsideLegalCountry: membership.kybDetails.taxPayerOutsideLegalCountry,
            ubo: membership.ubo,
            uboConnectionToOrganization: membership.kybDetails?.uboConnectionToOrganization ?? null,
            uboConnectionToOrganizationRaw:
              membership.kybDetails?.uboConnectionToOrganizationRaw ?? null,
            kycKybUpdateProcess: updateProcess,
          });
        }
      }

      if (!membership.owner) {
        let newRepresentative = membershipUpdates.find(update => {
          return update.belongsTo('membership').id() === membership.id;
        });

        if (!newRepresentative) {
          let legalRepresentativeValue =
            membership.legalRepresentative !== null
              ? membership.legalRepresentative
              : membership.corporateOfficer === false
                ? false
                : null;

          newRepresentative = this.store.createRecord('kyc-kyb-update-membership-change-request', {
            addressCity: membership.address.city,
            addressCountry: membership.address.country,
            addressFirstLine: membership.address.firstLine,
            addressSecondLine: membership.address.secondLine,
            addressThirdLine: membership.address.thirdLine,
            addressZipCode: membership.address.zipcode,
            birthdate: membership.birthdate,
            birthCity: membership.birthCity,
            birthCountry: membership.birthCountry,
            corporateOfficer: membership.corporateOfficer,
            firstName: membership.firstName,
            lastName: membership.lastName,
            gender: membership.gender,
            legalRepresentative: legalRepresentativeValue,
            membership,
            nationality: membership.nationality,
            nationalities: membership.nationalities,
            position: membership.position,
            taxCountry: membership.taxResidenceCountry,
            taxIdentificationNumber: membership.taxResidenceTaxIdentificationNumber,
            ubo: membership.ubo,
            uboConnectionToOrganization: membership.kybDetails?.uboConnectionToOrganization ?? null,
            uboConnectionToOrganizationRaw:
              membership.kybDetails?.uboConnectionToOrganizationRaw ?? null,
            kycKybUpdateProcess: updateProcess,
          });
        }

        representativeChangeRequests.push(newRepresentative);
      }
    }

    let membershipCreations = membershipUpdates.filter(update => {
      return !membershipIds.includes(update.belongsTo('membership').id());
    });

    representativeChangeRequests.push(...membershipCreations);
    representativeChangeRequests.sort((rep1, rep2) => (rep1.lastName < rep2.lastName ? -1 : 1));

    let kbis = await updateProcess.kycKybUpdateKbisFile;

    // Load all document collection processes (periodic update for high-risk customers).
    let documentCollectionProcesses = await this.store.query('document-collection-process', {
      filter: {
        subject_type: 'Organization',
        subject_id: organization.id,
        status: 'created,pending_upload,pending_review',
      },
      include: 'required_documents',
    });

    return {
      organization,
      membership,
      organizationChangeRequest,
      ownerChangeRequest,
      representativeChangeRequests,
      updateProcess,
      kbis,
      documentCollectionProcesses,
    };
  }

  redirect({ organization }) {
    if (
      this.abilities.cannot('view settings organization') ||
      !organization.hasKycKybUpdateFeature
    ) {
      this.router.replaceWith('settings');
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('shouldBlockNavigation', true);
      controller.set('modalIsInUse', false);
    }
  }
}
