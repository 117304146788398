/* eslint-disable unicorn/prefer-at */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';
import { MAX_TERM_DEPOSIT_ACCOUNTS, STATUS } from 'qonto/constants/savings-account';
import { EmptyState } from 'qonto/react/components/savings-accounts/empty-state';

export default class SavingsController extends Controller {
  emptyState = EmptyState;

  @service abilities;
  @service modals;
  @service router;
  @service segment;

  queryParams = ['scroll'];

  @tracked scroll = false;
  @tracked scrolledTop = true;

  @reads('organization.remuneratedAuthorizedBalance') remuneratedAuthorizedBalance;

  get canCreateAccount() {
    return (
      this.abilities.can('create savings-account') ||
      this.abilities.can('create remunerated-account')
    );
  }

  get organization() {
    return this.model.organization;
  }

  @action onCreateAccount() {
    if (this.savingsAccountLimitReached) {
      return this.modals.open('savings-accounts/account-limit-reached');
    }
    this.segment.track('accounts_new_account_clicked', { origin: SAVINGS_DEFAULT_URL });
    this.router.transitionTo('accounts.new', {
      queryParams: { origin: SAVINGS_DEFAULT_URL },
    });
  }

  get savingsAccounts() {
    return [];
  }

  get remuneratedAccountsDetails() {
    return this.model.remuneratedAccountsTask?.lastSuccessful?.value.remuneratedAccounts || [];
  }

  get savingsAccountsOpened() {
    let savings = this.savingsAccounts?.length;
    let remunerated = this.organization.nonClosedRemuneratedAccounts?.length;
    return savings || remunerated;
  }

  get savingsAccountLimitReached() {
    let savingsAccounts = this.savingsAccounts.filter(({ status }) => status !== STATUS.CLOSED);
    return (
      (savingsAccounts.length === MAX_TERM_DEPOSIT_ACCOUNTS &&
        this.abilities.cannot('create remunerated-account')) ||
      (this.organization.isMaxRemuneratedAccountNumberReached &&
        this.abilities.cannot('create savings-account')) ||
      (savingsAccounts.length === MAX_TERM_DEPOSIT_ACCOUNTS &&
        this.organization.isMaxRemuneratedAccountNumberReached)
    );
  }

  @action maybeScrollToLast(element) {
    if (this.scroll) {
      // reset query param again to make the URL pretty
      this.scroll = false;

      let { children } = element;
      if (children.length > 2) {
        children[children.length - 2]?.scrollIntoView();
      }
    }
  }

  @action updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}
