export default {
  "page-wrapper": "qfS",
  "content-wrapper": "qfc",
  "content": "qfq",
  "period-field": "qfZ",
  "format-options": "qfR",
  "format-option": "qfQ",
  "format-radio": "qfe",
  "format-description": "qfB body-2"
};
