import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask, task } from 'ember-concurrency';

import { CopyButton } from 'qonto/react/shared/components/copy-button';

export default class ReceiptsForwardController extends Controller {
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service modals;
  @service organizationManager;
  @service store;

  @tracked _allowedEmail;

  CopyButton = CopyButton;

  get allowedEmail() {
    return this._allowedEmail || this.model.allowedEmail;
  }

  openEmailFormModalTask = task(async () => {
    let modal;
    try {
      modal = this.modals.open('allowed-emails/email-form-modal', {
        allowedEmail: this.allowedEmail,
        saveEmail: (...args) => this.saveEmailTask.perform(...args),
        onClose: () => this._rollbackAttributes(),
      });
      await modal;
    } finally {
      modal.close();
    }
  });

  saveEmailTask = dropTask(async (email, close) => {
    let message = this.allowedEmail.isNew
      ? this.intl.t('settings.invoice-receipt-email.email-added.success-toast')
      : this.intl.t('settings.invoice-receipt-email.email-edited.success-toast');

    this.allowedEmail.email = email;
    let { validations } = await this.allowedEmail.validate();

    if (validations.isValid) {
      try {
        await this.allowedEmail.save();
        close();
        this.toastFlashMessages.toastInfo(message);
      } catch {
        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
      }
    }
  });

  openDeleteEmailModalTask = task(async () => {
    let modal;
    try {
      modal = this.modals.open('popup/destructive', {
        title: this.intl.t('settings.invoice-receipt-email.delete-confirmation-modal.title'),
        description: this.intl.t(
          'settings.invoice-receipt-email.delete-confirmation-modal.subtitle'
        ),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('settings.invoice-receipt-email.delete-cta'),
        confirmTask: this.deleteEmailTask,
      });
      await modal;
    } finally {
      modal.close();
    }
  });

  deleteEmailTask = dropTask(async close => {
    try {
      await this.allowedEmail.destroyRecord();
      close();
      this._allowedEmail = this._createAllowedEmail();
      let message = this.intl.t('settings.invoice-receipt-email.email-deleted.success-toast');
      this.toastFlashMessages.toastInfo(message);
    } catch {
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  @action
  handleSuccessCopy() {
    this.segment.track('settings_forwarding_email_copy_button_clicked');
    let message = this.intl.t('settings.invoice-receipt-email.copy.success-toast');
    this.toastFlashMessages.toastInfo(message);
  }

  _createAllowedEmail() {
    let { organization } = this.organizationManager;
    return this.store.createRecord('allowed-email', { organization });
  }

  _rollbackAttributes() {
    if (this.allowedEmail.isNew) {
      this._allowedEmail = this._createAllowedEmail();
    } else {
      this.allowedEmail.rollbackAttributes();
    }
  }
}
