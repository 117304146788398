import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class ReceivableInvoicesRoute extends Route {
  @service abilities;
  @service homePage;
  @service store;
  @service organizationManager;
  @service invoiceSubscriptionManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'accounts-receivable');
    let canNavigate =
      this.abilities.can('navigate receivable-invoice') &&
      this.abilities.can('create receivable-invoice');

    if (!canNavigate || this.organizationManager.organization.isSuspended) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async model() {
    // important to clean up store
    this.store.unloadAll('direct-debit-collection-activation');

    let sddActivation = await this.store
      .createRecord('direct-debit-collection-activation')
      .fetchData();

    this.invoiceSubscriptionManager.setSddEligibility(sddActivation);
    return { sddActivation };
  }
}
