import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ValueProposition } from 'qonto/react/receivable-invoices/components/addon/value-proposition';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReceivableInvoicesAddonController extends Controller {
  @service router;
  @service intl;
  @service upsellManager;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service sentry;
  @service segment;

  valueProposition = ValueProposition;

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  @action
  onClose() {
    this.router.transitionTo('receivable-invoices.index');
  }

  onCtaClickTask = dropTask(async () => {
    this.segment.track('ar_add_on-value_proposition_modal-clicked', {
      cta_type: this.hasAvailableFreeTrial ? 'free_trial' : 'upgrade',
    });

    try {
      await this.upsellManager.upgradeRecommendationWithTransition('ar_advanced_customization');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
