export default {
  "page-wrapper": "qTx",
  "container": "qTw",
  "spinner-container": "qTN",
  "left-panel": "qTv",
  "form": "qTo",
  "left-content": "qTn",
  "form-footer": "qTi",
  "delete-button": "qTA",
  "header": "qTY",
  "preview-container": "qTh",
  "email-preview": "qTf",
  "close-button": "qTK btn btn--icon-only btn--tertiary btn--large"
};
