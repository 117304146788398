export default {
  "accounts": "qDh",
  "header-subtitle": "qDf title-4",
  "subtitle-container": "qDK",
  "subtitle": "qDG title-3 mb-16",
  "amount": "qDr body-2",
  "recommendations-section": "qDb",
  "dismissing": "qDy",
  "recommendations-divider": "qDU",
  "list--cashbeeSavings": "qDj",
  "list": "qDW",
  "icon": "qDu",
  "badge": "qVS",
  "discover-tile": "qVc"
};
