import Route from '@ember/routing/route';
import { service } from '@ember/service';

import TransactionsModalComponent from 'qonto/components/supplier-invoices/transactions-modal';

export default class SupplierInvoicesMatchTransactionsRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;
  @service store;
  @service modals;
  @service menu;
  @service toastFlashMessages;
  @service intl;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ invoice_id }) {
    let supplierInvoice = await this.store.findRecord('supplier-invoice', invoice_id);
    let attachment = await supplierInvoice.belongsTo('attachment').load();
    return {
      invoice: supplierInvoice,
      attachment,
    };
  }

  afterModel({ invoice, attachment }, transition) {
    let origin = transition.to?.queryParams?.origin;
    let transitionArgs =
      origin === 'invoice-details'
        ? ['supplier-invoices.show', invoice.id]
        : ['supplier-invoices.index'];
    let isLinkCreditNoteCtaDisplayed = invoice?.isCreditNote || false;
    let successMessage = isLinkCreditNoteCtaDisplayed
      ? this.intl.t('supplier-invoices.success-toast.matched-transaction')
      : this.intl.t('supplier-invoices.success-toast.mark-as-paid');

    this.modal = this.modals.open(
      TransactionsModalComponent,
      {
        invoice,
        attachment,
        isFullScreenModal: true,
        onCloseModal: () => {
          this.router.transitionTo(...transitionArgs);
          this.toastFlashMessages.toastSuccess(successMessage);
        },
      },
      {
        focusTrapOptions: {
          initialFocus: false,
          onDeactivate: () => {
            this.router.transitionTo(...transitionArgs);
          },
        },
      }
    );
  }

  resetController(_, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      this.modal.close();
    }
  }
}
