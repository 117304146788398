import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class InsuranceHubCategoriesRoute extends Route {
  @service insuranceManager;
  @service router;

  controllerName = 'insurance-hub';

  async model(params) {
    let { category_slug: categorySlug } = params;

    let products = await this.insuranceManager.getInsuranceProducts(categorySlug);

    return products.map(product => {
      if (!variation('feature--boolean-qontent-insurance-hub')) {
        return {
          description: product.data.description,
          logo: product.data.logo,
          name: product.data.name,
          price: product.data.price,
          risks: product.data.risks.map(item => item.risk.data.name),
          uid: product.uid,
        };
      }

      return {
        description: product.description,
        logo: product.logo,
        name: product.name,
        price: product.price,
        risks: product.risks,
        uid: product.slug,
      };
    });
  }
}
