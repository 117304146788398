export const paymentLinksOnboarding = {
  steps: {
    intro: {
      componentName: 'payment-links/onboarding/intro',
      nextStepId({ isNotConnected }) {
        return isNotConnected ? 'form' : 'feedback';
      },
    },
    form: {
      componentName: 'payment-links/onboarding/form',
      nextStepId: 'feedback',
    },
    feedback: {
      componentName: 'payment-links/onboarding/feedback',
    },
  },
  options: {
    enablePersistence: true,
  },
};
