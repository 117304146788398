/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { variation } from 'ember-launch-darkly';

import { Addons } from 'qonto/constants/addons';
import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ESignaturePromotionalPopup } from 'qonto/react/components/account-receivable/e-signature-promotional-popup';
import {
  CASH_FLOW_FIRST_TIME_EXPERIENCE_STORAGE_KEY,
  FirstTimeExperiencePopup,
} from 'qonto/react/components/cash-flow/components/first-time-experience/popup';
import {
  ESM_POPUP_STORAGE_KEY,
  EsmAddonFeaturesPopup,
} from 'qonto/react/components/esm-addon-features-popup/esm-addon-features-popup';
import { KycPendingPopup } from 'qonto/react/components/kyc/kyc-pending-popup';
import { RibaPromotionalPopup } from 'qonto/react/components/riba-promotional-popup';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';
import QontoInvoicingHomepage from 'qonto/utils/qonto-invoicing/qonto-invoicing-homepage';

export default class OverviewController extends Controller {
  queryParams = [{ bankAccounts: 'bank-accounts' }];

  @service router;
  @service flowLinkManager;
  @service abilities;
  @service segment;
  @service featuresManager;
  @service organizationManager;
  @service subscriptionManager;
  @service supplierInvoicesInsights;
  @service cashFlowManager;

  get hasRibaAbility() {
    return this.abilities.can('update riba');
  }

  get hasContractBeenSignedLessThan90DaysAgo() {
    let { organization } = this.organizationManager;
    let { contractSignedAt } = organization;

    return dayjs().isBetween(contractSignedAt, dayjs(contractSignedAt).add(90, 'days'));
  }

  get isBefore6thJanuary2025() {
    return dayjs().isBefore(dayjs('2025-01-06'));
  }

  get hasAlreadySeenConnectExternalAccountsPopup() {
    let { organization } = this.organizationManager;
    let { id } = organization;
    return safeLocalStorage.getItem(`connect-external-accounts-popup-shown_${id}`);
  }

  get hasAlreadySeenMoveRecurringPaymentsPopup() {
    let { organization } = this.organizationManager;
    let { id } = organization;
    return safeLocalStorage.getItem(`move-recurring-payments-popup-shown_${id}`);
  }

  get externalBankRecommendationsName() {
    return this.model.recommendationsBundle?.bankName;
  }

  get hasRecommendations() {
    return this.model.recommendationsBundle?.recommendations.length > 0;
  }

  get qontoInvoicingHomepage() {
    return new QontoInvoicingHomepage(
      this.subscriptionManager,
      this.model.invoicesStatsTask,
      this.model.clientInvoicesOnboardingState,
      this.supplierInvoicesInsights
    );
  }

  get showQontoInvoicingActivationHomepage() {
    return this.qontoInvoicingHomepage.shouldShow;
  }

  get shouldShowAccountsFilter() {
    if (!this.subscriptionManager.isQontoInvoicing) {
      return true;
    }
    return (
      this.qontoInvoicingHomepage.isLogoStepDone !== true &&
      this.qontoInvoicingHomepage.hasReceivableInvoices !== true &&
      this.qontoInvoicingHomepage.hasSupplierInvoices !== true
    );
  }

  get audience() {
    let audience = variation('feature--string-spend-management-addon-modal');
    if (!audience || audience === 'off') {
      return false;
    }
    return audience;
  }

  get shouldUserSeeEsmPopup() {
    if (safeLocalStorage.getItem(ESM_POPUP_STORAGE_KEY)) {
      return false;
    }
    let { role } = this.organizationManager.membership;
    return [ROLES.ADMIN, ROLES.OWNER].includes(role);
  }
  /**
   * Handles popup rendering.
   * Sort by priority.
   * Only one can be rendered per condition.
   * @returns {{reactComponent: () => React.ReactNode, props?: Record<any, any>}}}
   */
  get popup() {
    let { organization, membership } = this.organizationManager;

    if (
      membership.shouldSubmitKyc &&
      // We only focus on owners
      organization.kybPending &&
      variation('feature--boolean-improve-modal-to-increase-kyc-rate')
    ) {
      return {
        reactComponent: KycPendingPopup,
      };
    }

    if (
      variation('feature--boolean-cash-flow-first-time-experience') &&
      this.cashFlowManager.firstTimeExperienceState === FIRST_TIME_EXPERIENCE_STATES.NOT_STARTED &&
      safeLocalStorage.getItem(CASH_FLOW_FIRST_TIME_EXPERIENCE_STORAGE_KEY) !== 'true' &&
      this.abilities.can('edit forecast cash-flow') &&
      this.abilities.cannot('fully interact with forecast cash-flow')
    ) {
      return {
        reactComponent: FirstTimeExperiencePopup,
      };
    }

    if (this.audience && this.shouldUserSeeEsmPopup) {
      return {
        reactComponent: EsmAddonFeaturesPopup,
        props: {
          audience: this.audience,
        },
      };
    }

    if (
      !organization.underRegistration &&
      variation('feature--boolean-riba-promotional-elements') &&
      this.hasRibaAbility
    ) {
      return {
        reactComponent: RibaPromotionalPopup,
      };
    }

    if (
      this.abilities.can('create receivableInvoice') &&
      this.subscriptionManager.hasAddon(Addons.AccountsReceivable) &&
      variation('feature--boolean-ar-quotes-esignature')
    ) {
      return {
        reactComponent: ESignaturePromotionalPopup,
      };
    }
  }

  get createTransfer() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');
    let canCreateTransfer = this.abilities.can('create transfer');

    if (isQontoPilotFeatureEnabled) {
      if (canUseQontoPilot) {
        return false;
      } else {
        return canCreateTransfer && !canUseQontoPilot;
      }
    } else {
      return canCreateTransfer;
    }
  }

  @action
  redirectToTransferPage() {
    this.segment.track('overview_create_transfer_clicked');
    return this.router.transitionTo('transfers.new');
  }
}
