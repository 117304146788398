import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class OrganizationProfileRoute extends Route {
  @service organizationManager;
  @service abilities;
  @service subscriptionManager;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
    let { hasKycKybUpdateFeature } = this.organizationManager.organization;

    if (!this.subscriptionManager.features.bankAccount) {
      return;
    }

    if (this.abilities.cannot('view settings organization') || hasKycKybUpdateFeature) {
      this.router.replaceWith('settings');
    }
  }

  async model() {
    let { organization } = this.organizationManager;
    await organization.getAvatar();

    let deactivationData = await organization.getDeactivation();

    return { organization, deactivationData };
  }
}
