export default {
  "container": "qiW",
  "wrapper": "qiu",
  "grey-header-block": "qAS",
  "connect-app-header": "qAc",
  "connect-app-header-link": "qAq",
  "connect-disclaimer-wrapper": "qAZ",
  "connect-app-header-content": "qAR",
  "connect-app-header-cta": "qAQ",
  "connect-app-content": "qAe",
  "connect-app-description": "qAB",
  "connect-app-body": "qAE"
};
