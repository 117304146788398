import { getOwner } from '@ember/owner';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

import window from 'ember-window-mock';

import { HrisConnection } from 'qonto/models/hris-integration';
import { parseObjectValuesQP } from 'qonto/utils/query-params';

const TRANSITION_ORIGIN = 'connectDetailsTransitionOrigin';

export default class HubApplicationDetailsRoute extends Route {
  @service organizationManager;
  @service store;

  hubApplicationRouteName = 'settings.connect-hub.applications.hub-application';

  model(params, transition) {
    let applicationParams = this.paramsFor(this.hubApplicationRouteName);
    let { organization } = this.organizationManager;
    let { hubApplication, integration } = this.modelFor(this.hubApplicationRouteName);
    let solutionId = integration.tray_solution_id;
    let komboConnectProviderName = integration.kombo_connect_provider_name;
    let fromTransition;

    if (
      transition &&
      transition.from &&
      transition.from.name !== 'settings.connect-hub.applications.hub-application.setup'
    ) {
      window.sessionStorage.setItem(
        TRANSITION_ORIGIN,
        JSON.stringify({
          name: transition.from.name,
          params: JSON.stringify(transition.from.params),
          queryParams: JSON.stringify(transition.from.queryParams),
        })
      );
    }
    if (window.sessionStorage.getItem(TRANSITION_ORIGIN)) {
      fromTransition = JSON.parse(window.sessionStorage.getItem(TRANSITION_ORIGIN));
      fromTransition.params = JSON.parse(fromTransition.params);
      fromTransition.queryParams = parseObjectValuesQP(JSON.parse(fromTransition.queryParams));
    }

    return hash({
      fromTransition,
      hubApplication,
      organization,
      solutionInstance:
        solutionId &&
        this.store
          .query('solution-instance', {
            organization_id: organization.id,
            solution_id: solutionId,
          })
          .then(solutionInstances => solutionInstances[0]),
      integration,
      hubConnection: this.store
        .query('hub-connection', {
          organization_id: organization.id,
          filters: {
            external_service: applicationParams.application_slug,
          },
        })
        .then(connections => connections[0]),
      hrisConnection:
        komboConnectProviderName && HrisConnection.create(getOwner(this), komboConnectProviderName),
      ...(integration?.bank_provider_connector_uuid
        ? {
            bankConnection: this.store
              .query('bank-connection', { connector_id: integration.bank_provider_connector_uuid })
              .then(accounts => accounts[0]),
          }
        : {}),
    });
  }
}
