import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class ReimbursementsIndexRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;

  beforeModel() {
    if (
      this.abilities.cannot('navigate reimbursements approver view in request') &&
      this.abilities.can('use reimbursements request')
    ) {
      return this.router.replaceWith('reimbursements.requests');
    }

    if (this.abilities.can('create transfer')) {
      return this.router.replaceWith('reimbursements.pending.to-pay');
    }

    if (
      !this.subscriptionManager.hasFeature('bank_account') &&
      variation('feature--boolean-expense-report-on-qf')
    ) {
      return this.router.replaceWith('reimbursements.completed');
    }

    return this.router.replaceWith('reimbursements.pending.to-approve');
  }
}
