import Controller from '@ember/controller';

import { INTEGRATION_TYPES } from 'qonto/constants/connect';
import { ImageGallery } from 'qonto/react/components/connect/image-gallery';

export default class HubApplicationDetailsController extends Controller {
  imageGallery = ImageGallery;
  readMoreIsOpen = false;

  get integrationImages() {
    return this.model.integration.images.map(el => el.image).filter(image => image.url);
  }

  get isPartnership() {
    return this.model.integration.integration_type === INTEGRATION_TYPES.partnerships;
  }
}
