import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { CertificateDownload } from 'qonto/react/components/certificate-download';
import { RemuneratedAccountMain } from 'qonto/react/components/remunerated-accounts/details/main';

export default class AccountsDetailsController extends Controller {
  remuneratedAccountMain = RemuneratedAccountMain;
  certificateDownload = CertificateDownload;

  @service router;
  @service organizationManager;
  @service abilities;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get parentRoute() {
    return this.isParentTransactions()
      ? 'transactions'
      : this.router.currentRoute?.queryParams?.origin || 'accounts';
  }

  get handleQuery() {
    let { queryParams } = this.origin?.parent || {};
    let queryParamsTemp = Object.assign({}, queryParams);

    if (this.isParentTransactions()) {
      if (queryParams.filters) {
        let filters = { filters: JSON.parse(queryParams.filters) };
        queryParamsTemp = { ...queryParams, ...filters };
      }
    }

    if (queryParamsTemp) {
      Object.keys(queryParamsTemp).forEach(
        k => queryParamsTemp[k] === null && delete queryParamsTemp[k]
      );
    }

    return queryParamsTemp;
  }

  get bankAccount() {
    return this.model?.bankAccount;
  }

  get remuneratedAccountDetails() {
    return this.model?.remunerationDetails;
  }

  get canDownloadCertificate() {
    let ability = this.abilities.abilityFor('bank-account');
    return (
      ability.canDownloadCertificate &&
      variation('feature--boolean-automating-account-certificates')
    );
  }

  isParentTransactions() {
    return this.origin?.parent?.name === 'transactions';
  }

  @action updateAccount(slug) {
    this.router.transitionTo('accounts.details', slug, {
      queryParams: { origin: this.router.currentRoute?.queryParams?.origin },
    });
  }
}
