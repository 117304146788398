import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { ErrorState } from '@repo/design-system-kit';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { dropTask, restartableTask } from 'ember-concurrency';

import { RIBA_STATUS } from 'qonto/constants/riba';
import { RIBA_SORT_BYS } from 'qonto/constants/sort';
import RibaPaymentModel from 'qonto/models/riba-payment';
import { Sidebar } from 'qonto/react/components/riba/sidebar';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class RibaCompletedController extends Controller {
  emptyStatesInform = EmptyStatesInform;
  ErrorState = ErrorState;

  @service intl;
  @service organizationManager;
  @service segment;
  @service store;
  @service toastFlashMessages;
  @service sensitiveActions;
  @service router;
  @service ribaAccountSelectionModal;
  @service sentry;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = `${RIBA_SORT_BYS.EXECUTION_DATE}:desc`;
  @tracked selectedPayments = [];
  @tracked statusFilter = null;
  @tracked sidebarPayment = null;

  Sidebar = Sidebar;

  queryParams = ['page', { perPage: 'per_page' }, { sortBy: 'sort_by' }];

  performRibaAction = dropTask(async (action, bankAccountId, paymentId) => {
    try {
      await RibaPaymentModel.updateBulk(this.store, action, bankAccountId, [paymentId]);

      if (action === 'approve') {
        this.toastFlashMessages.toastSuccess(this.intl.t('riba.toast.approved.single'));
        this.router.transitionTo('riba.processing');
      }

      this.reloadData();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });

  approveRibaTask = dropTask(async (bankAccountId, paymentId) => {
    this.sidebarPayment = null;
    await this.sensitiveActions.runTask.perform(
      this.performRibaAction,
      'approve',
      bankAccountId,
      paymentId
    );
  });

  openAccountSelectionPopupTask = dropTask(async payment => {
    this.closeSidebar();

    let hasSingleBankAccount = this.organizationManager.organization.activeAccounts.length === 1;

    if (hasSingleBankAccount) {
      let bankAccount = this.organizationManager.organization.activeAccounts[0];
      await this.sensitiveActions.runTask.perform(
        this.performRibaAction,
        'approve',
        bankAccount.id,
        payment.id
      );
      return;
    }

    await this.ribaAccountSelectionModal.show({
      onSubmit: async bankAccount => {
        await this.sensitiveActions.runTask.perform(
          this.performRibaAction,
          'approve',
          bankAccount.id,
          payment.id
        );
      },
      onSubmitRunning: this.performRibaAction.isRunning,
    });
  });

  @action updateParam(param, value) {
    this[param] = value;
  }

  get payments() {
    return this.fetchDataTask.last?.value || [];
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  get isError() {
    return this.fetchDataTask.last?.isError;
  }

  get hasNoResult() {
    return this.payments.length === 0 && !this.isLoading && this.statusFilter;
  }

  get isEmptyState() {
    return this.payments.length === 0 && !this.isLoading && !this.statusFilter && !this.isError;
  }

  get hasNoActiveAccounts() {
    return !this.organizationManager.organization.activeAccounts.length;
  }

  get isClosedAccountState() {
    return this.hasNoActiveAccounts && this.payments.length === 0;
  }

  @action closeSidebar() {
    this.sidebarPayment = null;
  }

  @action openSidebar(payment) {
    this.segment.track('riba_completed_payment_details_opened');
    this.sidebarPayment = payment;
  }

  get filterOptions() {
    return [
      {
        code: RIBA_STATUS.COMPLETED,
        value: this.intl.t('riba.status.completed'),
        key: Object.keys(RIBA_STATUS).find(key => RIBA_STATUS[key] === RIBA_STATUS.COMPLETED),
      },
      {
        code: RIBA_STATUS.DECLINED,
        value: this.intl.t('riba.status.declined'),
        key: Object.keys(RIBA_STATUS).find(key => RIBA_STATUS[key] === RIBA_STATUS.DECLINED),
      },
      {
        code: RIBA_STATUS.REJECTED,
        value: this.intl.t('riba.status.rejected'),
        key: Object.keys(RIBA_STATUS).find(key => RIBA_STATUS[key] === RIBA_STATUS.REJECTED),
      },
      {
        code: RIBA_STATUS.CANCELED,
        value: this.intl.t('riba.status.canceled'),
        key: Object.keys(RIBA_STATUS).find(key => RIBA_STATUS[key] === RIBA_STATUS.CANCELED),
      },
    ];
  }

  @action handleStatusSelect(value) {
    this.statusFilter = value;
    this.page = 1;
    this.reloadData();
  }

  fetchDataTask = restartableTask(async () => {
    let statuses = this.statusFilter
      ? [RIBA_STATUS[this.statusFilter.key]]
      : [RIBA_STATUS.COMPLETED, RIBA_STATUS.CANCELED, RIBA_STATUS.REJECTED, RIBA_STATUS.DECLINED];

    return await this.store.query('riba-payment', {
      statuses,
      page: this.page,
      per_page: this.perPage,
      sort_by: this.sortBy,
    });
  });

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.perPage = value;
    this.reloadData();
  }

  @action changePage(page) {
    this.page = page;
    this.reloadData();
  }

  @action
  changeSortBy(value) {
    this.sortBy = value;
    this.page = 1;
    this.reloadData();
  }

  @action
  reloadData() {
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }
}
