import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class AddonChangeDataContext {
  @tracked recurrence;
  @tracked refererPage;

  constructor({ recurrence, refererPage, addonCode }) {
    this.recurrence = recurrence;
    this.refererPage = refererPage;
    this.addonCode = addonCode;
  }
}
export default class AddonChangeFlowSetup extends FlowSetup {
  @service organizationManager;
  @service router;
  @service flow;
  @service abilities;
  @service intl;
  @service segment;
  @service toastFlashMessages;

  dataContext = null;

  constructor() {
    super(...arguments);

    let { queryParams } = this.router.currentRoute;
    let { dataContext } = this.flow;

    let recurrence =
      queryParams.addon_recurrence || dataContext?.recurrence || SUBSCRIPTION_RECURRENCES.MONTHLY;

    let addonCode = queryParams.addon || dataContext?.addonCode;

    this.dataContext = new AddonChangeDataContext({
      addonCode,
      recurrence,
      refererPage: this.flow.refererPage,
    });
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');

    if (
      this.abilities.cannot('update subscription') ||
      this.organizationManager.organization.status === ORGA_STATUS.DEACTIVATED
    ) {
      return this.router.replaceWith('settings');
    }
  }

  onAbortTask = dropTask(async context => {
    let currentStepId = this.router.currentRoute?.params?.step_id;
    if (currentStepId === 'addons') {
      this.segment.track('addon_management_close_clicked', {
        origin: 'close',
      });
    }

    if (currentStepId === 'benefits-list') {
      this.segment.track('remove_addon_keep_benefits_clicked', {
        addon_code: context.tracking.addon_code,
        addon_recurrence: context.tracking.addon_recurrence,
      });
    }

    if (currentStepId === 'confirm' || currentStepId === 'confirm-remove') {
      this.segment.track('checkout_rejected', {
        ...context.tracking,
        confirmation_type:
          context.tracking.confirmation_type === 'add'
            ? 'add'
            : context.tracking.confirmation_type === 'removal'
              ? 'removal'
              : 'other',
      });
    }

    await this._goBackToMainPage();
  });

  @action
  onComplete() {
    return this._goBackToMainPage();
  }

  @action
  onSuccessToast(dataContext) {
    if (dataContext.isItalyError) {
      this.toastFlashMessages.toastError(
        this.intl.t('subscription.change.error.italy-restrictions.toast')
      );
    } else {
      this.toastFlashMessages.toastSuccess(this.intl.t('subscription-addon.change.success-toast'));
    }
  }

  _goBackToMainPage() {
    if (this.flow.refererPage) {
      this.router.transitionTo(this.flow.refererPage);
    } else {
      let organizationSlug = this.organizationManager.organization.slug;
      this.router.transitionTo('settings.subscription.index', organizationSlug);
    }
  }
}
