import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
// eslint-disable-next-line no-duplicate-imports -- needed because Onwer is a type default exported
import type Owner from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class CashFlowFirstTimeExperienceFlowSetup {
  @service declare sentry: Services['sentry'];
  @service declare router: Services['router'];

  dataContext = {};

  constructor(owner: Owner) {
    setOwner(this, owner);
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);
  }

  @action
  onAbort() {
    this.router.transitionTo('overview');
  }

  @action
  onComplete() {}
}
