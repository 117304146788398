import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { QUOTE_STATUSES } from 'qonto/constants/quotes';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class QuotesIndexRoute extends Route {
  @service abilities;
  @service router;
  @service organizationManager;
  @service sentry;
  @service intl;
  @service store;
  @service modals;
  @service toastFlashMessages;

  queryParams = {
    status: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
  };

  activate() {
    this.router.on('routeWillChange', this.storeTransition);
  }

  deactivate() {
    this.router.off('routeWillChange', this.storeTransition);
    this.fetchQuotesTask.lastSuccessful = null;
  }

  model(params) {
    let canReadQuotes = this.abilities.can('read receivableInvoice');

    this.fetchQuotesTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this.handleError(error));
    this.fetchSettingsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this.handleError(error));

    return {
      canReadQuotes,
      quotesTask: this.fetchQuotesTask,
      settingsTask: this.fetchSettingsTask,
    };
  }

  combinedSortParam(sortBy, defaultSortBy) {
    if (sortBy.replace(/^-/, '') === defaultSortBy.replace(/^-/, '')) {
      return sortBy;
    }
    return [sortBy, defaultSortBy].join(',');
  }

  getParamsWithOrganizationId(params) {
    let organizationId = this.organizationManager.organization.id;
    let { status, page, perPage, sortBy } = params;
    let filter = status ? { status } : {};
    let defaultSortBy =
      status === QUOTE_STATUSES.PENDING ? '-expiry_date,-number' : '-issue_date,-number';
    return {
      filter: { organization_id: organizationId, ...filter },
      page: { number: page, size: perPage },
      sort: this.combinedSortParam(sortBy, defaultSortBy),
    };
  }

  fetchQuotesTask = restartableTask(async params => {
    let paramsWithOrganizationId = this.getParamsWithOrganizationId(params);

    let quotes;

    quotes = await this.store.query('quote', paramsWithOrganizationId, {});

    let hasReachedLimitOfQuotes =
      (await this.store.modelFor('quote').getStats(this.store)).quotasRemaining?.total === 0;

    let quoteStats = await this.store.modelFor('quote').getStats(this.store);

    return {
      quotes: quotes ?? [],
      hasReachedLimitOfQuotes,
      quoteStats,
      meta: {
        total_count: quotes?.meta?.total ?? 0,
        total_pages: quotes?.meta ? quotes?.meta.total / paramsWithOrganizationId.page.size : 1,
        per_page: paramsWithOrganizationId.page.size,
        current_page: paramsWithOrganizationId.page.number,
      },
      quotesCreatedCount: quotes?.meta?.total_for_organization ?? 0,
    };
  });

  fetchSettingsTask = restartableTask(async params => {
    let paramsWithOrganizationId = this.getParamsWithOrganizationId(params);
    let { organization_id } = paramsWithOrganizationId.filter;
    let settings = {};

    settings = await this.store.findRecord('receivable-invoices-settings', organization_id);

    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }

    return settings;
  });

  storeTransition(transition) {
    this.transition = transition;
  }

  resetController(controller, isExiting) {
    if (
      !isExiting ||
      this.router.transition.targetName === 'quotes.show' ||
      this.router.transition.targetName === 'invoicing-settings'
    )
      return;

    controller.status = null;
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = controller.defaultSortBy;
  }

  beforeModel({ to }) {
    if (!this.abilities.can('create receivableInvoice')) {
      return this.router.replaceWith('receivable-invoices');
    }

    if (!to.queryParams.status) {
      return this.router.replaceWith({ queryParams: { status: QUOTE_STATUSES.PENDING } });
    }
  }

  handleError(exception) {
    let { errors } = exception;
    if (errors?.some(error => error?.source?.pointer.includes('/quotefilters'))) {
      return this.router.replaceWith({ queryParams: { status: QUOTE_STATUSES.PENDING } });
    }

    let errorInfo = ErrorInfo.for(exception);
    if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 401) {
      this.sentry.captureException(exception);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
