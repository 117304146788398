import Controller from '@ember/controller';

import { Spinner } from '@repo/design-system-kit';

import { PageHeader } from 'qonto/react/financing/components/pay-later/cockpit/page-header';

export default class FinancingPayLaterLoadingControllerController extends Controller {
  spinner = Spinner;
  pageHeader = PageHeader;
}
