export const cashFlowFirstTimeExperience = {
  steps: {
    intro: {
      componentName: 'cash-flow/first-time-experience/intro',
      nextStepId: 'loading',
    },
    loading: {
      componentName: 'cash-flow/first-time-experience/loading',
      nextStepId: 'categories-mapping',
    },
    'categories-mapping': {
      componentName: '',
      nextStepId: 'success',
    },
    success: {
      componentName: '',
    },
  },
  options: {
    enablePersistence: false,
  },
};
