import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignore404, ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesShowRoute extends BaseReceivableInvoicesRoute {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service router;
  @service store;
  @service organizationManager;
  @service sentry;
  @service segment;
  @service abilities;

  queryParams = {
    imported: { refreshModel: true },
    reload: { refreshModel: true },
  };

  beforeModel() {
    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('receivable-invoice').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }
  }

  async model(params, transition) {
    this.origin = transition.from;

    let organizationId = this.organizationManager.organization.id;
    let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
      .perform()
      .catch(ignoreCancelation);

    if (this.abilities.can('read client-hubs')) {
      await this.fetchClientsTask.perform().catch(this.error);
    }

    try {
      let invoiceData = await this.fetchTask.perform(params.id);

      this.fetchSettingsTask.perform(organizationId).catch(ignore404).catch(this.error);

      this.modal = this.modals.open(
        'receivable-invoices/invoice-modal',
        {
          isFullScreenModal: true,
          hasSidebar: true,
          imported: params?.imported,
          fetchTask: this.fetchTask,
          fetchSettingsTask: this.fetchSettingsTask,
          isPdfPreviewWithNoToolbar: true,
          isDuplicateDisabled:
            (await this.store.modelFor('receivable-invoice').getStats(this.store)).quotasRemaining
              ?.total === 0,

          canCreateFrEinvoice,
        },
        {
          focusTrapOptions: {
            initialFocus: false,
            onDeactivate: this.onCloseModal,
          },
        }
      );

      if (params.reload) {
        this._reloadsWithIntervals(params.id);
      }

      return invoiceData;
    } catch (error) {
      this.error(error);
    }
  }

  _reloadsWithIntervals(id) {
    let reloadIntervals = [500, 1000, 2000];

    for (let interval of reloadIntervals) {
      later(
        this,
        () => this._reloadInvoiceAndClientTask.perform(id).catch(ignoreCancelation),
        macroCondition(isTesting()) ? 0 : interval
      );
    }
  }

  _reloadInvoiceAndClientTask = restartableTask(async id => {
    let invoice = await this.store.peekRecord('receivable-invoice', id);

    if (!invoice) {
      return;
    }
    await invoice.reload();

    if (this.abilities.can('read client-hubs')) {
      let client = this.store.peekRecord('client-hub', invoice.belongsTo('customer').id());
      await client.reload();
    }
  });

  fetchSettingsTask = restartableTask(async organizationId => {
    let settings = await this.store.findRecord('receivable-invoices-settings', organizationId);
    return settings;
  });

  fetchTask = dropTask(async id => {
    this.organizationManager.organization.getAvatar();

    let invoice = await this.store.findRecord('receivable-invoice', id);

    await invoice.belongsTo('quote').load();
    await invoice.belongsTo('depositInvoice').load();
    await invoice.hasMany('receivableCreditNotes').load();
    await invoice.hasMany('balanceInvoices').load();

    await invoice.setPdfPreviewIframeUrl();

    return { invoice };
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  deactivate() {
    this.fetchTask.lastSuccessful = undefined;
    this.fetchTask.last = undefined;
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.modal.close();
    }
  }

  @action error(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.replaceWith('receivable-invoices.index');
  }

  @action onCloseModal() {
    if (this.origin?.name === 'payment-links.index') {
      this.router.transitionTo('payment-links.index', { queryParams: this.origin.queryParams });
    } else if (this.origin?.name === 'upcoming-transactions.index') {
      this.router.transitionTo('upcoming-transactions.index');
    } else {
      this.router.transitionTo('receivable-invoices.index');
    }
    this.segment.track('invoice_options_escape');
  }
}
