export default {
  "page-container": "qmL",
  "form": "qmT",
  "close-button": "qmz btn btn--icon-only btn--tertiary btn--large",
  "preview": "qma",
  "preview-padding": "qmH",
  "title": "qmO",
  "header": "qmm",
  "without-tabs": "qmp",
  "form-section": "qmx",
  "form-footer": "qmw",
  "disclaimer-text": "qmN"
};
