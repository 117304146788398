import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { CFT } from 'qonto/constants/sentry-tags';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';

export default class ForecastRoute extends Route {
  @service featuresManager;
  @service abilities;
  @service homePage;
  @service cashFlowManager;
  @service flowLinkManager;
  @service organizationManager;

  @reads('organizationManager.organization.pricePlanFeatures.cfmForecast') hasForecastFeature;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);

    if (this.abilities.cannot('view cash-flow')) {
      return this.homePage.visitDefaultPage();
    }

    if (
      variation('feature--boolean-cash-flow-first-time-experience') &&
      this.cashFlowManager.firstTimeExperienceState === FIRST_TIME_EXPERIENCE_STATES.NOT_STARTED &&
      this.abilities.can('edit forecast cash-flow') &&
      !this.hasForecastFeature
    ) {
      return this.flowLinkManager.transitionTo({
        name: 'cash-flow-first-time-experience',
        stepId: 'intro',
      });
    }
  }

  model({ bankAccounts }) {
    return { bankAccounts };
  }
}
