export default {
  "vat-number": "qAu body-2",
  "banner": "qYS",
  "container": "qYc",
  "organization-profile": "qYq",
  "input": "qYZ",
  "medium-input": "qYR medium",
  "small-input": "qYQ small",
  "actions": "qYe",
  "company-info": "qYB",
  "company-info-section": "qYE",
  "company-address-section": "qYd",
  "company-country": "qYI",
  "company-billing-email": "qYt",
  "company-address": "qYM"
};
