export default {
  "page-wrapper": "qTS",
  "back-button": "qTc",
  "abort-button": "qTq",
  "container": "qTZ",
  "subtitle": "qTR",
  "button": "qTQ",
  "panel-list": "qTe",
  "panel-list-item": "qTB",
  "card-illustration": "qTE"
};
