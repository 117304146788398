import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ApprovalWorkflowsRoute extends Route {
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  async beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    try {
      await this.organizationManager.findMembers();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.homePage.visitDefaultPage();
    }
  }

  redirect() {
    if (
      this.abilities.cannot('create approval-workflow') &&
      this.abilities.cannot('upsell approval-workflow')
    ) {
      this.homePage.visitDefaultPage();
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    return controller.fetchDataTask.perform();
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.set('highlight', null);
  }
}
