import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import window from 'ember-window-mock';

export default class ReceivableInvoicesAccountantAccessUpgradeController extends Controller {
  @service intl;
  @service subscriptionManager;
  @service upsellManager;
  @service router;

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('accountant_access') || this.hasInitialTrial;
  }

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-invite-accountant.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goBack() {
    window.history.back();
  }

  @action
  upsell() {
    this.upsellManager.upsell({ feature: 'accountant_access' });
  }
}
