import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { ErrorState } from '@repo/design-system-kit';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { dropTask, restartableTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { DEFAULT_SORT_ORDER, RIBA_SORT_BYS } from 'qonto/constants/sort';
import RibaPaymentModel from 'qonto/models/riba-payment';
import { Sidebar } from 'qonto/react/components/riba/sidebar';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class RibaTasksController extends Controller {
  emptyStatesInform = EmptyStatesInform;
  ErrorState = ErrorState;

  @service intl;
  @service organizationManager;
  @service segment;
  @service store;
  @service zendeskLocalization;
  @service sensitiveActions;
  @service toastFlashMessages;
  @service sentry;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = `${RIBA_SORT_BYS.EXECUTION_DATE}:${DEFAULT_SORT_ORDER}`;
  @tracked selectedPayments = [];
  @tracked sidebarPayment = null;

  queryParams = ['page', { perPage: 'per_page' }, { sortBy: 'sort_by' }];

  Sidebar = Sidebar;

  performRibaAction = dropTask(async (action, bankAccountId, paymentIds) => {
    try {
      await RibaPaymentModel.updateBulk(this.store, action, bankAccountId, paymentIds);
      let isSingle = paymentIds.length === 1;
      if (action === 'approve') {
        if (isSingle) {
          this.toastFlashMessages.toastSuccess(this.intl.t('riba.toast.approved.single'));
        } else {
          this.toastFlashMessages.toastSuccess(
            this.intl.t('riba.toast.approved.bulk', { amount: paymentIds.length })
          );
        }
      } else {
        if (isSingle) {
          this.toastFlashMessages.toastSuccess(this.intl.t('riba.toast.rejected.single'));
        } else {
          this.toastFlashMessages.toastSuccess(
            this.intl.t('riba.toast.rejected.bulk', { amount: paymentIds.length })
          );
        }
      }
      this.reloadData();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });

  approveRibaTask = dropTask(async (bankAccountId, paymentIds) => {
    await this.sensitiveActions.runTask.perform(
      this.performRibaAction,
      'approve',
      bankAccountId,
      paymentIds
    );
  });

  rejectRibaTask = dropTask(async (bankAccountId, paymentIds) => {
    await this.performRibaAction.perform('reject', bankAccountId, paymentIds);
  });

  @action closeSidebar() {
    this.sidebarPayment = null;
  }

  @action openSidebar(payment) {
    this.segment.track('riba_pending_payment_details_opened');
    this.sidebarPayment = payment;
  }

  @action updateParam(param, value) {
    this[param] = value;
  }

  get shouldShowSelectedFooter() {
    return this.selectedPayments.length > 0;
  }

  get payments() {
    return this.fetchDataTask.last?.value || [];
  }

  get selectedPaymentsSum() {
    if (!this.selectedPayments?.length) {
      return 0;
    }

    let paymentsById = this.payments.reduce((acc, payment) => {
      acc[payment.id] = payment;
      return acc;
    }, {});

    return this.selectedPayments.reduce((sum, id) => {
      let payment = paymentsById[id];
      if (!payment) {
        return sum;
      }
      return sum + Number(payment.amount.value);
    }, 0);
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  get isError() {
    return this.fetchDataTask.last?.isError;
  }

  get isEmptyState() {
    return this.payments.length === 0 && !this.isLoading && !this.isError;
  }

  get hasNoActiveAccounts() {
    return !this.organizationManager.organization.activeAccounts.length;
  }

  get isClosedAccountState() {
    return this.hasNoActiveAccounts && this.payments.length === 0;
  }

  @action onFaqClick() {
    let link = this.zendeskLocalization.getLocalizedArticle(3258378);
    window.open(link, '_blank');
  }

  fetchDataTask = restartableTask(async () => {
    return await this.store.query('riba-payment', {
      statuses: ['pending'],
      page: this.page,
      per_page: this.perPage,
      sort_by: this.sortBy,
    });
  });

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.perPage = value;
    this.reloadData();
  }

  @action changePage(page) {
    this.page = page;
    this.reloadData();
  }

  @action
  changeSortBy(value) {
    this.sortBy = value;
    this.page = 1;
    this.reloadData();
  }

  @action
  reloadData() {
    this.sidebarPayment = null;
    this.selectedPayments = [];
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  get areAllSelected() {
    if (!this.payments?.length) return false;
    return this.selectedPayments.length === this.payments.length;
  }

  get areSomeSelected() {
    if (!this.payments?.length) return false;
    return this.selectedPayments.length > 0 && this.selectedPayments.length < this.payments.length;
  }

  @action
  selectAll(isChecked) {
    if (!isChecked || this.areSomeSelected) {
      this.selectedPayments = [];
      return;
    }

    if (!this.payments?.length) return;
    this.selectedPayments = this.payments.map(payment => payment.id);
    this.segment.track('riba_pending_bulk_clicked');
  }

  @action
  selectPayment(paymentId, isChecked) {
    if (isChecked) {
      if (!this.selectedPayments.includes(paymentId)) {
        this.selectedPayments = [...this.selectedPayments, paymentId];
      }
    } else {
      this.selectedPayments = this.selectedPayments.filter(id => id !== paymentId);
    }
  }
}
