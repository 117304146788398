import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PayLaterTopupFlow {
  amount = null;
  minAmount = null;
  maxAmount = null;
}

export default class PayLaterTopupFlowSetup extends FlowSetup {
  @service homePage;
  @service abilities;
  @service router;

  constructor() {
    super(...arguments);

    this.dataContext = new PayLaterTopupFlow();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    if (
      !variation('feature--boolean-pay-later-repay-by-card') ||
      this.abilities.cannot('access pay later in financing')
    ) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  onComplete() {
    this.router.replaceWith('financing.pay-later');
  }

  onAbort() {
    this.router.replaceWith('financing.pay-later');
  }
}
