/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, SearchField } from '@repo/design-system-kit';

import { ROLES } from 'qonto/constants/membership';
import normalize from 'qonto/utils/normalize-string';

export default class AccountingHubController extends Controller {
  lottiePlayer = LottiePlayer;
  searchField = SearchField;

  @tracked searchQuery = '';
  @tracked filteredItems = this.model.organizations;

  @action
  filterItems(query) {
    this.searchQuery = query;

    let normalizedQuery = normalize(query);

    this.filteredItems = this.model.organizations.filter(({ name }) =>
      normalize(name).includes(normalizedQuery)
    );
  }

  get businessItems() {
    return this.model.organizations.filter(
      ({ membershipRole }) => membershipRole !== ROLES.REPORTING
    );
  }

  get clientItems() {
    return this.model.organizations.filter(
      ({ membershipRole }) => membershipRole === ROLES.REPORTING
    );
  }
}
