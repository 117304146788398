import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { variation } from 'ember-launch-darkly';

import { IS_PERIODIC_WARNING_REVIEWED } from 'qonto/constants/kyc-kyb-update-process';
import { ORGA_STATUS, ORGA_SUSPENDED_REASON } from 'qonto/constants/organization';
import { isRiskClassHighOrBanned } from 'qonto/utils/kyc-kyb-update-process';
import { setSessionStorageItem } from 'qonto/utils/session-storage';
import { hasSddEddTypeformFeature } from 'qonto/utils/typeform';

export default class PeriodicReviewWarningController extends Controller {
  @service router;
  @service intl;
  @service periodicUpdate;
  @service segment;

  queryParams = ['redirectRoute'];

  _getDateToken(date, token) {
    return dateToken({
      date,
      locale: this.intl.primaryLocale,
      token,
    });
  }

  get isSuspended() {
    let { organization } = this.model;
    let { status, suspensionReason } = organization;

    return (
      status === ORGA_STATUS.SUSPENDED &&
      suspensionReason === ORGA_SUSPENDED_REASON.EXPIRED_DUE_DILIGENCE
    );
  }

  get isHighRisk() {
    let { riskClass } = this.model.updateProcess || {};
    return isRiskClassHighOrBanned(riskClass);
  }

  get shouldDisplaySddEddTypeform() {
    return hasSddEddTypeformFeature(
      this.model.organization,
      this.model.updateProcess,
      variation('feature--boolean-improve-comms-in-it')
    );
  }

  get title() {
    let { dueDiligenceExpiringAt, isDuringCertificationTime } = this.model.updateProcess || {};

    if (this.isSuspended) {
      return this.intl.t('periodic-review.account-suspended-modal.title');
    }

    if (isDuringCertificationTime) {
      return this.intl.t('periodic-review.kyb-reminder-modal.title', {
        date: this._getDateToken(dueDiligenceExpiringAt, DATE_FORMAT_TOKENS.DATE_YEAR_L),
      });
    }
  }

  get subtitle1() {
    let { dueDiligenceExpiringAt, isDuringCertificationTime } = this.model.updateProcess || {};

    if (this.isSuspended) {
      return this.isHighRisk
        ? this.intl.t('periodic-review.account-suspended-modal.highrisk.subtitle1', {
            date: this._getDateToken(dueDiligenceExpiringAt, DATE_FORMAT_TOKENS.DATE_YEAR_L),
          })
        : this.intl.t('periodic-review.account-suspended-modal.lowrisk.subtitle1', {
            date: this._getDateToken(dueDiligenceExpiringAt, DATE_FORMAT_TOKENS.DATE_YEAR_L),
          });
    }

    if (isDuringCertificationTime) {
      if (this.isHighRisk) {
        return this.intl.t('periodic-review.kyb-reminder-modal.highrisk.subtitle1', {
          date: this._getDateToken(dueDiligenceExpiringAt, DATE_FORMAT_TOKENS.DATE_YEAR_L),
        });
      }

      return this.shouldDisplaySddEddTypeform
        ? this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.subtitle1-italy', {
            date: this._getDateToken(dueDiligenceExpiringAt, DATE_FORMAT_TOKENS.DATE_YEAR_L),
          })
        : this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.subtitle1');
    }
  }

  get subtitle2() {
    let { dueDiligenceExpiringAt, isDuringCertificationTime } = this.model.updateProcess || {};

    if (this.isSuspended) {
      if (this.isHighRisk) {
        return this.intl.t('periodic-review.account-suspended-modal.highrisk.subtitle2');
      }

      return this.shouldDisplaySddEddTypeform
        ? this.intl.t('periodic-review.account-suspended-modal.lowrisk.subtitle2-italy')
        : this.intl.t('periodic-review.account-suspended-modal.lowrisk.subtitle2');
    }

    if (isDuringCertificationTime) {
      if (this.isHighRisk) {
        return this.intl.t('periodic-review.kyb-reminder-modal.highrisk.subtitle2');
      }

      return this.shouldDisplaySddEddTypeform
        ? this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.subtitle2-italy')
        : this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.subtitle2', {
            date: this._getDateToken(dueDiligenceExpiringAt, DATE_FORMAT_TOKENS.DATE_YEAR_L),
          });
    }
  }

  get instructions() {
    let { isDuringCertificationTime } = this.model.updateProcess || {};

    if (this.isSuspended) {
      if (this.isHighRisk) {
        return this.shouldDisplaySddEddTypeform
          ? [
              this.intl.t('periodic-review.account-suspended-modal.highrisk.instructions1'),
              this.intl.t('periodic-review.account-suspended-modal.highrisk.instructions2'),
              this.intl.t('periodic-review.account-suspended-modal.highrisk.instructions3-italy'),
            ]
          : [
              this.intl.t('periodic-review.account-suspended-modal.highrisk.instructions1'),
              this.intl.t('periodic-review.account-suspended-modal.highrisk.instructions2'),
            ];
      }

      return this.shouldDisplaySddEddTypeform
        ? [
            this.intl.t('periodic-review.account-suspended-modal.lowrisk.instructions1'),
            this.intl.t('periodic-review.account-suspended-modal.lowrisk.instructions2-italy'),
          ]
        : [this.intl.t('periodic-review.account-suspended-modal.lowrisk.instructions1')];
    }

    if (isDuringCertificationTime) {
      if (this.isHighRisk) {
        return this.shouldDisplaySddEddTypeform
          ? [
              this.intl.t('periodic-review.kyb-reminder-modal.highrisk.instructions1'),
              this.intl.t('periodic-review.kyb-reminder-modal.highrisk.instructions2'),
              this.intl.t('periodic-review.kyb-reminder-modal.highrisk.instructions3-italy'),
            ]
          : [
              this.intl.t('periodic-review.kyb-reminder-modal.highrisk.instructions1'),
              this.intl.t('periodic-review.kyb-reminder-modal.highrisk.instructions2'),
              this.intl.t('periodic-review.kyb-reminder-modal.highrisk.instructions3'),
            ];
      }

      return this.shouldDisplaySddEddTypeform
        ? [
            this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.instructions1'),
            this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.instructions2-italy'),
          ]
        : [this.intl.t('periodic-review.kyb-reminder-modal.lowrisk.instructions1')];
    }
  }

  setPeriodicWarningReviewed() {
    setSessionStorageItem(IS_PERIODIC_WARNING_REVIEWED, 'yes');
  }

  @action
  handleOnLinkClick() {
    this.segment.track('periodic_kyc_kyb_review_warning_clicked', {
      action: 'cta',
    });

    this.setPeriodicWarningReviewed();
  }

  @action
  handleOnClose() {
    this.segment.track('periodic_kyc_kyb_review_warning_clicked', {
      action: 'close',
    });

    this.setPeriodicWarningReviewed();

    return this.router.replaceWith(this.model.redirectRoute);
  }
}
