import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { CARD_FLOWS_TRACKING_ORIGINS, ORIGIN } from 'qonto/constants/cards';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class CardsNewInviteeFlowDataContext {
  constructor({ cardHolderId, shouldShowRestrictions }) {
    this.cardHolderId = cardHolderId;
    this.displayEditCardSettings = true;
    this.origin = ORIGIN.MEMBER_INVITATION;
    this.flowTrackingOrigin = CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION;
    this.shouldShowRestrictions = shouldShowRestrictions;
  }
}

export default class CardsNewInviteeFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service cardsManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;
  @service modals;
  @service intl;

  dataContext = null;

  constructor() {
    super(...arguments);

    let { inviteeMembershipId } = this.router.currentRoute.queryParams;
    this.dataContext = new CardsNewInviteeFlowDataContext({
      cardHolderId: inviteeMembershipId,
      shouldShowRestrictions: this.abilities.can('view restrictions card'),
    });
  }

  get initialStepId() {
    return 'choose';
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('access creation flow card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    let { organization } = this.organizationManager;

    let { defaultAccount } = organization;

    let { estimates } = await this.cardsManager.fetchCardsCostsTask.perform(
      this.organizationManager.organization.id
    );
    let cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform();
    let holder = await this.store.findRecord('membership', this.dataContext.cardHolderId);

    this.dataContext.estimates = estimates;
    this.dataContext.cardsLimits = cardsLimits;
    this.dataContext.cardHolder = holder;
    this.dataContext.hasMultipleActiveAccounts =
      this.organizationManager.organization.hasMultipleActiveAccounts;
    this.dataContext.mainAccount = defaultAccount;
  }

  @action
  onComplete() {
    this.router.transitionTo('members');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    let result = await this.openAbortModalTask.perform();
    let { flowTrackingOrigin } = this.dataContext;
    if (result === 'confirm') {
      this.segment.track('cancel-modal_cancel_clicked', {
        origin: flowTrackingOrigin,
        step: stepId,
      });
      this.router.transitionTo('members');
    }
    return result === 'confirm';
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('cards.modals.cancel-creation.title'),
      description: this.intl.t('cards.modals.cancel-creation.subtitle'),
      cancel: this.intl.t('cards.modals.cancel-creation.back-cta'),
      confirm: this.intl.t('cards.modals.cancel-creation.leave-cta'),
    });
  });
}
