import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { apiBaseURL } from 'qonto/constants/hosts';
import { markHandledBySentry } from 'qonto/utils/handled-by-sentry';

export default class ConfirmCompanyProfileRoute extends Route {
  @service networkManager;
  @service sessionManager;
  @service organizationManager;
  @service sentry;
  @service router;

  async model() {
    let { confirmation_token } = this.paramsFor('confirm-company-profile');
    try {
      await this.networkManager.request(`${apiBaseURL}/v3/kyc_kyb_update_processes/confirm`, {
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization?.id,
        },
        method: 'POST',
        data: { confirmation_token },
      });
    } catch (error) {
      let code = error.responseJSON.errors[0].code ?? 'error';
      let isTokenExpired = code === 'token_expired';
      let isQuickConfirmationDisabled = code === 'quick_confirmation_disabled_for_organization';

      let customError = new Error(`${code}:${confirmation_token}`);

      if (isTokenExpired || isQuickConfirmationDisabled) {
        markHandledBySentry(customError);
      }

      throw customError;
    }

    return confirmation_token;
  }
}
