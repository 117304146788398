import { NotFoundError } from '@ember-data/adapter/error';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { COMMON_CREDIT_PERIODS } from 'qonto/components/receivable-invoices/form/due-date-selector';
import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
import { IRPF_ES_DEFAULT_RATE } from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { differenceInCalendar } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class ReceivableInvoicesDuplicateRoute extends BaseReceivableInvoicesRoute {
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service localeManager;
  @service menu;
  @service router;
  @service sentry;
  @service mollie;
  @service abilities;
  @service receivableInvoicesBreadcrumb;
  @service accountReceivableDocumentsUploadManager;

  @reads('organizationManager.organization.activeSortedAccounts') activeAccounts;

  activate() {
    this.menu.hide();
  }

  async beforeModel({ routeInfos }) {
    let id = routeInfos.reduce((acc, { params }) => {
      return { ...acc, ...params };
    }, {}).id;
    let canReadInvoice = this.abilities.can('read receivableInvoice');

    if (canReadInvoice) {
      let { organization } = this.organizationManager;
      if (organization.legalCountry === 'IT') {
        let eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organization.id
        );
        if (!eInvoiceActivation.eInvoicingActivated) {
          this.flowLinkManager.transitionTo({
            name: 'invoice-onboarding',
            stepId: 'einvoice-activation',
            queryParams: { destination: 'duplicate', invoiceID: id },
          });
        }
      }
    }

    if (variation('feature--boolean-ar-advanced-customization')) {
      this.store.adapterFor('quote').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoice').namespace = receivableInvoiceV5Namespace;
      this.store.adapterFor('receivable-invoices-settings').namespace =
        receivableInvoiceV5Namespace;
    }
  }

  async model({ id }, transition) {
    let { organization } = this.organizationManager;

    let settings;

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return;
      } else {
        this._redirectOnError();
        return;
      }
    }

    try {
      await this.fetchOrganizationAvatarTask.perform(organization);
    } catch (error) {
      this.handleError(error);
    }

    let invoice = await this.store.findRecord('receivable-invoice', id);

    let {
      beneficiaryName,
      bic,
      contactEmail,
      discount,
      iban,
      termsAndConditions,
      welfareFund,
      withholdingTax,
      payment,
      stampDutyAmount,
      sections,
      items,
      status,
      header,
      footer,
      currency,
      organizationSnapshot,
      paymentLink,
    } = invoice;

    let { locale } = this.localeManager;

    let issueDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);
    let performanceDate =
      organization.legalCountry === 'DE' ? dayjs().format(DATE_PICKER_FIELD_FORMAT) : null;
    let creditPeriod = differenceInCalendar(invoice.dueDate, invoice.issueDate, 'day');
    let daysToAdd = COMMON_CREDIT_PERIODS.includes(creditPeriod) ? creditPeriod : 15;
    let dueDate = dayjs().add(daysToAdd, 'day').format(DATE_PICKER_FIELD_FORMAT);

    await this.fetchClientsTask.perform().catch(ignoreCancelation).catch(this.handleError);

    let customerRecord, customerBillingCountry;
    if (invoice.belongsTo('customer').id()) {
      customerRecord = this.store.peekRecord('client-hub', invoice.belongsTo('customer').id());

      customerBillingCountry = customerRecord?.get('billingAddress.countryCode');
    }

    let duplicatedCurrency = customerRecord?.currency || currency || CURRENCIES.default;

    let lastInvoice, lastNumber, lastWithholdingTaxRate;
    try {
      lastInvoice = await this.store.modelFor('receivable-invoice').last(this.store);

      lastNumber = lastInvoice.number;
      lastWithholdingTaxRate = lastInvoice.withholdingTax?.rate;
    } catch (error) {
      // we expect 404 if no last invoice
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
        this.sentry.captureException(error);
      }
    }

    let duplicatedWelfareFund = this.store.createRecord('receivable-invoice/welfare-fund', {
      type: welfareFund?.type,
      rate: welfareFund?.rate,
    });

    let duplicatedWithholdingTax;
    if (this.isSpanishFreelancer && customerBillingCountry === 'ES') {
      duplicatedWithholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
        type: '',
        rate: withholdingTax?.rate || lastWithholdingTaxRate || IRPF_ES_DEFAULT_RATE,
        reason: '',
      });
    } else if (organization.legalCountry === 'IT') {
      duplicatedWithholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
        type: withholdingTax?.type,
        rate: withholdingTax?.rate,
        reason: withholdingTax?.reason,
      });
    }

    let duplicatedPayment = this.store.createRecord('receivable-invoice/payment', {
      conditions: payment?.conditions,
      method: payment?.method,
    });

    let bankAccount, duplicatedPaymentLink;

    if (this.isBanklessOrganization) {
      iban = invoice?.iban || lastInvoice?.iban || '';
      bic = invoice?.bic || lastInvoice?.bic || '';
    } else if (this.abilities.can('write paymentLink')) {
      bankAccount = await this._getBankAccount(iban);
      duplicatedPaymentLink = { methods: paymentLink?.methods?.slice() };
    } else {
      bankAccount = this.activeAccounts.find(account => account.iban === iban);
      iban = bankAccount?.iban || '';
      bic = bankAccount?.bic || '';
    }

    let hasSections = !isEmpty(sections);
    let duplicatedSections = [];
    let duplicatedItems = [];
    if (hasSections) {
      sections.forEach(section => {
        let { title, description, isDefault, items } = section;
        let sectionItems = [];

        items.forEach(item => {
          let {
            title,
            description,
            quantity,
            unitPrice,
            vatRate,
            vatExemptionCode,
            discount,
            unit,
            productId,
            links,
            type,
          } = item;
          sectionItems.push(
            this.store.createRecord('receivable-invoice/item', {
              title,
              description,
              quantity,
              unitPrice,
              vatRate,
              vatExemptionCode,
              discount,
              unit,
              productId,
              links,
              type,
            })
          );
        });

        duplicatedSections.push(
          this.store.createRecord('receivable-invoice/section', {
            title,
            description,
            isDefault,
            items: sectionItems,
          })
        );
      });
    } else {
      items.forEach(item => {
        let {
          title,
          description,
          quantity,
          unitPrice,
          vatRate,
          vatExemptionCode,
          discount,
          unit,
          productId,
          links,
          type,
        } = item;

        duplicatedItems.push(
          this.store.createRecord('receivable-invoice/item', {
            title,
            description,
            quantity,
            unitPrice,
            vatRate,
            vatExemptionCode,
            discount,
            unit,
            productId,
            links,
            type,
          })
        );
      });
    }

    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }
    contactEmail = settings.contactEmail;

    let nextNumber =
      settings?.numberingMode === 'automatic'
        ? settings.invoiceNextNumberFormatted || settings.nextInvoiceNumber
        : '';

    let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(this.handleError);

    let isFirstFrenchEinvoice = false;
    if (organization.legalCountry === 'FR') {
      await this.fetchFirstEInvoice();

      let allEinvoices = this.fetchFirstEInvoiceTask?.lastSuccessful?.value || false;

      isFirstFrenchEinvoice = allEinvoices && allEinvoices.length === 0;
    }

    let duplicatedInvoice = {};

    // when coming from the settings/flows modal, there might be already one recorded invoice in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (
      (transition?.from?.name === 'invoicing-settings' || transition?.from?.name === 'flows') &&
      this.peekRecordedInvoices.length > 0
    ) {
      // only one newly created invoice can be expected inside the array
      duplicatedInvoice = this.peekRecordedInvoices[0];
      // the email needs to be the latest one
      duplicatedInvoice.contactEmail = settings.contactEmail;

      if (variation('feature--boolean-ar-advanced-customization')) {
        duplicatedInvoice.termsAndConditions = null;
      }
    } else {
      let duplicatedFreshInvoice = {
        isEinvoice:
          (canCreateFrEinvoice && customerRecord?.einvoicing) ||
          Boolean(customerRecord?.eInvoicingAddress) ||
          false,
        number: nextNumber,
        bankAccount,
        beneficiaryName,
        bic,
        customer: customerRecord,
        discount,
        lastNumber,
        stampDutyAmount,
        issueDate,
        dueDate,
        performanceDate,
        organization,
        iban,
        ...(hasSections ? { sections: duplicatedSections } : { items: duplicatedItems }),
        locale,
        termsAndConditions: variation('feature--boolean-ar-advanced-customization')
          ? null
          : termsAndConditions,
        contactEmail,
        header,
        footer,
        welfareFund: duplicatedWelfareFund,
        withholdingTax: duplicatedWithholdingTax,
        payment: duplicatedPayment,
        organizationSnapshot,
        currency: duplicatedCurrency,
        paymentLink: duplicatedPaymentLink,
      };

      if (invoice.belongsTo('receivableInvoicingUpload').id()) {
        let attachmentRecord = await this.store.findRecord(
          'receivable-invoices-upload',
          invoice.belongsTo('receivableInvoicingUpload').id()
        );
        if (attachmentRecord) {
          duplicatedFreshInvoice.receivableInvoicingUpload = attachmentRecord;
        }
      }

      duplicatedInvoice = this.store.createRecord('receivable-invoice', duplicatedFreshInvoice);
    }

    duplicatedInvoice.organization.vatNumber = settings.vatNumber;

    if (organization.legalCountry === 'DE') {
      duplicatedInvoice.header = settings.invoiceHeader;
      duplicatedInvoice.footer = settings.invoiceFooter;
      duplicatedInvoice.organization.taxNumber = settings.taxNumber;
      duplicatedInvoice.organization.companyLeadership = settings.companyLeadership;
      duplicatedInvoice.organization.districtCourt = settings.districtCourt;
      duplicatedInvoice.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    if (variation('feature--boolean-ar-advanced-customization') && isEmpty(invoice.sections)) {
      let item = this.store.createRecord('receivable-invoice/item', defaultValues(this));
      duplicatedInvoice.sections = [
        this.store.createRecord('receivable-invoice/section', {
          isDefault: true,
          items: [item],
        }),
      ];
    }

    let toastMessage =
      status === 'draft'
        ? this.intl.t('receivable-invoices.duplicate-draft.toast-success')
        : this.intl.t('receivable-invoices.duplicate-invoice.toast-success');

    this.toastFlashMessages.toastInfo(toastMessage);

    let isFirstInvoice = Boolean(!lastInvoice);

    return {
      invoice: duplicatedInvoice,
      settings,
      isFirstInvoice,
      canCreateFrEinvoice,
      isFirstFrenchEinvoice,
      lastWithholdingTaxRate,
      isBanklessOrganization: this.isBanklessOrganization,
    };
  }

  get peekRecordedInvoices() {
    // peek in the store the already created but not saved invoice without an id
    return this.store
      .peekAll('receivable-invoice')
      .filter(invoice => invoice.isNew && invoice.id === null);
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }

  deactivate() {
    this.menu.show();
  }

  async _getBankAccount(iban) {
    await this.mollie.fetchConnectionTask.perform();
    let mollieBankAccount = this.mollie.bankAccountId
      ? this.store.peekRecord('bank-account', this.mollie.bankAccountId)
      : null;
    let invoiceAccount = this.activeAccounts.find(account => account.iban === iban);
    let mainAccount = this.organizationManager.organization.mainAccount;

    if (!this.mollie.isConnected && invoiceAccount) {
      return invoiceAccount;
    }

    return mollieBankAccount || mainAccount;
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);

    this.receivableInvoicesBreadcrumb.loadBreadcrumb(this.#getBreadcrumb());
    this.receivableInvoicesBreadcrumb.registerCallback(this.controller.validateTask.perform);
  }

  resetController(_controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.receivableInvoicesBreadcrumb.reset();
      this.accountReceivableDocumentsUploadManager.resetFiles();
    }
  }

  #getBreadcrumb() {
    let items = [
      {
        route: 'receivable-invoices.duplicate.index',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.invoice-details'
        ),
      },
      {
        route: 'receivable-invoices.duplicate.products',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.products-and-services'
        ),
      },
    ];

    if (this.abilities.can('write paymentLink') && !this.isBanklessOrganization) {
      items.push({
        route: 'receivable-invoices.duplicate.payment',
        title: this.intl.t(
          'receivable-invoices.invoice-creation.navigation.breadcrumb.payment-details'
        ),
      });
    }

    return items;
  }
}
