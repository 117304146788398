/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { ROLES } from 'qonto/constants/membership';
import { QPageSelector } from 'qonto/react/shared/components/q-page-selector';

export default class MemberListController extends Controller {
  qPageSelector = QPageSelector;

  queryParams = ['page', 'perPage'];

  perPage = 25;
  @tracked page = 1;

  @service abilities;
  @service intl;
  @service router;
  @service organizationManager;
  @service segment;
  @service subscriptionManager;
  @service flowLinkManager;

  rootRouteName = `members.list`;
  @tracked query;

  get revokedPaginatedMembers() {
    return this.model.paginatedMembersTask.last?.value?.filter(member => member.revoked) ?? [];
  }

  get activePaginatedMembers() {
    return (
      this.model.paginatedMembersTask.last?.value?.filter(
        member => member.active || member.pending
      ) ?? []
    );
  }

  get isSoloPlan() {
    return this.subscriptionManager.getLimitByFeatureCode('additional_users') === 1;
  }

  get inviteButtonText() {
    if (this.isSoloPlan) {
      return this.intl.t('btn.invite_an_accountant');
    }

    if (this.organizationManager.membership.role === ROLES.MANAGER) {
      return this.intl.t('btn.invite_an_employee');
    }

    return this.intl.t('btn.invite_new_member');
  }

  get hasResults() {
    return Boolean(this.model.paginatedMembersTask.last?.value?.length);
  }

  get hasMembersOrSearching() {
    return (
      Boolean(this.query) ||
      Boolean(this.model.paginatedMembersTask.last?.value?.length) ||
      Boolean(this.model.invitableMembersTask.last?.value?.length)
    );
  }

  get emptyStateOptions() {
    return {
      title: this.intl.t('activities.members.empty-team.title'),
      subtitle: this.intl.t('activities.members.empty-team.description'),
      lottieSrc: '/lotties/empty-state/no-team.json',
      button: {
        label: this.inviteButtonText,
        callback: this.inviteTeamMember,
      },
    };
  }

  get planUpgradeIsNeeded() {
    let hasReachedLimit =
      this.subscriptionManager.hasReachedUserLimit &&
      this.subscriptionManager.hasReachedAccountantLimit;

    return this.abilities.cannot('create paid members members') && hasReachedLimit;
  }

  get totalCount() {
    return this.model.paginatedMembersTask.last?.value?.meta?.total_count;
  }

  get totalPages() {
    return this.model.paginatedMembersTask.last?.value?.meta?.total_pages;
  }

  get revokedMemberNumber() {
    return this.model.paginatedMembersTask.last?.value?.filter(member => member.revoked).length;
  }

  get isLoading() {
    return this.model.invitableMembersTask.isRunning || this.model.paginatedMembersTask.isRunning;
  }

  @action
  inviteTeamMember() {
    this.trackTeamInviteSelected('team_page');

    this.flowLinkManager.transitionTo({
      name: 'member-invite',
    });
  }

  @action
  handleDestroyMember(member) {
    member.destroyRecord();
  }

  @action
  trackTeamInviteSelected(origin) {
    this.segment.track('team_invite_selected', {
      origin,
    });
  }
}
