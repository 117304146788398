import { PAY_LATER_SIGNATURE_STATUS } from 'qonto/constants/financing';

export const payLaterApplication = {
  steps: {
    signature: {
      componentName: 'financing/pay-later/application/signature',
      nextStepId: ({ signature }) =>
        signature?.status === PAY_LATER_SIGNATURE_STATUS.SUCCESS ? 'success' : 'failure',
    },
    success: {
      componentName: 'financing/pay-later/application/success',
    },
    failure: {
      componentName: 'financing/pay-later/application/failure',
    },
  },
  options: {
    enablePersistence: false,
  },
};
