export const quoteEsignature = {
  steps: {
    signature: {
      componentName: 'receivable-invoices/quote-esignature/signature',
      nextStepId: ({ signature }) => (signature?.status === 'success' ? 'success' : 'error'),
    },
    success: {
      componentName: 'receivable-invoices/quote-esignature/success',
    },
    error: {
      componentName: 'receivable-invoices/quote-esignature/error',
    },
  },
  options: {
    enablePersistence: false,
  },
};
