import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { FeatureDiscoveryCard } from 'qonto/react/components/feature-discovery/card';

export default class FeatureDiscoveryIndexController extends Controller {
  @service intl;
  @service router;
  @service segment;

  featureDiscoveryCard = FeatureDiscoveryCard;
  queryParams = [{ flow: 'flow', pricePlan: 'price_plan' }];

  @tracked flow;
  @tracked pricePlan;

  get title() {
    return this.flow === 'cash-flow-management'
      ? this.intl.t('painted-door-test.cash-flow-management.plan-selection.title')
      : this.intl.t('painted-door-test.invoice-automation.plan-selection.title');
  }

  get featureOptions() {
    return this.flow === 'cash-flow-management'
      ? this.getCashFlowManagementOptions()
      : this.getInvoiceAutomationOptions();
  }

  getCashFlowManagementOptions() {
    let freeFeatures = [
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.core.feature-1'),
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.core.feature-2'),
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.core.feature-3'),
    ];

    let paidFeatures = [
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.advanced.feature-1'),
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.advanced.feature-2'),
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.advanced.feature-3'),
      this.intl.t('painted-door-test.cash-flow-management.plan-selection.advanced.feature-4'),
    ];

    let freeOption = {
      illustration: '/illustrations/ill-sspot-account-analytic-tools.svg',
      name: this.intl.t('painted-door-test.cash-flow-management.plan-selection.core.title'),
      optionKey: 'free',
      isFree: true,
      price: undefined,
      features: freeFeatures,
    };

    let paidPrice = this.getPaidPrice();
    let paidOption = {
      illustration: '/illustrations/ill-sspot-cards-next-budget-settings.svg',
      name: this.intl.t('painted-door-test.cash-flow-management.plan-selection.advanced.title'),
      optionKey: `${paidPrice}`,
      isFree: false,
      price: paidPrice,
      features: paidFeatures,
    };

    return [freeOption, paidOption];
  }

  getInvoiceAutomationOptions() {
    let coreFeatures = [
      this.intl.t('painted-door-test.invoice-automation.plan-selection.core.feature-1'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.core.feature-2'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.core.feature-3'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.core.feature-4'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.core.feature-5'),
    ];

    let advancedFeatures = [
      this.intl.t('painted-door-test.invoice-automation.plan-selection.advanced.feature-1'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.advanced.feature-2'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.advanced.feature-3'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.advanced.feature-4'),
      this.intl.t('painted-door-test.invoice-automation.plan-selection.advanced.feature-5'),
    ];

    let advancedOption = {
      illustration: '/illustrations/ill-sspot-roles-and-permissions-financial-monitoring.svg',
      name: this.intl.t('painted-door-test.invoice-automation.plan-selection.advanced.title'),
      optionKey: 'advanced',
      isFree: false,
      price: 59,
      features: advancedFeatures,
    };

    let freeCoreOption = {
      illustration: '/illustrations/ill-sspot-account-analytic-tools.svg',
      name: this.intl.t('painted-door-test.invoice-automation.plan-selection.core.title'),
      optionKey: 'free',
      isFree: true,
      price: undefined,
      features: coreFeatures,
    };

    let paidPrice = this.getPaidPrice();
    let paidCoreOption = {
      illustration: '/illustrations/ill-sspot-account-analytic-tools.svg',
      name: this.intl.t('painted-door-test.invoice-automation.plan-selection.core.title'),
      optionKey: `${paidPrice}`,
      isFree: false,
      price: paidPrice,
      features: coreFeatures,
    };

    if (this.pricePlan === '3') {
      return [freeCoreOption, advancedOption];
    }

    return [paidCoreOption, advancedOption];
  }

  getPaidPrice() {
    if (this.flow === 'cash-flow-management') {
      switch (this.pricePlan) {
        case '1':
          return 30;
        case '2':
          return 40;
        case '3':
          return 50;
        case '4':
          return 60;
      }
    } else if (this.flow === 'invoice-automation') {
      switch (this.pricePlan) {
        case '1':
          return 29;
        case '2':
          return 19;
      }
    }
  }

  @action
  handleSelectOption(optionKey) {
    if (this.flow === 'cash-flow-management') {
      if (optionKey === 'free') {
        let price = this.getPaidPrice();
        this.segment.track('cash-flow_analysis_choose', { price });
      } else {
        this.segment.track('cash-flow_forecast_choose', { price: optionKey });
      }

      this.router.transitionTo('feature-discovery.interest', {
        queryParams: { optionKey, flow: this.flow },
      });
    } else {
      if (optionKey === 'advanced') {
        let price = this.pricePlan === '3' ? 'free' : this.getPaidPrice();
        this.segment.track('accounts-payable_advanced_choose', { price });
      } else {
        this.segment.track('accounts-payable_core_choose', {
          price: optionKey,
        });
      }

      this.router.transitionTo('feature-discovery.success', {
        queryParams: { flow: this.flow },
      });
    }
  }

  @action
  handleClose() {
    this.router.transitionTo('overview.index');
  }
}
