import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SuppliersRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'accounts-payable');
    let canNavigate = this.abilities.can('view supplier');

    if (!canNavigate) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }
}
