export default {
  "guests": "qHi",
  "guests--upsell-screen": "qHA",
  "no-members": "qHY",
  "teams-header": "qHh",
  "empty": "qHf",
  "invite-text": "qHK",
  "body": "qHG",
  "members": "qHr",
  "members-list": "qHb",
  "members-title": "qHy caption-bold",
  "member": "qHU",
  "details": "qHj",
  "kyc-disclaimer": "qHW",
  "kyc-disclaimer-width": "qHu"
};
