import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';

export default class QuotesNewIndexController extends Controller {
  @service router;
  @service segment;
  @service receivableInvoicesBreadcrumb;

  @controller('quotes.new') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.saveQuoteTask') saveQuoteTask;
  @reads('parentController.closeQuoteForm') closeQuoteForm;

  header = Header;

  get breadcrumb() {
    return this.receivableInvoicesBreadcrumb.breadcrumbWithStates;
  }

  get hasNextStep() {
    let hasProductAndServices = variation('feature--boolean-ar-advanced-customization');

    return hasProductAndServices;
  }

  continueTask = dropTask(async () => {
    try {
      if (variation('feature--boolean-ar-advanced-customization')) {
        await this.model.quote.validate({ skipSectionsValidation: true });
      }
      await this.router.transitionTo('quotes.new.products');
    } catch (error) {
      this.parentController.handleError(error);
    }
  });

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'quoteForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        origin: 'quotes.new',
      },
    });
  }
}
