import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class KycRoute extends Route {
  @service organizationManager;
  @service router;
  @service store;
  @service menu;
  @service homePage;

  getRedirectRoute(transition) {
    if (
      !transition.to.queryParams?.redirectRoute ||
      transition.to.queryParams?.redirectRoute?.startsWith('http')
    ) {
      return this.homePage.defaultRouteName;
    }

    return transition.to.queryParams.redirectRoute;
  }

  async beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');

    let { membership } = this.organizationManager;

    let kyc = await this.store.queryRecord('identitiesKyc', {
      identityId: membership.identityId,
    });

    if (membership.kycAccepted && !kyc.isRenewalPoiRequired) {
      this.router.replaceWith(this.getRedirectRoute(transition));
    }

    if (['kyc', 'kyc.index'].includes(transition.to.name)) {
      this.router.replaceWith('kyc.intro');
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
