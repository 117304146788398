import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';

export default class ReceivableInvoicesEditPaymentController extends Controller {
  @service router;
  @service store;
  @service segment;
  @service flowLinkManager;
  @service receivableInvoicesBreadcrumb;

  @controller('receivable-invoices.edit') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.editInvoiceOrigin') editInvoiceOrigin;

  @reads('parentController.finalizeDraftTask') finalizeDraftTask;
  @reads('parentController.updateDraftTask') updateDraftTask;
  @reads('parentController.onCloseTask') onCloseTask;

  header = Header;

  get breadcrumb() {
    return this.receivableInvoicesBreadcrumb.breadcrumbWithStates;
  }

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'invoiceForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        ...this.editInvoiceOrigin,
      },
    });
  }

  activatePaymentLinksTask = dropTask(async () => {
    await this.parentController.updateDraftBeforeOnboardingTask.perform();

    let queryParams = {
      origin: 'receivable-invoices.edit.payment',
      draftInvoiceId: this.model.invoice.id,
    };

    this.segment.track('payment-link_activation_started', {
      origin: 'invoice',
    });

    await this.flowLinkManager.transitionTo({
      name: 'payment-links-onboarding',
      stepId: 'intro',
      queryParams,
    });
  });
}
