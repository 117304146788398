import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { PromoCodeForm } from 'qonto/react/components/subscription/promo-code/form';
import { SubscriptionPromoCodeItem } from 'qonto/react/components/subscription/promo-code/item/item';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class SettingsPricePlanPromoCodesController extends Controller {
  promoCodeForm = PromoCodeForm;

  SubscriptionPromoCodeItem = SubscriptionPromoCodeItem;

  @service organizationManager;
  @service store;

  @reads('organizationManager.organization') organization;

  @tracked discounts = [];

  get sortedFilteredDiscounts() {
    let discounts = this.discounts.filter(({ voucherType }) =>
      ['trial', 'initial', 'subscription', 'promotional'].includes(voucherType)
    );

    return discounts.sort(sortByKey('finishOn'));
  }

  get discountsWithActive() {
    let firstNotPromotionalCodeFound = false;
    return this.sortedFilteredDiscounts.map(discount => {
      if (['promotional', 'trial'].includes(discount.voucherType)) {
        discount.isActive = true;
      }

      if (!firstNotPromotionalCodeFound && discount.voucherType !== 'promotional') {
        firstNotPromotionalCodeFound = true;
        discount.isActive = true;
      }

      return discount;
    });
  }

  fetchDiscountsTask = dropTask(async () => {
    this.discounts = await this.store.query('discount', { organization_id: this.organization.id });
  });
}
