import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { CARD_FLOWS_TRACKING_ORIGINS, CARD_LEVELS, ORIGIN } from 'qonto/constants/cards';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/cards';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { REQUEST_ORIGINS, REQUEST_TYPES } from 'qonto/constants/requests';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const DEFAULT_SORT_BY = 'processed_at:desc';

export default class CardsRequestsController extends Controller {
  @service organizationManager;
  @service abilities;
  @service emptyStates;
  @service intl;
  @service segment;
  @service notifierCounterManager;
  @service requestsManager;
  @service subscriptionManager;
  @service router;
  @service toastFlashMessages;
  @service modals;

  queryParams = ['highlight', 'page', 'per_page'];

  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked allocatedBudget;

  ORIGIN = ORIGIN;
  CARD_LEVELS = CARD_LEVELS;
  REQUEST_TYPES = REQUEST_TYPES;

  get requests() {
    return this.model.fetchRequestsTask.lastSuccessful?.value || [];
  }

  get cachedRequests() {
    return this.model.isApprover
      ? this.requests.filter(({ status }) => status === 'pending')
      : [...this.requests];
  }

  get requestCardAbility() {
    return this.abilities.can('read request') && this.abilities.can('createCard request');
  }

  get createCardAbility() {
    return this.abilities.can('create card');
  }

  get shouldSyncRequests() {
    return !this.cachedRequests.length && this.requests.meta.total_pages > 1;
  }

  get shouldShowNotification() {
    return this.notifierCounterManager.counter?.cardRequests > 0;
  }

  get availableBankAccounts() {
    return this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts;
  }

  get isAccountSelectorShown() {
    return this.abilities.can('read bankAccount') && this.availableBankAccounts.length > 1;
  }

  get authorizedBalance() {
    return this.organizationManager.organization?.mainAccount?.authorizedBalance;
  }

  get localState() {
    let { isRunning, last } = this.model.fetchRequestsTask;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
      };
    }

    if (last.isError) {
      return {
        isLoading: false,
        error: true,
      };
    }
  }

  get isEmptyLocally() {
    return this.cachedRequests.length === 0;
  }

  get isEmptyGlobally() {
    return this.model.isCardsEmptyGlobally && this.isEmptyLocally;
  }

  get shouldShowDiscoverEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get shouldShowInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get emptyStateRevampOptions() {
    if (this.model.fetchRequestsTask.isRunning || this.model.fetchRequestsTask.last.isError) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        tab: this.model.isApprover ? 'tasks' : 'requests',
        isSubAccountClosed: false,
      },
      abilities: {
        canRequestCards: this.requestCardAbility,
        canCreateCards: this.createCardAbility,
        canReadCards: this.abilities.can('read card'),
        canReadBankAccount: this.abilities.can('read bankAccount'),
        hasMultiAccountsFeature: this.subscriptionManager.features.multiAccounts,
        hasTeamManagementFeature: this.subscriptionManager.features.teamManagement,
      },
    });
  }

  get isItalianOrga() {
    return (
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let { role } = this.organizationManager.membership;

    let request = this.requests.find(({ id }) => id === requestId);
    if (!request) {
      this.highlight = '';
      return;
    }

    this.segment.track('request_details_opened', {
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      origin: 'cards',
      role,
    });

    this.allocatedBudget = await this.requestsManager.fetchAllocatedBudgetTask.perform(request);
    this.highlight = request.id;
  });

  @action
  setAccount(request, account) {
    request.bankAccount = account;
  }

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }

  @action handleCancel(request) {
    return request.cancelRequest();
  }

  @action handleDecline(request) {
    return request.declineRequest();
  }

  approveTask = dropTask(async request => {
    await request.approveRequest();
  });

  handleQuickDecline = task(async request => {
    this.segment.track('request_declined_clicked', {
      origin: REQUEST_ORIGINS.CARDS,
      method: 'quick_actions',
    });

    await this.requestsManager.quickDeclineCardRequestTask.perform(request);
    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  handleQuickApprove = task(async request => {
    this.segment.track('request_approved_clicked', {
      method: 'quick_actions',
      origin: REQUEST_ORIGINS.CARDS,
    });

    await this.requestsManager.quickApproveCardRequestTask.perform(request);

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  // websocket events callbacks
  reloadData() {
    this.model.fetchRequestsTask.perform({}).catch(ignoreCancelation);
  }

  @action
  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'cards',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleCreateCardClick(origin) {
    if (this.isItalianOrga) {
      this.segment.track('cards_order_clicked', {
        origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
      });
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    // ES(?) tracking
    this.trackCtaEvent(origin);
    // Cards-dedicated tracking
    this.segment.track('cards_order_clicked', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
    });

    this.router.transitionTo('cards.physical-or-virtual', {
      queryParams: {
        origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
      },
    });
  }

  @action
  handleRequestCardClick(origin) {
    if (this.isItalianOrga) {
      this.segment.track('cards_order_clicked', {
        origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
      });
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    this.trackCtaEvent(origin);

    this.router.transitionTo('requests.landing', {
      queryParams: {
        origin: 'cards',
      },
    });
  }
}
