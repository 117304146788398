import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class FreemiumUpgradeRoute extends Route {
  @service router;
  @service homePage;
  @service upsellManager;

  beforeModel() {
    if (!this.upsellManager.shouldShowFreemiumUpgrade) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
