export default {
  "splash-landing": "qLF",
  "container": "qLg",
  "options": "qLD",
  "physical": "qLV",
  "card-label": "qLL",
  "physical-cta-container": "qLT",
  "physical-cta": "qLz",
  "coming-soon": "qLa"
};
