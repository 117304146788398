import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { ALL_STAKEHOLDERS, CATEGORIES } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubCategoriesRoute extends Route {
  @service intl;
  @service router;
  @service connectManager;

  beforeModel(params) {
    let categorySlug = params.to.params.category_slug;
    let invalidCategory = !Object.values(CATEGORIES).includes(categorySlug);

    if (invalidCategory) {
      this.router.transitionTo('settings.connect-hub.applications');
    }
  }

  async model(params) {
    let { category_slug: categorySlug, searchQuery, stakeholderSlug, page } = params;

    if (!variation('feature--boolean-qontent-connect-hub')) {
      let allCategories = await this.connectManager.getAllCategories();
      let allStakeholders = await this.connectManager.getAllStakeholders();

      let category = allCategories.find(({ slug }) => slug === categorySlug);
      let stakeholder = allStakeholders.find(({ slug }) => slug === stakeholderSlug);

      let integrations = [];

      if (searchQuery) {
        integrations = await this.connectManager.searchIntegrationsTask
          .perform(searchQuery)
          .catch(ignoreCancelation);
      } else {
        integrations = await this.connectManager.getIntegrationsTask
          .perform({
            categoryId: category?.id,
            stakeholderId: stakeholder?.id,
            page,
          })
          .catch(ignoreCancelation);
      }

      let highlightedIntegrations = [];

      if (categorySlug === CATEGORIES.BANKS) {
        highlightedIntegrations = await this.connectManager.highlightBankIntegrationsTask
          .perform({
            categoryId: category?.id,
          })
          .catch(ignoreCancelation);
      }

      if (categorySlug === CATEGORIES.INVOICE_IMPORT) {
        highlightedIntegrations = await this.connectManager.highlightGetMyInvoicesTask
          .perform({
            categoryId: category?.id,
          })
          .catch(ignoreCancelation);
      }

      return {
        integrations,
        allStakeholders,
        categoryId: category?.id,
        categorySlug,
        highlightedIntegrations,
      };
    }

    let integrations = [];
    let highlightedIntegrations = [];

    if (searchQuery) {
      integrations = await this.connectManager.searchIntegrationsTask
        .perform(searchQuery)
        .catch(ignoreCancelation);
    } else {
      integrations = await this.connectManager.getIntegrationsTask
        .perform({
          categorySlug,
          stakeholderSlug,
          page,
        })
        .catch(ignoreCancelation);
    }

    highlightedIntegrations = await this.connectManager.highlightPopularIntegrationsTask
      .perform({
        categorySlug,
      })
      .catch(ignoreCancelation);

    return {
      integrations,
      allStakeholders: ALL_STAKEHOLDERS(this.intl),
      categoryId: categorySlug,
      categorySlug,
      highlightedIntegrations,
    };
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setData(model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.searchQuery = undefined;
      controller.stakeholderSlug = undefined;
      controller.page = 1;
    }
  }
}
