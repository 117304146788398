import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RibaTasksRoute extends Route {
  @service segment;

  setupController(controller, model) {
    this.segment.track('riba_domain_opened');

    super.setupController(controller, model);
    return controller.fetchDataTask.perform();
  }
}
