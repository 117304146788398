import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CashFlowCategoriesRoute extends Route {
  @service abilities;
  @service homePage;
  @service organizationManager;

  beforeModel() {
    if (this.abilities.cannot('manage category')) {
      return this.homePage.visitDefaultPage();
    }
  }

  // Since /cash-flow-categories manages its own cache via Tanstack Query, this ensures the Ember data store only gets updated
  // when the user navigates away from the page, without triggering unnecessary re-fetches while creating/updating multiple categories.
  @action
  willTransition(transition) {
    if (!transition.to.name.startsWith('cash-flow-categories')) {
      this.organizationManager.loadCashFlowCategories(this.organizationManager.organization.id);
    }
  }
}
