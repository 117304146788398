import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class EditSupplierRoute extends Route {
  @service modals;
  @service intl;
  @service abilities;
  @service homePage;
  @service store;
  @service organizationManager;
  @service toastFlashMessages;
  @service router;
  @service sentry;
  @service segment;

  supplier;
  modal;
  referrerSupplierInvoiceId;
  invoiceCurrency;

  beforeModel() {
    if (this.abilities.cannot('update supplier')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async model({ id }, transition) {
    if (transition.from?.name === 'supplier-invoices.show') {
      this.referrerSupplierInvoiceId = transition.from.params?.invoice_id;
      this.invoiceCurrency = transition.to?.queryParams?.invoiceCurrency;
    }

    this.supplier = await this.store.findRecord('supplier', id);
    this.modal = this.modals.open(
      'suppliers/supplier-modal',
      {
        isFullScreenModal: true,
        title: this.intl.t('supplier-invoices.supplier-edition.title'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('supplier-invoices.supplier-creation.cta.save-changes'),
        confirmTask: this.updateSupplierTask,
        supplier: this.supplier,
        referrerSupplierInvoiceId: this.referrerSupplierInvoiceId,
        invoiceCurrency: this.invoiceCurrency,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
          escapeDeactivates: false,
        },
      }
    );

    return { supplier: this.supplier };
  }

  updateSupplierTask = dropTask(async () => {
    try {
      if (this.supplier.currency !== CURRENCIES.default) {
        this.supplier.iban = '';
      }
      await this.supplier.save();
      this.toastFlashMessages.toastSuccess(
        this.intl.t(
          'supplier-invoices.supplier-invoice-details.updated-supplier.confirmation-toast'
        )
      );

      this.segment.track('edit-supplier_submit', { result: 'success' });

      if (this.referrerSupplierInvoiceId) {
        this.router.transitionTo('supplier-invoices.show', this.referrerSupplierInvoiceId);
      } else {
        this.router.transitionTo('suppliers.index');
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error.errors.some(errorObj => errorObj?.status === '409')) {
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.supplier-invoice-details.form.duplicate-supplier-toast')
        );
        this.segment.track('add-supplier_duplicated_toast');
        return;
      }
      if (['required', 'invalid'].includes(error.code)) {
        // Handled by the validation system
        return;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      this.segment.track('edit-supplier_submit', { result: 'error' });
    }
  });

  deactivate() {
    this.modal.close();
    this.referrerSupplierInvoiceId = undefined;
    this.invoiceCurrency = undefined;
  }
}
