import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import * as Sentry from '@sentry/ember';
import { reads } from 'macro-decorators';

import { CARD_FLOWS_TRACKING_ORIGINS, CARD_LEVELS, CARD_STATUSES } from 'qonto/constants/cards';
import { ErrorInfo } from 'qonto/utils/error-info';

// Triggers redirecting to the upsell flow
export const ORIGIN = {
  PROMOTIONAL: 'promotional',
  PAYMENT_LIMITS: 'payment-limits',
  WITHDRAWAL_LIMITS: 'withdrawal-limits',
  WITHDRAWAL_FEES: 'withdrawal-fees',
  FX_PAYMENT_FEES: 'fx-payment-fees',
};

export class CardUpsellDataContext {}

export default class CardUpsellFlowSetup {
  @service abilities;
  @service cardsManager;
  @service flow;
  @service homePage;
  @service intl;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  @reads('organizationManager.organization') organization;

  dataContext = null;

  constructor(owner) {
    setOwner(this, owner);
    // Necessary otherwise, when chaining upsell with the flow with card id then the flow without card id,
    // the flow without card id will start on the "upsell step" instead of the "card-selection" step
    this.flow.clearStorage();
    this.dataContext = new CardUpsellDataContext();
    this.dataContext.upsellCard = {};
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.visitDefaultPage();
    }

    try {
      let { cardId, origin } = this.router.currentRoute.queryParams;
      this.dataContext.origin = origin ?? ORIGIN.PROMOTIONAL;
      this.dataContext.flowTrackingOrigin = CARD_FLOWS_TRACKING_ORIGINS.UPSELL;

      if (cardId) {
        let cardToUpsell = await this.store.findRecord('card', cardId);

        if (this.abilities.cannot('upsell card', cardToUpsell)) {
          return this.router.replaceWith('cards');
        }

        this.dataContext.card = cardToUpsell;
      } else {
        if (this.abilities.cannot('upsell any card')) {
          return this.router.replaceWith('cards');
        }

        let cards = await this.store.query('card', {
          filters: {
            holder_id: this.organizationManager.membership.id,
            card_levels: [CARD_LEVELS.STANDARD, CARD_LEVELS.PLUS],
            statuses: [CARD_STATUSES.LIVE],
          },
        });

        let upsellableCards = cards.filter(c => c.eligibleForUpsell);

        if (isEmpty(upsellableCards)) {
          return this.router.replaceWith('cards');
        }

        // in case there is only one upsellable card, we can directly set it as the selected card to upsell
        if (upsellableCards.length === 1) {
          this.dataContext.card = upsellableCards[0];
        } else {
          this.dataContext.cards = upsellableCards;
        }
      }

      // if the ability check has passed, the user is the card holder, it's part of the check
      this.dataContext.isUserCardHolder = true;
      // card costs will show in the upsell and review steps
      let { estimates } = await this.cardsManager.fetchCardsCostsTask.perform(this.organization.id);
      this.dataContext.estimates = estimates;
      // card limits will be used in the upsell step
      let cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform();
      this.dataContext.cardsLimits = cardsLimits;

      this.dataContext.cardOptionsPrices = await this.cardsManager.fetchCardOptionsPricesTask
        .perform()
        .catch(() => []);

      this.showCardCustomization = false;

      this.cardUpsellDesign = null;
      this.cardUpsellTypeOfPrint = null;
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.router.transitionTo('cards.index');
    }
  }

  @action
  onAbort() {
    this.router.transitionTo('cards.index');
  }

  @action
  onComplete() {
    let { upsellCard } = this.dataContext;
    this.router.transitionTo('cards.index', { queryParams: { highlight: upsellCard.id } });
  }
}
