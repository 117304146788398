export default {
  "create-invoice-button": "qoS btn btn--primary",
  "filters-container": "qoc",
  "header-actions": "qoq",
  "product-discovery": "qoZ",
  "search-field": "qoR",
  "full-width": "qoQ",
  "footer-import-cta": "qoe",
  "footer-show-existing-invoices-cta": "qoB"
};
