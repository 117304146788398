export default {
  "header": "qiZ",
  "content": "qiR",
  "read-only": "qiQ",
  "disclaimer": "qie",
  "title": "qiB",
  "card-container": "qiE",
  "representatives-section": "qid",
  "disabled-href": "qiI"
};
