import Route from '@ember/routing/route';
import { service } from '@ember/service';

import {
  FINANCING_INSTALLMENT_STATUS,
  IN_PROGRESS_FINANCING_STATUSES,
  PAY_LATER_ELIGIBILITY_STATUSES,
} from 'qonto/constants/financing';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FinancingPayLaterRoute extends Route {
  @service router;
  @service abilities;
  @service financing;
  @service userManager;
  @service flowLinkManager;
  @service sentry;

  beforeModel() {
    if (this.abilities.cannot('access pay later in financing')) {
      return this.router.transitionTo('application');
    }
  }

  async model() {
    let [eligibility, insights] = await Promise.all([
      this.financing.checkPayLaterEligibility(),
      this.financing.fetchRepaymentInsights(),
    ]);

    let tabCounts = {};
    let installmentsInsights = {};

    try {
      tabCounts = await this.financing.fetchFinancingsTabInsights(
        IN_PROGRESS_FINANCING_STATUSES,
        'in_progress'
      );

      if (eligibility.eligibility === PAY_LATER_ELIGIBILITY_STATUSES.NO_MORE_ELIGIBLE) {
        installmentsInsights = await this.financing.fetchTabInsights({
          not_paid_and_recovered: [
            FINANCING_INSTALLMENT_STATUS.NOT_PAID,
            FINANCING_INSTALLMENT_STATUS.RECOVERED,
          ],
        });
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    let model = {
      ...eligibility,
      ...insights,
      tabCounts,
      installmentsInsights,
    };

    return model;
  }
}
