import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { Header } from 'qonto/react/components/receivable-invoices/header';
import { ArFeaturesPromoPopup } from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class QuotesNewProductsController extends Controller {
  @service router;
  @service store;
  @service segment;
  @service intl;
  @service upsellManager;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service sentry;
  @service receivableInvoicesBreadcrumb;

  @controller('quotes.new') parentController;

  @tracked popupFeature = '';

  @reads('parentController.logo') logo;

  @reads('parentController.saveQuoteTask') saveQuoteTask;
  @reads('parentController.closeQuoteForm') closeQuoteForm;

  arFeaturesPromoPopup = ArFeaturesPromoPopup;
  header = Header;

  get breadcrumb() {
    return this.receivableInvoicesBreadcrumb.breadcrumbWithStates;
  }

  get popupIsOpen() {
    return Boolean(this.popupFeature);
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  onCtaClickTask = dropTask(async () => {
    try {
      await this.upsellManager.upgradeRecommendationWithTransition('ar_advanced_customization');
      this.hidePopup();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  handleOpenSettings() {
    this.segment.track('invoicing_settings_open', { origin: 'quoteForm' });
    this.router.transitionTo('invoicing-settings', {
      queryParams: {
        origin: 'quotes.new.products',
      },
    });
  }

  @action showPopup(feature) {
    this.popupFeature = feature;
  }

  @action hidePopup() {
    this.popupFeature = '';
  }
}
