export const payLaterTopup = {
  steps: {
    amount: {
      componentName: 'financing/pay-later/topup/amount',
      nextStepId: 'payment',
    },
    payment: {
      componentName: 'financing/pay-later/topup/payment',
      nextStepId: 'success',
    },
    success: {
      componentName: 'financing/pay-later/topup/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
