import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { didCancel, dropTask, restartableTask, task, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { APPROVAL_WORKFLOW_STATUSES } from 'qonto/constants/approval-workflow';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/reimbursements';
import { CODES, LAYOUT } from 'qonto/constants/empty-states/system';
import { apiBaseURL, requestsNamespace } from 'qonto/constants/hosts';
import { MAX_BULK_SELECTION_ITEMS } from 'qonto/constants/max-bulk-selection-items';
import { REQUEST_ORIGINS, REQUEST_TYPES, STATUS } from 'qonto/constants/requests';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import cloneProperties from 'qonto/utils/clone-properties';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';

const DEFAULT_SORT_BY = 'created_at:desc';
const NEXT_ELEMENT_DELAY = 800;

export default class ReimbursementsPendingController extends Controller {
  @service abilities;
  @service modals;
  @service notifierCounterManager;
  @service featuresManager;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service store;
  @service requestsManager;
  @service sensitiveActions;
  @service segment;
  @service sentry;
  @service emptyStates;
  @service flowLinkManager;
  @service subscriptionManager;

  currentTransfer = null;
  spendLimitsWarning = null;
  spendLimits = null;
  shouldPreserveHighlight = false;

  @tracked showAllreadyProcessedModal = false;
  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked meta = null;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked allocatedBudget;
  @tracked selectedRequester;
  @tracked quickActionsRejectRunningIds = [];
  @tracked selectedAccountHasInsufficientFunds = false;
  @tracked canShowInsufficientWarning = false;
  @tracked selectedOrgBankAccount = this.availableBankAccounts[0];

  @reads('organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts')
  hasMultipleActiveCurrentRemuneratedAccounts;

  approvalWorkflowLastStep = null;

  ES_CODES = CODES;

  maxBulkSelectionItems = MAX_BULK_SELECTION_ITEMS;

  get isEmptyStatePreviewLayout() {
    return this.emptyStateOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get isEmptyGlobally() {
    let { mileageCompleted = 0, expenseReportCompleted = 0 } =
      this.notifierCounterManager.counter || {};
    return this.pendingRequestsCount + expenseReportCompleted + mileageCompleted === 0;
  }

  get currentParams() {
    return {
      page: this.page,
      per_page: this.per_page,
      meta: this.meta,
      sort_by: this.sort_by,
    };
  }

  get emptyStateOptions() {
    if (this.fetchDataTask.isRunning || this.fetchDataTask.last?.isError) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.requests.length === 0,
      hasActiveFilterOrSearch: Boolean(this.selectedRequester),
      config: getEmptyStateConfig(this.intl, { changePlanCtaCallback: this.changePlanCtaCallback }),
      customInputs: {
        tab: this.tab || 'pending',
        hasTeamManagement: this.subscriptionManager.hasFeature('team_management'),
      },
      abilities: {
        canReviewExpenseReport: this.abilities.can('review expense report request'),
        canCreateExpenseReport: this.abilities.can('create expense report request'),
      },
    });
  }

  get hasInsuficientFunds() {
    return this.selectedAccountHasInsufficientFunds;
  }

  get canShowAccountSelector() {
    return (
      this.abilities.can('review expense report request') &&
      this.hasMultipleActiveCurrentRemuneratedAccounts &&
      !this.spendLimitsWarning
    );
  }

  get toReviewRequestsCount() {
    let { expenseReportRequestsToReview = 0, mileageRequestsToReview = 0 } =
      this.notifierCounterManager.counter || {};

    return expenseReportRequestsToReview + mileageRequestsToReview;
  }

  get toPayRequestsCount() {
    let { expenseReportRequestsToPay = 0, mileageRequestsToPay = 0 } =
      this.notifierCounterManager.counter || {};

    return expenseReportRequestsToPay + mileageRequestsToPay;
  }

  get pendingRequestsCount() {
    let { expenseReportRequests = 0, mileageRequests = 0 } =
      this.notifierCounterManager.counter || {};
    return expenseReportRequests + mileageRequests;
  }

  get availableBankAccounts() {
    return this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts;
  }

  get isSingleAccountApprover() {
    return this.availableBankAccounts.length === 1;
  }

  get requests() {
    return this.fetchDataTask.last?.value?.filter(({ status }) => status === 'pending') ?? [];
  }

  get shouldSyncRequests() {
    return !this.requests.length && this.meta.total_pages > 1;
  }

  get displayFilter() {
    return (
      this.memberships &&
      (this.fetchDataTask.isRunning || this.requests.length > 0 || Boolean(this.selectedRequester))
    );
  }

  @action
  handleBulkDecline(selector) {
    let { selectedItems } = selector;

    this.segment.track('request_declined_clicked', {
      origin: REQUEST_ORIGINS.reimbursements,
    });
    this.modals.open('reimbursements/bulk/modals/decline', {
      requests: selectedItems,
      approveTask: this.bulkDeclineTask,
      selector,
    });
  }

  bulkDeclineTask = dropTask(async (close, requests, note, selector) => {
    try {
      await this.networkManager.request(`${apiBaseURL}/${requestsNamespace}/requests/bulk/reject`, {
        method: 'POST',
        data: { request_ids: requests.map(request => request.requestId), declined_note: note },
      });

      close();

      requests.forEach(request => this.store.unloadRecord(request));

      this.toastFlashMessages.toastInfo(
        this.intl.t('requests.reimbursements.bulk.toasts.requests-rejected', {
          count: requests.length,
        })
      );

      this.handleCloseBulkSidebar(selector);
      this.notifierCounterManager.updateCounter();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });

  handleBulkApproveTask = dropTask(async selector => {
    let { selectedItems } = selector;
    this.segment.track('request_approved_clicked', {
      outcome: this.tab === 'to-approve' ? 'approval' : 'payment',
      request_type: 'expense_report',
      origin: REQUEST_ORIGINS.reimbursements,
      method: 'sidepanel',
      request_count: selectedItems.length,
    });
    if (this.tab === 'to-approve') {
      await this.sensitiveActions.runTask.perform(
        this.requestsManager.bulkApproveTask,
        selectedItems
      );
    } else if (this.tab === 'to-pay') {
      await this.sensitiveActions.runTask.perform(
        this.requestsManager.bulkPayTask,
        selectedItems,
        this.selectedOrgBankAccount
      );
    }
    this.handleCloseBulkSidebar(selector);
    this.notifierCounterManager.updateCounter();
  });

  @action
  onBankAccountSelect(request, account) {
    request.bankAccount = account;
  }

  get localState() {
    let { isRunning, last } = this.fetchDataTask;
    let isEmpty = this.requests.length === 0;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
        filtersApplied: false,
      };
    }

    if (last?.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
        filtersApplied: false,
      };
    }

    if (isEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
        filtersApplied: Boolean(this.selectedRequester),
      };
    }
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action setAccount(request, account) {
    request.bankAccount = account;
    this.setProperties({ confirmWarnings: [], confirmErrors: [] });
    return this.confirmRequestTask.perform(request);
  }

  @action
  setSelectedOrgBankAccount(account) {
    this.selectedOrgBankAccount = account;
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  handleReloadRequestTask = dropTask(async () => {
    this.showAllreadyProcessedModal = false;
    await this.transitionToRoute('requests.past', {
      queryParams: { highlight: this.highlight },
    });
  });

  @action handleRefresh() {
    this.fetchDataTask
      .perform({})
      .then(() => {
        this.showAllreadyProcessedModal = false;
        this.resetQueryParams(['highlight']);
      })
      .catch(() => {
        // ignore error
      });
  }

  @action
  showAccountInsufficientFundsWarning() {
    this.selectedAccountHasInsufficientFunds = true;
  }

  @action
  toggleCanShowInsufficientWarning(value) {
    this.canShowInsufficientWarning = value ?? !this.canShowAccountSelector;
  }

  @action
  trackCTA(origin) {
    if (this.emptyStateOptions) {
      this.emptyStates.trackCta(this.emptyStateOptions, origin);
    }
  }

  @action
  changePlanCtaCallback(origin) {
    this.trackCTA(origin);
    this.flowLinkManager.transitionTo({ name: 'subscription-change', stepId: 'plans' });
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let { role } = this.organizationManager.membership;

    let request = this.requests.find(({ id }) => id === requestId);

    if (!request.bankAccount) {
      this.toggleCanShowInsufficientWarning();
      this.setAccount(request, this.organizationManager.organization.mainAccount);
    }

    this.segment.track('request_details_opened', {
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      origin: 'reimbursements',
      role,
    });

    await request.belongsTo('approvalWorkflowState').reload();

    await this.fetchAllocatedBudgetTask.perform(request);

    this.highlight = request.id;

    return this.confirmRequestTask.perform(request);
  });

  openNextElement(request, requests) {
    this.highlight = '';
    let nextElementIndex = requests.findIndex(({ id }) => id === request.id) + 1;
    let nextElement = requests[nextElementIndex];

    if (nextElement) {
      this.shouldPreserveHighlight = true;
      later(() => {
        this.highlight = nextElement.id;
      }, NEXT_ELEMENT_DELAY);
    }
  }

  onQuickRejectTask = task(async request => {
    let isRequester = request.initiator.get('id') === this.organizationManager.membership.id;

    let trackedEvent = isRequester ? 'request_cancel_clicked' : 'request_declined_clicked';

    this.segment.track(trackedEvent, {
      origin: REQUEST_ORIGINS.reimbursements,
      method: 'quick_actions',
      request_type: request.requestType,
    });

    await this.requestsManager.quickRejectReimbursementRequestTask.perform(request);

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  onQuickApproveTask = task(async request => {
    this.segment.track('request_approved_clicked', {
      origin: REQUEST_ORIGINS.reimbursements,
      method: 'quick_actions',
      request_type: request.requestType,
      outcome: request.lastStep ? 'payment' : 'approval',
    });

    await this.requestsManager.quickApproveReimbursementRequestTask.perform(request);

    if (this.shouldSyncRequests) {
      this.resetQueryParams();
      this.reloadData();
    }
  });

  @action handleCancel(request) {
    // eslint-disable-next-line promise/catch-or-return
    request.cancelRequest().then(() => this.notifierCounterManager.updateCounter());
    this.openNextElement(request, this.requests);
  }

  handleCancelTask = dropTask(async request => {
    try {
      await request.cancelRequest();
      this.notifierCounterManager.updateCounter();
      this.openNextElement(request, this.requests);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(
          new Error('Failed to cancel request at reimbursements.pending', {
            cause: error,
          })
        );
      }
      throw error;
    }
  });

  @action handleDecline(request) {
    // eslint-disable-next-line promise/catch-or-return
    request.declineRequest().then(() => this.notifierCounterManager.updateCounter());
    this.openNextElement(request, this.requests);
  }

  approveTask = dropTask(async request => {
    this.segment.track('request_approved_clicked', {
      request_type: request.requestType,
      origin: REQUEST_ORIGINS.reimbursements,
      method: 'sidepanel',
      outcome: request.lastStep ? 'payment' : 'approval',
    });
    await request.approveRequest();
    this.openNextElement(request, this.requests);
  });

  clearHighlightParam() {
    if (this.highlight) {
      this.resetQueryParams(['highlight']);
    }
  }

  @action
  toggleAllBulkItemsCheckbox(selector) {
    selector.toggleAllSelected(false);
    this.clearHighlightParam();
  }

  @action
  toggleBulkItemCheckbox(selector, requestId) {
    selector.toggleSelectedItem(requestId, false);
    this.clearHighlightParam();
  }

  @action
  handleCloseBulkSidebar(selector) {
    selector.clearFeaturedItems(false);
    this.clearHighlightParam();
  }

  @action handleCloseSidePanel() {
    if (!this.shouldPreserveHighlight) {
      this.resetQueryParams(['highlight']);
    }
    this.shouldPreserveHighlight = false;

    return this.fetchDataTask
      .perform({})
      .then(() => this.notifierCounterManager.updateCounter())
      .catch(() => {
        // ignore error
      });
  }

  fetchDataTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by, initiator_ids } = filterParams(params);

    let requestModel = {
      includes: ['memberships'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
      status: [STATUS.PENDING],
      request_type: ['expense_report', 'mileage'],
      initiator_ids,
    };

    if (this.featuresManager.isEnabled('approvalWorkflows')) {
      requestModel.approval_workflow_status = APPROVAL_WORKFLOW_STATUSES.AWAITS_MY_APPROVAL;
      if (this.approvalWorkflowLastStep !== null) {
        requestModel.approval_workflow_last_step = this.approvalWorkflowLastStep;
      }
    }

    let response = await this.store.query('request', requestModel);

    if (response.length === 0) {
      // We need to reload the number of invitable members in the case we have the empty state
      await this.organizationManager.organization.reload();
    }

    this.meta = response.meta;
    return response;
  });

  confirmRequestTask = dropTask(async request => {
    await this.confirmReimbursementTask.perform(request);
  });

  fetchAllocatedBudgetTask = dropTask(async request => {
    if (
      !request ||
      this.abilities.cannot('read budget') ||
      [REQUEST_TYPES.MILEAGE].includes(request.requestType) ||
      !request.pending
    ) {
      this.allocatedBudget = null;
      return;
    }

    let requestPeriodAmounts = this.abilities.can('review expense report request');
    let initiatorId = request.initiator.get('id');
    let scheduledDate = new Date();

    requestPeriodAmounts &= this.organizationManager.membership.id !== initiatorId;

    try {
      let results = await this.store.adapterFor('budget').search({
        initiatorId,
        scheduledDate,
        includes: requestPeriodAmounts ? ['period_amounts'] : [],
      });
      this.allocatedBudget = results[0];
    } catch (error) {
      if (didCancel(error)) return;

      if (ErrorInfo.for(error).shouldSendToSentry) {
        return this.sentry.captureException(error);
      }
    }
  });

  confirmReimbursementTask = dropTask(async request => {
    if (!request.lastStep) return;
    this.destroyCurrentTransfer();
    this.set('confirmErrors', []);
    this.set('spendLimitsWarning', null);
    this.set('spendLimits', null);

    let bankAccount = this.organizationManager.currentAccount;
    let transfer = this.store.createRecord('transfer', { bankAccount });

    await cloneProperties(request, transfer);
    transfer.email = request.initiator.get('email');
    transfer.amount = request.amount.value;
    transfer.currency = request.amount.currency;
    this.currentTransfer = transfer;

    let transferNamespace = this.store.adapterFor('transfer').namespace;
    let url = `${apiBaseURL}/${transferNamespace}/transfers/confirm`;
    // Patch : End point confirm not support operation type's scheduled_later only scheduled
    let data = this.currentTransfer.serialize();
    data.operation_type =
      data.operation_type === 'scheduled_later' ? 'scheduled' : data.operationType;
    data.name = request.initiator.get('fullName');
    data.activity_tag = 'other_service';
    data = JSON.stringify({ transfer: data });

    try {
      let response = await this.networkManager.request(url, {
        method: 'POST',
        data,
      });

      this.setProperties(parseConfirmResponse(response, this.intl));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error.status === 422 && error?.errors?.iban?.length) {
        let hasSEPAError = error.errors.iban.some(error => error.includes('SEPA'));

        if (hasSEPAError) {
          this.set('confirmErrors', ['iban_sepa_error']);
        }
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }

  destroyCurrentTransfer() {
    let transfer = this.currentTransfer;
    // Ember Data 4.7 does not like calling `destroy()` on a store
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.store.isDestroying || this.store.isDestroyed) return;
    if (transfer) {
      transfer.destroy();
    }
  }

  reloadData() {
    this.fetchDataTask.perform({}).catch(ignoreCancelation);
  }

  employeeCanceled(payload) {
    let { id } = payload.object;
    if (!this._isRequestInList(id)) {
      return;
    }

    if (this.highlight === id) {
      this.showAllreadyProcessedModal = true;
    } else {
      this._displayToastRequestProcessed();
      this.reloadData();
    }
  }

  _isRequestInList(itemId) {
    return this.requests.some(({ id }) => id === itemId);
  }

  _displayToastRequestProcessed() {
    this.toastFlashMessages.toastInfo(
      this.intl.t('toasts.request_processed_refresh'),
      'transfer_create'
    );
  }

  @action
  handleRequesterSelect(requester) {
    this.selectedRequester = requester;
    let params = {};
    if (requester) {
      params.initiator_ids = [requester.id];
      this.segment.track('reimbursements_filter_requester');
    }

    this.fetchDataTask.perform(params).catch(() => {});
  }
}
