import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';

export default class ReceivableInvoicesDuplicateProductsRoute extends BaseReceivableInvoicesRoute {
  @service router;
  @service store;
  @service organizationManager;

  beforeModel() {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      this.router.transitionTo('receivable-invoices.duplicate.index');
    }
  }

  async model() {
    let parentModel = this.modelFor('receivable-invoices.duplicate');
    let { invoice } = parentModel;

    let attachment;

    if (invoice.belongsTo('receivableInvoicingUpload').id()) {
      attachment = await this.store.peekRecord(
        'receivable-invoices-upload',
        invoice.belongsTo('receivableInvoicingUpload').id()
      );
    }

    return {
      ...parentModel,
      attachment,
      isBanklessOrganization: this.isBanklessOrganization,
    };
  }
}
