/* eslint-disable @qonto/no-async-action */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { CardPaymentLoading } from 'qonto/react/shared/components/card-payment-loading';

const HARD_ERROR_CODE_REGEX = /^[3][0-9]*$/gm;

export default class TopupPaymentController extends Controller {
  disclaimer = Disclaimer.Block;
  cardPaymentLoading = CardPaymentLoading;

  @service homePage;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service payment;
  @service router;
  @service sentry;

  @tracked checkoutFramesLibraryReady = false;
  @tracked gpayLibraryLoaded = false;

  @tracked threeDSAuthenticationError = null;

  @tracked showGooglePaySection = false;
  @tracked googlePayError = null;

  queryParams = [{ ckoSessionId: 'cko-session-id' }];
  @tracked ckoSessionId;

  get isLoading() {
    return !this.checkoutFramesLibraryReady || !this.gpayLibraryLoaded;
  }

  get locale() {
    let localeSecondPart = this.localeManager.locale === 'en' ? 'gb' : this.localeManager.locale;
    return `${this.localeManager.locale}-${localeSecondPart}`.toUpperCase();
  }

  get checkoutDisclaimerError() {
    if (this.ckoSessionId && this.payment.provider === 'checkout') {
      return this.intl.t('on-boarding.top-up.error.generic-card-decline-message', {
        legalCountry: this.organizationManager.organization.legalCountry,
      });
    }

    return this.threeDSAuthenticationError;
  }

  get googPayDisclaimerError() {
    if (this.ckoSessionId && this.payment.provider === 'google-pay') {
      return this.intl.t('on-boarding.top-up.error.generic-card-decline-message', {
        legalCountry: this.organizationManager.organization.legalCountry,
      });
    }

    return this.googlePayError;
  }

  handleOnCloseTask = dropTask(async () => {
    let membership = this.organizationManager.membership;
    membership.topupsStatus = 'skipped';
    try {
      await membership.save();
    } catch (error) {
      // We should not prevent a user to close the topup flow evenif
      // updating the topupsStatus fails
      this.sentry.captureException(error);
    }

    this.homePage.visitDefaultPage(this.organizationManager.organization.slug);
  });

  @action
  handleOnGoBack() {
    this.router.transitionTo('onboarding.topup.amount', this.organizationManager.organization.id);
  }

  onSubmitTask = dropTask(async (cardTokenized, context) => {
    let errorKey = context === 'checkout' ? 'threeDSAuthenticationError' : 'googlePayError';
    this[errorKey] = null;

    let response;
    try {
      response = await this.payment.triggerPayment({
        payload: {
          organization_id: this.organizationManager.organization.id,
          token: cardTokenized.token,
          amount: this.payment.amount,
          transaction_type: context === 'google-pay' ? 'google_pay' : 'card',
        },
        successURLPath: 'success',
        failureURLPath: 'payment',
      });
    } catch (error) {
      this.sentry.captureException(error);
      return this.router.transitionTo('onboarding.topup.fail');
    }

    let { status: responseStatus, response_code: responseCode } = response;

    if (responseStatus === 'Pending') {
      window.sessionStorage.setItem(
        'topup_context',
        JSON.stringify({ amount: this.payment.amount, provider: context })
      );
      return window.location.replace(response._links.redirect.href);
    }

    if (responseStatus === 'Declined') {
      if (HARD_ERROR_CODE_REGEX.test(responseCode)) {
        this[errorKey] = this.intl.t('on-boarding.top-up.error.generic-payment-decline-message');
      } else {
        this[errorKey] = this.intl.t('on-boarding.top-up.error.generic-card-decline-message', {
          legalCountry: this.organizationManager.organization.legalCountry,
        });
      }
    }
  });

  @action
  onCheckoutFramesReady() {
    this.checkoutFramesLibraryReady = true;
  }

  @action
  async onGooglePayTokenReady(paymentData) {
    try {
      let response = await this.payment.handleGooglePayToken(paymentData);

      await this.onSubmitTask.perform(response, 'google-pay');
    } catch {
      this.googlePayError = this.intl.t('on-boarding.top-up.error.generic-card-decline-message', {
        legalCountry: this.organizationManager.organization.legalCountry,
      });
    }
  }

  @action
  onGooglePayReadyToPay(isReady) {
    this.gpayLibraryLoaded = true;
    this.showGooglePaySection = isReady;
  }

  @action
  onGooglePayError(error) {
    // A DOMException error is thrown when the user closes the payment sheet
    // and we don't care about it
    if (!(error instanceof DOMException)) {
      this.sentry.captureException(error);
    }
  }
}
