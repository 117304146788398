import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SupplierInvoicesRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;
  @service store;
  @service organizationManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'accounts-payable');
    let canNavigate = this.abilities.can('navigate supplier-invoice');

    if (!canNavigate || this.abilities.cannot('view supplierInvoice')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async afterModel() {
    if (this.abilities.can('create approval-workflow')) {
      let approvalWorkflows = this.store.peekAll('approval-workflow');
      let isApprovalWorkflowFromCurrentOrganization = approvalWorkflows?.every(workflow => {
        let value =
          workflow.organization.organizationId === this.organizationManager.organization.id;
        return value;
      });

      if (!approvalWorkflows.length || !isApprovalWorkflowFromCurrentOrganization) {
        this.store.unloadAll('approval-workflow');
        await this.store.query('approval-workflow', {});
      }
    }
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }
}
