import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';

export default class ReceivableInvoicesEditIndexRoute extends BaseReceivableInvoicesRoute {
  model() {
    let parentModel = this.modelFor('receivable-invoices.edit');
    return {
      ...parentModel,
      isBanklessOrganization: this.isBanklessOrganization,
    };
  }
}
