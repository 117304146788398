import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class EinvoicingRoute extends Route {
  @service store;
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service sentry;

  get isFeatureEnabled() {
    return variation('feature--boolean-einvoicing-settings-management');
  }

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'e-invoicing');

    let canRead = this.abilities.can('read einvoicingSetting');
    let isFrench = this.organizationManager.organization.legalCountry === 'FR';
    let isKybValidated = this.organizationManager.organization.kybAccepted;

    if (!canRead || !isFrench || !isKybValidated) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  model() {
    if (!this.isFeatureEnabled) return;

    this.fetchSettingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => this.handleError(error));

    return {
      settingsTask: this.fetchSettingsTask,
    };
  }

  fetchSettingsTask = dropTask(async () => {
    let { organization } = this.organizationManager;

    return await this.store.findRecord('einvoicing-fr-settings', organization.id);
  });

  handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
